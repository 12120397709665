import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Input, Select, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'src/app/hooks'
import DatePicker from './DatePicker'

interface IProps {
  open: boolean
  onClose: () => void
  title: string
  onSubmit?: (values: any) => void
  rowData: any
  datePicker: any
  setdatePicker: any
  timePicker: any
  settimePicker: any
  complationPicker: any
  setcomplationPicker: any
  assigned: any
  pmsData: any
  allRequests: any
  statusData: any
  secotrData: any
  types: any
  clients: any
  selectedClient: any
  setSelectedClient: any
  selectedStatus: any
  setselectedStatus: any
  selectedAssigned: any
  setSelectedAssigned: any
  clientCompany: any
  setclientCompany: any
  selectedChargeRate: any
  setselectedChargeRate: any
  selectedType: any
  setselectedType: any
  fetchClientsAdd?: any
  setselctedtls: any
  tls: any
  pms: any
  newClient: any
}

const { Option } = Select

const SeeMoreRequests: React.FC<IProps> = ({
  open,
  onClose,
  title,
  onSubmit,
  rowData,
  datePicker,
  setdatePicker,
  timePicker,
  settimePicker,
  complationPicker,
  setcomplationPicker,
  assigned,
  pmsData,
  allRequests,
  statusData,
  secotrData,
  types,
  clients,
  selectedClient,
  setSelectedClient,
  selectedStatus,
  setselectedStatus,
  selectedAssigned,
  setSelectedAssigned,
  clientCompany,
  setclientCompany,
  selectedChargeRate,
  setselectedChargeRate,
  selectedType,
  setselectedType,
  fetchClientsAdd,
  setselctedtls,
  tls,
  pms,
  newClient,
}) => {
  const [form] = Form.useForm()
  const user = useAppSelector((state) => state.user.data)
  const history = useHistory()

  useEffect(() => {
    if (rowData && rowData?.type_of_work?.id) {
      setselectedType(rowData.type_of_work.id)
    }
  }, [rowData, setselectedType])

  useEffect(() => {
    if (rowData && rowData?.client?.id) {
      setSelectedClient(rowData.client.id)
    }
  }, [rowData, setSelectedClient])

  const [firstCharge, setfirstCharge] = useState(false)

  useEffect(() => {
    if (
      rowData &&
      rowData?.client?.id === selectedClient &&
      rowData?.type_of_work?.id === selectedType
    ) {
      setfirstCharge(true)
    } else {
      setfirstCharge(false)
    }
  }, [rowData, selectedType, selectedClient, setselectedChargeRate])

  useEffect(() => {
    if (!firstCharge && selectedType && selectedClient) {
      setselectedChargeRate(
        clients
          ?.find((n: any) => n.id === selectedClient)
          ?.charge_rates?.find((m: any) => m.type_of_work === selectedType)
          ?.price,
      )
    }
  }, [
    selectedType,
    clients,
    setselectedChargeRate,
    selectedClient,
    firstCharge,
  ])

  useEffect(() => {
    if (rowData && firstCharge) {
      setselectedChargeRate(rowData.charge_rate)
    }
  }, [rowData, selectedType, setselectedChargeRate, firstCharge])

  useEffect(() => {
    if (selectedChargeRate !== null) {
      form.setFieldValue('charge_rate', selectedChargeRate)
    }
  }, [form, selectedChargeRate, selectedClient, rowData])

  useEffect(() => {
    if (selectedClient) {
      const findClient = clients.find((n: any) => n.id === selectedClient)
      setclientCompany(
        findClient?.company ?? findClient?.email.split('@').pop().split('.')[0],
      )
    }
  }, [selectedClient, clients, setclientCompany])

  useEffect(() => {
    if (clientCompany) {
      form.setFieldValue('company', clientCompany)
    }
  }, [clientCompany, form])

  useEffect(() => {
    if (rowData && rowData.datetime && datePicker === undefined) {
      setdatePicker(dayjs(rowData.datetime))
    }
  }, [rowData, datePicker, setdatePicker])

  useEffect(() => {
    if (rowData && rowData.datetime && timePicker === undefined) {
      settimePicker(dayjs(rowData.datetime))
    }
  }, [rowData, timePicker, settimePicker])

  useEffect(() => {
    if (rowData && rowData.datetime && complationPicker === undefined) {
      setcomplationPicker(dayjs(rowData.datetime).startOf('day'))
    }
  }, [rowData, complationPicker, setcomplationPicker])

  useEffect(() => {
    if (rowData) {
      setselctedtls(rowData.senior_pm?.id)
    }
  }, [rowData, setselctedtls])

  useEffect(() => {
    form.setFieldsValue({
      name: rowData && rowData.id_code,
      company: rowData && rowData.client?.email.split('@').pop().split('.')[0],
      subject: rowData && rowData.subject,
      sector: rowData && rowData.sector && rowData.sector?.id,
      type_of_work: rowData && rowData.type_of_work && rowData.type_of_work?.id,
      estimated_hours: rowData && rowData.estimated_hours,
      billable_hours: rowData && rowData.billable_hours,
      non_billable_hours: rowData && rowData.non_billable_hours,
      senior_pm: rowData && rowData.senior_pm?.id,
      spm_billable_hours: rowData && rowData.senior_pm?.billable_hours,
      spm_non_billable_hours: rowData && rowData.senior_pm?.non_billable_hours,
      pm: rowData && rowData.pm?.id,
      pm_billable_hours: rowData && rowData.pm?.billable_hours,
      pm_non_billable_hours: rowData && rowData.pm?.non_billable_hours,
      status: rowData && rowData.status && rowData.status?.id,
      casecode: rowData && rowData.casecode,
      parent: rowData && rowData?.parent === null ? 'null' : rowData?.parent,
      datetime:
        rowData && rowData.datetime !== null
          ? dayjs(rowData.datetime)
          : dayjs(),
      complation_date:
        rowData && rowData.datetime !== null
          ? dayjs(rowData.datetime)
          : dayjs(),
    })
  }, [form, rowData])

  useEffect(() => {
    if (newClient === null) {
      form.setFieldsValue({
        client: rowData && rowData.client?.id,
      })
    }
  }, [newClient, form, rowData])

  useEffect(() => {
    if (newClient !== null) {
      form.setFieldsValue({
        client: newClient.id,
      })
      setSelectedClient(newClient.id)
    }
  }, [newClient, form, setSelectedClient])

  useEffect(() => {
    if (newClient !== null) {
      form.setFieldsValue({
        company: newClient?.email.split('@').pop().split('.')[0],
      })
    }
  }, [newClient, form])

  useEffect(() => {
    if (
      (rowData && selectedStatus && selectedStatus !== 'Delivered') ||
      (rowData && selectedStatus && selectedStatus !== 'Cancelled')
    ) {
      form.setFieldValue(
        'assigned',
        rowData && rowData.assigned?.map((n: any) => n.id),
      )
    }
  }, [form, rowData, selectedStatus])

  useEffect(() => {
    if (
      (rowData && selectedStatus && selectedStatus === 'Delivered') ||
      (rowData && selectedStatus && selectedStatus === 'Cancelled')
    ) {
      form.setFieldValue('assigned', rowData && rowData.assigned)
    }
  }, [form, rowData, selectedStatus])

  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  useEffect(() => {
    if (windowWidth && windowWidth > 992) {
      if (open) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }, [open, windowWidth])

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      getContainer={false}
      visible={open}
      className="drawer__custom"
      zIndex={10000}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name="name" label="Request ID">
          <Input placeholder="" disabled />
        </Form.Item>
        <Form.Item
          name="client"
          label="Client"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder="Name, Surname <email@company.com>"
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(val) => {
              setSelectedClient(val.value)
            }}
            onInputKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchClientsAdd({ data: e.currentTarget.value })
              }
            }}
            disabled={
              (user && user.groups[0].name === 'Project Manager') ||
              (user && user.groups[0].name === 'Team Member')
                ? true
                : false
            }
          >
            {clients &&
              clients.map((n: any) => (
                <Option value={n.id} label={n.name || n.surname} key={n.id}>
                  {n.name + ', ' + n.surname + ' <' + n.email + '>'}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="company" label="Company">
          <Input placeholder="" disabled />
        </Form.Item>
        <div className="drawer__custom-double">
          <Form.Item
            name="datetime"
            label="Date"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              suffixIcon={
                <i className="icon-calendar" style={{ color: '#9092a7' }}></i>
              }
              onChange={(el) => {
                setdatePicker(el)
              }}
              value={datePicker}
            />
          </Form.Item>
          <Form.Item
            name="datetime"
            label="Time"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              picker="time"
              suffixIcon={<i className="icon-clock"></i>}
              onChange={(el) => {
                settimePicker(el)
              }}
              value={timePicker}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="subject"
          label="Subject Line"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Input
            placeholder=""
            disabled={
              (user && user.groups[0].name === 'Project Manager') ||
              (user && user.groups[0].name === 'Team Member')
                ? true
                : false
            }
          />
        </Form.Item>
        <Form.Item
          name="sector"
          label="Sector"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            disabled={
              user && user.groups[0].name === 'Team Member' ? true : false
            }
          >
            {secotrData &&
              secotrData.map((n: any) => (
                <Option value={n.id} key={n.id}>
                  {n.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="type_of_work"
          label="Type of work"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            onChange={(val) => {
              setselectedType(val.value)
            }}
            disabled={
              user && user.groups[0].name === 'Team Member' ? true : false
            }
          >
            {types &&
              types.map((n: any) => (
                <Option value={n.id} key={n.id}>
                  {n.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="parent" label="Parent project">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            disabled={
              user && user.groups[0].name === 'Team Member' ? true : false
            }
          >
            <Option value="null">No Parent</Option>
            {allRequests &&
              allRequests.map((n: any) => (
                <Option value={n.id} key={n.id}>
                  {n.id_code}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <div className="drawer__custom-double">
          <Form.Item
            name="estimated_hours"
            label="Est. Effort (hrs)"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <Input
              placeholder=""
              prefix={<i className="icon-clock"></i>}
              disabled={
                user && user.groups[0].name === 'Team Member' ? true : false
              }
            />
          </Form.Item>
        </div>
        <div className="drawer__custom-double">
          <Form.Item
            name="complation_date"
            label="Est. Delivery Date"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              suffixIcon={
                <i className="icon-calendar" style={{ color: '#9092a7' }}></i>
              }
              onChange={(el) => {
                setcomplationPicker(el)
              }}
              value={complationPicker}
              disabled={
                user && user.groups[0].name === 'Team Member' ? true : false
              }
            />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select
              labelInValue
              placeholder=""
              allowClear={true}
              style={{ marginBottom: '0' }}
              suffixIcon={
                <i
                  className="icon-chevron-down"
                  style={{ fontSize: '20px', color: '#667085' }}
                ></i>
              }
              onChange={(val) => {
                setselectedStatus(val.label)
              }}
              disabled={
                user && user.groups[0].name === 'Team Member' ? true : false
              }
            >
              {statusData &&
                statusData.map((n: any) => (
                  <Option value={n.id} key={n.id}>
                    {n.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        {selectedStatus === 'Delivered' || selectedStatus === 'Cancelled' ? (
          <div className="drawer__custom-double">
            <Form.Item
              name="billable_hours"
              label="Billable Hours"
              rules={[
                {
                  required: true,
                  message: 'Fill the field',
                },
              ]}
            >
              <Input
                placeholder=""
                prefix={<i className="icon-clock"></i>}
                disabled={
                  user && user.groups[0].name === 'Team Member' ? true : false
                }
              />
            </Form.Item>
            <Form.Item
              name="non_billable_hours"
              label="Non-Billable Hours"
              rules={[
                {
                  required: true,
                  message: 'Fill the field',
                },
              ]}
            >
              <Input
                placeholder=""
                prefix={<i className="icon-clock"></i>}
                disabled={
                  user && user.groups[0].name === 'Team Member' ? true : false
                }
              />
            </Form.Item>
          </div>
        ) : (
          ''
        )}
        <Form.Item name="charge_rate" label="Charge rate">
          <Input
            placeholder=""
            disabled={
              user && user.groups[0].name === 'Team Member' ? true : false
            }
          />
        </Form.Item>
        <Form.Item
          name="senior_pm"
          label="Team Leader"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(val) => setselctedtls(val.value)}
            disabled={
              (user && user.groups[0].name === 'Project Manager') ||
              (user && user.groups[0].name === 'Team Member')
                ? true
                : false
            }
          >
            {tls &&
              tls?.map((n: any) => (
                <Option
                  key={n.id}
                  value={n.id}
                  label={n.profile.first_name || n.profile.last_name}
                >
                  {n.profile.first_name + ' ' + n.profile.last_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {/* {selectedStatus === 'Delivered' || selectedStatus === 'Cancelled' ? (
          <div className="drawer__custom-double">
            <Form.Item
              name="spm_billable_hours"
              label="Billable Hours"
              rules={[
                {
                  required: true,
                  message: 'Fill the field',
                },
              ]}
            >
              <Input placeholder="" prefix={<i className="icon-clock"></i>} />
            </Form.Item>
            <Form.Item
              name="spm_non_billable_hours"
              label="Non-Billable Hours"
              rules={[
                {
                  required: true,
                  message: 'Fill the field',
                },
              ]}
            >
              <Input placeholder="" prefix={<i className="icon-clock"></i>} />
            </Form.Item>
          </div>
        ) : (
          ''
        )} */}
        <Form.Item
          name="pm"
          label="Project Manager"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            disabled={
              (user && user.groups[0].name === 'Project Manager') ||
              (user && user.groups[0].name === 'Team Member')
                ? true
                : false
            }
          >
            {pms &&
              pms?.map((n: any) => (
                <Option
                  key={n.id}
                  value={n.id}
                  label={n.profile.first_name || n.profile.last_name}
                >
                  {n.profile.first_name + ' ' + n.profile.last_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {selectedStatus === 'Delivered' || selectedStatus === 'Cancelled' ? (
          <div className="drawer__custom-double">
            <Form.Item
              name="pm_billable_hours"
              label="Billable Hours"
              rules={[
                {
                  required: true,
                  message: 'Fill the field',
                },
              ]}
            >
              <Input
                placeholder=""
                prefix={<i className="icon-clock"></i>}
                disabled={
                  // (rowData && rowData.pm?.id !== user.id) && (
                  // (user && user.groups[0].name === 'Project Manager') ||
                  user && user.groups[0].name === 'Team Member' ? true : false
                }
              />
            </Form.Item>
            <Form.Item
              name="pm_non_billable_hours"
              label="Non-Billable Hours"
              rules={[
                {
                  required: true,
                  message: 'Fill the field',
                },
              ]}
            >
              <Input
                placeholder=""
                prefix={<i className="icon-clock"></i>}
                disabled={
                  // (rowData && rowData.pm?.id !== user.id) && (
                  // (user && user.groups[0].name === 'Project Manager') ||
                  user && user.groups[0].name === 'Team Member' ? true : false
                }
              />
            </Form.Item>
          </div>
        ) : (
          ''
        )}
        {selectedStatus === 'Delivered' || selectedStatus === 'Cancelled' ? (
          <Form.List name="assigned">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'id']}
                        label="Assigned Team Members"
                        rules={[
                          {
                            required: true,
                            message: 'Fill the field',
                          },
                        ]}
                        style={{ height: 'auto' }}
                      >
                        <Select
                          labelInValue
                          placeholder=""
                          allowClear={true}
                          style={{ marginBottom: '0' }}
                          suffixIcon={
                            <i
                              className="icon-chevron-down"
                              style={{ fontSize: '20px', color: '#667085' }}
                            ></i>
                          }
                          showSearch
                          optionFilterProp="children"
                          filterSort={(optionA: any, optionB: any) =>
                            (optionA?.label ?? '')
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? '').toLowerCase(),
                              )
                          }
                          onChange={(val) => {
                            setSelectedAssigned(val)
                          }}
                          disabled={
                            user && user.groups[0].name === 'Team Member'
                              ? true
                              : false
                          }
                        >
                          {pmsData &&
                            pmsData.map((n: any) =>
                              n.groups.length !== 0 &&
                              n.groups?.find(
                                (m: any) => m.name !== 'Business Head',
                              ) ? (
                                <Option
                                  key={n.id}
                                  value={n.id}
                                  label={
                                    n.profile.first_name || n.profile.last_name
                                  }
                                >
                                  {n.profile.first_name +
                                    ' ' +
                                    n.profile.last_name}
                                </Option>
                              ) : (
                                ''
                              ),
                            )}
                        </Select>
                      </Form.Item>
                      {selectedStatus === 'Delivered' ||
                      selectedStatus === 'Cancelled' ? (
                        <div className="drawer__custom-double">
                          <Form.Item
                            {...restField}
                            name={[name, 'billable_hours']}
                            label="Billable Hours"
                            rules={[
                              {
                                required: true,
                                message: 'Fill the field',
                              },
                            ]}
                          >
                            <Input
                              placeholder=""
                              prefix={<i className="icon-clock"></i>}
                              disabled={
                                user && user.groups[0].name === 'Team Member'
                                  ? true
                                  : false
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'non_billable_hours']}
                            label="Non-Billable Hours"
                            rules={[
                              {
                                required: true,
                                message: 'Fill the field',
                              },
                            ]}
                          >
                            <Input
                              placeholder=""
                              prefix={<i className="icon-clock"></i>}
                              disabled={
                                user && user.groups[0].name === 'Team Member'
                                  ? true
                                  : false
                              }
                            />
                          </Form.Item>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    style={{
                      border: '1px solid #d0d5dd',
                      height: '44px',
                      borderRadius: '8px',
                      marginTop: '10px',
                    }}
                    disabled={
                      user && user.groups[0].name === 'Team Member'
                        ? true
                        : false
                    }
                  >
                    Add assigned
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        ) : (
          <Form.Item
            name="assigned"
            label="Assigned Team Members"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
            style={{ height: 'auto' }}
          >
            <Select
              mode="multiple"
              labelInValue
              placeholder=""
              allowClear={true}
              style={{ marginBottom: '0' }}
              suffixIcon={
                <i
                  className="icon-chevron-down"
                  style={{ fontSize: '20px', color: '#667085' }}
                ></i>
              }
              showSearch
              optionFilterProp="children"
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={(val) => {
                setSelectedAssigned(val)
              }}
            >
              {pmsData &&
                pmsData.map((n: any) =>
                  n.groups.length !== 0 &&
                  n.groups?.find((m: any) => m.name !== 'Business Head') ? (
                    <Option
                      key={n.id}
                      value={n.id}
                      label={n.profile.first_name || n.profile.last_name}
                    >
                      {n.profile.first_name + ' ' + n.profile.last_name}
                    </Option>
                  ) : (
                    ''
                  ),
                )}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="casecode" label="Case code">
          <Input
            placeholder=""
            suffix={
              <i
                className="icon-edit-2"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          />
        </Form.Item>

        <Form.Item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: ' 25px',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{
              width: '108px',
              height: '40px',
              flex: '0 1 50%',
              borderRadius: '8px',
              background: '#28C76F',
              border: '1px solid #28C76F',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            // loading={isLoading}
            className="login__btn"
          >
            <i
              className="icon-external-link"
              style={{ marginRight: '8px' }}
            ></i>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default SeeMoreRequests
