import { Button, Form, Input, Select, Switch } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IPageRequest } from 'src/app/models/page'
import services from 'src/services'
import user from 'src/resources/images/user.svg'

const { Option } = Select

const General = () => {
  const [form] = Form.useForm()

  const [data, setData] = useState<any>()
  const [alerts, setalerts] = useState<boolean>()
  const onChange = (checked: boolean) => {
    setalerts(checked)
  }

  useEffect(() => {
    if (data) {
      setalerts(data.is_alerts)
    }
  }, [data])

  const fetchData = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.settings.get()
      const { data } = res

      setData(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const updateRequest = useCallback(async (params?: any) => {
    try {
      const res = await services.settings.update(params)
      console.log(res)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const [rowIcon, setRowIcon] = useState('')
  const [rowIconasd, setRowIconasd] = useState('')
  const file: any = useRef(null)

  const loadIcon = (e: any) => {
    const file = e.target.files[0]
    setRowIconasd(URL.createObjectURL(file))
    setRowIcon(file)
  }

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.working_hours) {
      submitData.append('working_hours', values.working_hours)
    }
    if (values.outlook_username) {
      submitData.append('outlook_username', values.outlook_username)
    }
    if (values.outlook_password) {
      submitData.append('outlook_password', values.outlook_password)
    }
    submitData.append('is_alerts', alerts as any)

    if (rowIcon) {
      submitData.append('icon', rowIcon)
    }

    updateRequest(submitData)
  }

  useEffect(() => {
    form.setFieldsValue({
      working_hours: data && data.working_hours,
      // outlook_username: data && data.outlook_username,
      // outlook_password: data && data.outlook_password,
    })
  }, [form, data])

  return (
    <div className="general">
      <div className="general__title">General</div>
      <div className="general__body">
        <div className="general__left">
          <div className="general__left-img">
            <img
              src={
                rowIconasd ? rowIconasd : data && data.icon ? data.icon : user
              }
              alt=""
            />
            <input
              type="file"
              id="file"
              onChange={(e) => {
                loadIcon(e)
              }}
              ref={file}
              style={{ display: 'none' }}
            />
          </div>
          <div className="general__left-text">Change company logo</div>
          <Button
            className="general__left-btn"
            // onClick={() => {
            //   if (rowIcon) {
            //     updateRequest(formData)
            //   }
            // }}
            onClick={() => {
              file.current.click()
            }}
          >
            Update
          </Button>
        </div>
        <div className="general__right">
          <Form
            labelCol={{ span: 7 }}
            name="normal_login"
            form={form}
            onFinish={handleSubmit}
            style={{
              width: '100%',
              paddingBottom: '5px',
            }}
            className="login__form"
          >
            <Form.Item name="switch" label="Send alerts">
              <div className="general-switch">
                <span>{alerts ? 'On' : 'Off'}</span>
                <Switch checked={alerts} onChange={onChange} />
              </div>
            </Form.Item>
            <Form.Item name="working_hours" label="Set working hours">
              <Input prefix={<i className="icon-clock"></i>} />
            </Form.Item>
            <Form.Item name="outlook_username" label="Pipeline Email">
              <Input />
            </Form.Item>
            <Form.Item name="outlook_password" label="Pipeline Password">
              <Input.Password />
            </Form.Item>
            <Form.Item style={{ marginTop: 48, marginBottom: 0 }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    width: '143px',
                    borderRadius: '12px',
                    height: '44px',
                  }}
                  //   loading={isLoading}
                  className="login__btn"
                  //   onClick={onOkFilter}
                >
                  Save changes
                </Button>
                <Button
                  style={{
                    width: '95px',
                    height: '44px',
                    border: '1px solid #9092A7',
                    background: '#fff',
                    borderRadius: '12px',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#9092A7',
                    marginLeft: '17px',
                  }}
                  onClick={() => {
                    form.resetFields()
                  }}
                >
                  Discard
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default General
