const pageTitles: { [key: string]: string } = {
  dashboard: 'Dashboard',
  requests: 'Requests',
  pipeline: 'Pipeline',
  team: 'Team',
  analytics: 'Analytics',
  templates: 'Email Templates',
  closed: 'Closed Projects',
  approval: 'Approval Status',
  paid: 'Paid Invoices',
  projects: 'Projects',
  notify: 'Notifications',
}

export default pageTitles
