import BaseApi from '../base.service'

class Microsoft extends BaseApi {
  async all() {
    return await this.api.get(
      `https://login.microsoftonline.com/626d3628-55b8-48cc-aec8-d0904001d313/oauth2/v2.0/authorize?client_id=7a91b742-79e2-4795-b269-33fa4abe5288&redirect_uri=https%3A%2F%2Fapi.penguin.exadot.io%2Faccounts%2Fmicrosoft%2Flogin%2Fcallback%2F&scope=User.Read&response_type=code&state=spkxuzoiHRbb&sso_reload=true`,
    )
  }
}

export default Microsoft
