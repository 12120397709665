import { initialPageData } from 'src/app/constants'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPageRequest } from 'src/app/models/page'

export interface tableParamsState {
  data?: IPageRequest | null
}

const initialState: tableParamsState = {
  data: initialPageData,
}

export const tableParamsSlice = createSlice({
  name: 'tableParams',
  initialState,
  reducers: {
    updateTableParams: (state, action: PayloadAction<IPageRequest>) => {
      state.data = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateTableParams } = tableParamsSlice.actions

export default tableParamsSlice.reducer
