import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class Team extends BaseApi {
  async all(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/projects/requests/get_assigned/${query}`)
  }
  async allLeaved(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(
      `api/v1/account/users/get_leave_users_list/${query}`,
    )
  }
  async gettingFree(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(
      `api/v1/projects/requests/get_getting_free/${query}`,
    )
  }
}

export default Team
