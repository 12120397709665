import React, { useEffect, useState } from 'react'
import { AreaChart, XAxis, Tooltip, ResponsiveContainer, Area } from 'recharts'

interface IProps {
  data: any
  total: any
}

const LineHours: React.FC<IProps> = ({ data, total }) => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  return (
    <div className="dashboard__line hours">
      <div className="dashboard__line-top">
        <div className="dashboard__line-info">
          <div className="dashboard__line-title">
            {total && total.hours} hrs
          </div>
          <div className="dashboard__line-subtitle">Total billed hours</div>
        </div>
        <div className="dashboard__line-icon hours">
          <i className="icon-clock"></i>
        </div>
      </div>
      <div style={{ height: '100px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={730}
            height={250}
            data={
              data && Array.isArray(data)
                ? data.sort(
                    (a: any, b: any) =>
                      daysOfWeek.indexOf(a.item) - daysOfWeek.indexOf(b.item),
                  )
                : data
            }
            margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#7466F0" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#7466F0" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="item" style={{ paddingLeft: '30px' }} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="hours"
              stroke="#7466F0"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default LineHours
