import BaseApi from '../base.service'

class Email extends BaseApi {
  async all() {
    return await this.api.get(`api/v1/projects/emailtemplates/`)
  }
  async allDetail(id: string) {
    return await this.api.get(`api/v1/projects/emailtemplates/${id}`)
  }
  async delete(id: string) {
    return await this.api.delete(`api/v1/projects/emailtemplates/${id}/`)
  }
  async put() {
    return await this.api.put(`api/v1/projects/emailtemplates/5/`)
  }
  async post() {
    return await this.api.post(`api/v1/projects/emailtemplates/5/`)
  }
}

export default Email
