import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class Notifications extends BaseApi {
  async all(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/projects/notifications/${query}`)
  }

  async notify(id?: any) {
    return await this.api.get(`/api/v1/respond-leave-request/${id}`)
  }

  async allLeaved() {
    return await this.api.get(
      `/api/v1/projects/notifications/get_leave_user_notifications/`,
    )
  }

  async getLeavedById(id: any) {
    return await this.api.get(`/api/v1/get-leave-request/${id}`)
  }

  async updateLeavedById(id: any, data: any) {
    return await this.api.post(`/api/v1/set-leave-request/${id}`, data)
  }

  async reduce(id: any) {
    return await this.api.get(`/api/v1/projects/notifications/${id}/`)
  }

  async setReadAll(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`/api/v1/projects/notifications/set_marked_read_all/${query}`)
  }
}

export default Notifications
