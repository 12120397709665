import BaseApi from '../base.service'

class Excel extends BaseApi {
  async all() {
    return await this.api.get(`api/v1/projects/export-excel/`, {
      headers: { 'Content-Type': 'blob' },
      responseType: 'arraybuffer',
    })
  }
}

export default Excel
