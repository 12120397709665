import React from 'react'
import { AreaChart, XAxis, Tooltip, ResponsiveContainer, Area } from 'recharts'

interface IProps {
  data: any
  total: any
}

const LineTasks: React.FC<IProps> = ({ data, total }) => {
  return (
    <div className="dashboard__line">
      <div className="dashboard__line-top">
        <div className="dashboard__line-info">
          <div className="dashboard__line-title">{total && total.tasks}</div>
          <div className="dashboard__line-subtitle">Total billed Tasks</div>
        </div>
        <div className="dashboard__line-icon task">
          <i className="icon-Coffee"></i>
        </div>
      </div>
      <div style={{ height: '100px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={730}
            height={250}
            data={data}
            margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorPv1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FF9F43" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FF9F43" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="item" style={{ paddingLeft: '30px' }} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="tasks"
              stroke="#FF9F43"
              fillOpacity={1}
              fill="url(#colorPv1)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default LineTasks
