import { Button, Modal } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { IPageRequest } from 'src/app/models/page'
import AddClients from 'src/components/common/AddClients'
import AntTable from 'src/components/common/AntTable'
import SeeMoreClients from 'src/components/common/SeeMoreClients'
import services from 'src/services'

const Clients = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [tableParams, setTableParams] = useState<IPageRequest>({
    page: 1,
    page_size: 10,
  })
  const [dataCount, setDataCount] = useState({
    count: 0,
  })
  const [data, setData] = useState()
  const [rowIcon, setRowIcon] = useState('')
  const [rowIconAdd, setRowIconAdd] = useState('')

  const fetchData = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.clients.getCompany(params)
      const { count, results } = res.data

      setData(results)
      setDataCount({
        count,
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchData(tableParams)
  }, [fetchData, tableParams])

  const columns = [
    {
      title: 'Company',
      dataIndex: 'profile',
      render: (profile: any) => (
        <div className="table__user">
          <div className="table__user-img">
            <img src={profile?.avatar} alt="" />
          </div>
          <div className="table__user-info">
            <div className="table__user-name">{profile?.company}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      render: (domain: any) => (
        <div
          style={{
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            color: '#101828',
          }}
        >
          {domain}
        </div>
      ),
    },
    {
      title: 'Taxable',
      dataIndex: 'taxable',
      render: (taxable: any) =>
        taxable ? (
          <div
            style={{
              padding: '2px 8px',
              background: '#ECFDF3',
              borderRadius: '16px',
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '18px',
              textAlign: 'center',
              color: '#027A48',
              width: 'fit-content',
            }}
          >
            Yes
          </div>
        ) : (
          <div
            style={{
              padding: '2px 8px',
              background: '#FEF3F2',
              borderRadius: '16px',
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '18px',
              textAlign: 'center',
              color: '#B42318',
              width: 'fit-content',
            }}
          >
            No
          </div>
        ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              showDrawer()
              fetchDataById(rowData.id)
            }}
          >
            <i className="icon-edit-2"></i>
          </Button>
          <Button
            type="primary"
            className="table-actions__more"
            onClick={() => {
              showModal()
              setrowId(rowData.id)
            }}
          >
            <i className="icon-trash-2"></i>
          </Button>
        </div>
      ),
    },
  ]

  const handleTableChange = (params: IPageRequest) => {
    setTableParams((prev) => ({
      ...prev,
      ...params,
    }))
  }

  const [open, setOpen] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [rowData, setRowData] = useState<any>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rowId, setrowId] = useState(null)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
    setRowData(null)
  }

  const showDrawerAdd = () => {
    setOpenAdd(true)
    setresetForm(false)
  }

  const onCloseAdd = () => {
    setOpenAdd(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = (val: any) => {
    setIsModalOpen(false)
    handleDelete()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleDelete = async () => {
    if (!rowId) return

    try {
      const res = await services.clients.delete(rowId)
      if (res.status === 204) {
        fetchData()
        setIsModalOpen(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchDataById = async (id: string) => {
    setIsLoading(true)
    try {
      const res = await services.clients.allDetails(id)
      const { data } = res
      setRowData(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.domain) {
      submitData.append('domain', values.domain)
    }
    if (values.company) {
      submitData.append('company', values.company)
    }
    if (values.taxable) {
      submitData.append(
        'taxable',
        values.taxable.value ? values.taxable.value : values.taxable,
      )
    }
    if (rowIcon) {
      submitData.append('icon', rowIcon)
    }
    if (values.charge_rates) {
      submitData.append('charge_rates', JSON.stringify(values.charge_rates))
    }

    updateRequest(rowData?.id, submitData)
  }

  const handleSubmitAdd = (values: any) => {
    const submitData = new FormData()

    if (values.domain) {
      submitData.append('domain', values.domain)
    }
    if (values.company) {
      submitData.append('company', values.company)
    }
    if (values.charge_rates) {
      let newArr
      newArr = values.charge_rates.map((n: any) => ({
        price: n.price,
        type_of_work: n.id,
        type_of_work_name: n.name,
      }))
      if (newArr !== undefined) {
        submitData.append('charge_rates', JSON.stringify(newArr))
      }
    }
    if (values.taxable) {
      submitData.append(
        'taxable',
        values.taxable?.value ? values.taxable?.value : values.taxable,
      )
    }
    if (rowIconAdd) {
      submitData.append('icon', rowIconAdd)
    }

    AddClient(submitData)
  }

  const updateRequest = useCallback(
    async (id: string, params?: any) => {
      setIsLoading(true)
      try {
        const res = await services.clients.updateUser(id, params)

        if (res.status === 200) {
          onClose()
          fetchData()
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
      }
    },
    [fetchData],
  )

  const [resetForm, setresetForm] = useState(false)

  const AddClient = useCallback(
    async (params?: any) => {
      setIsLoading(true)
      try {
        const res = await services.clients.post(params)

        if (res.status === 200) {
          onCloseAdd()
          fetchData()
          setresetForm(true)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
      }
    },
    [fetchData],
  )

  return (
    <div className="employees">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className="employees__title">Company</div>
        <Button
          type="primary"
          style={{
            background: '#568EFF',
            border: '1px solid #568EFF',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '12px',
            marginRight: '24px',
            height: '44px',
            padding: '10px 18px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFFFFF',
          }}
          onClick={showDrawerAdd}
        >
          Add
        </Button>
      </div>
      <AntTable
        data={data}
        columns={columns}
        loading={isLoading}
        rowsCount={dataCount.count}
        tableParams={tableParams}
        onTableChange={handleTableChange}
      />
      {!isLoading && (
        <SeeMoreClients
          onSubmit={handleSubmit}
          open={open}
          onClose={onClose}
          title="Company"
          rowData={rowData}
          setRowIcon={setRowIcon}
        />
      )}
      <AddClients
        onSubmit={handleSubmitAdd}
        open={openAdd}
        onClose={onCloseAdd}
        title="Add company"
        setRowIcon={setRowIconAdd}
        resetForm={resetForm}
      />
      <Modal
        title={<i className="icon-alert-circle"></i>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal__custom"
        width={400}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Confirm
          </Button>,
        ]}
      >
        <div className="modal__custom-title">Delete employee</div>
        <div className="modal__custom-subtitle">
          Are you sure you want to delete from platform?
        </div>
      </Modal>
    </div>
  )
}

export default Clients
