import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  message,
  Modal,
  Select,
} from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { CornerDownRight, Filter } from 'react-feather'
import { serverDateFormat } from 'src/app/constants'
import { useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'
import AntTable from 'src/components/common/AntTable'
import FilterRequests from 'src/components/common/FilterRequests'
import RequestDetail from 'src/components/common/RequestDetail'
import services from 'src/services'

const { Option } = Select
const { RangePicker } = DatePicker

const Requests = () => {
  const [selectedValue, setSelectedValue] = useState('today')
  const [tableParams, setTableParams] = useState<IPageRequest>({
    page: 1,
    page_size: 10,
    date_range: selectedValue,
  })
  const [dataCount, setDataCount] = useState({
    count: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rowId, setrowId] = useState(null)
  const [data, setData] = useState()
  const [searchValue, setSearchValue] = useState<any>(null)
  const [rowData, setRowData] = useState<any>()
  const [datePicker, setdatePicker] = useState<any>()
  const [timePicker, settimePicker] = useState<any>()
  const [complationPicker, setcomplationPicker] = useState<any>()
  const [datePickerFilter, setdatePickerFilter] = useState<any>(null)
  const [datePickerMain, setdatePickerMain] = useState([])
  const [complationPickerFilter, setcomplationPickerFilter] =
    useState<any>(null)
  const windowWidth = useAppSelector((state) => state.windowWidth.data)
  const user = useAppSelector((state) => state.user.data)
  const [selectedValueShow, setSelectedValueShow] = useState('Today')

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = (val: any) => {
    setIsModalOpen(false)
    handleDelete()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const fetchData = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.requests.getList(params)
      const { count, results } = res.data

      setData(results)
      setDataCount({
        count,
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (selectedValue && selectedValue !== 'Custom Dates') {
      setTableParams({
        ...tableParams,
        date_range: selectedValue === 'today' ? '' : selectedValue,
        default_today: selectedValue === 'today' ? 1 : '',
      })
      setSelectedValue('')
    }
  }, [selectedValue, tableParams])

  useEffect(() => {
    if (tableParams) {
      fetchData(tableParams)
    }
  }, [fetchData, tableParams])

  useEffect(() => {
    if (
      datePickerMain &&
      datePickerMain.length !== 0 &&
      selectedValue === 'Custom Dates'
    ) {
      delete tableParams.date_range
      setTableParams({
        ...tableParams,
        start_date: dayjs(datePickerMain[0])
          .startOf('day')
          .format('YYYY-MM-DD'),
        end_date: dayjs(datePickerMain[1]).endOf('day').format('YYYY-MM-DD'),
      })
      setSelectedValue('')
    }
  }, [datePickerMain, fetchData, selectedValue, tableParams])

  const [allRequests, setAllRequests] = useState<any>()

  const fetchAllData = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.requests.getList(params)
      const { results } = res.data

      setAllRequests(results)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [selectedStatus, setselectedStatus] = useState<any>(null)
  const [selectedAssigned, setSelectedAssigned] = useState<any>(null)
  const [clientCompany, setclientCompany] = useState<any>(null)
  const [selectedChargeRate, setselectedChargeRate] = useState<any>(null)
  const [selectedType, setselectedType] = useState<any>(null)

  useEffect(() => {
    if (rowData) {
      setselectedStatus(rowData.status?.name)
    }
  }, [rowData])

  const [newClient, setNewClient] = useState<any>(null)

  const updateRequest = useCallback(
    async (id: string, params?: any) => {
      setIsLoading(true)
      try {
        const res = await services.requests.updateRequests(id, params)

        if (res.status === 200) {
          onClose()
          fetchData({ ...tableParams })
          setdatePicker(undefined)
          settimePicker(undefined)
          setcomplationPicker(undefined)
          setSelectedClient(null)
          setclientCompany(null)
          setselectedStatus(null)
          setselectedChargeRate(null)
          setselectedType(null)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
      }
    },
    [fetchData, tableParams],
  )

  const handleDelete = async () => {
    if (!rowId) return

    try {
      const res = await services.requests.deleteRequests(rowId)
      if (res.status === 204) {
        fetchData({ ...tableParams })
        setIsModalOpen(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchDataById = async (id: string) => {
    setIsLoading(true)
    try {
      const res = await services.requests.getDetail(id)
      const { data } = res
      fetchAllData()
      setRowData(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    if (searchValue) {
      const handler = setTimeout(() => {
        setTableParams({ ...tableParams, search: searchValue })
      }, 300)

      return () => {
        clearTimeout(handler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const columns = [
    {
      title: 'Requests ID',
      dataIndex: ['id_code', 'parent'],
      render: (text: any, row: any) => (
        <div style={{ color: '#101828', fontWeight: '500' }}>
          {row['id_code']} <br />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row['parent'] !== null && row['parent'] !== null ? (
              <CornerDownRight
                style={{
                  color: '#667085',
                }}
              />
            ) : (
              ''
            )}
            <span
              style={{
                marginTop: '4px',
              }}
            >
              {row['parent']}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (client: any) => (
        <div className="table__user">
          <div className="table__user-img">
            {client && client.icon ? (
              <img src={client?.icon} alt="" />
            ) : client?.name === undefined || client?.surname === undefined ? (
              ''
            ) : (
              client?.name.charAt(0) + '' + client?.surname.charAt(0)
            )}
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>{`${
              client?.name ? client?.name : ''
            } ${client?.surname ? client?.surname : ''}`}</div>
            <div className="table__user-email">{client?.email}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: any) => (
        <div className={`table__user-status ${status?.name}`}>
          {status?.name}
        </div>
      ),
    },
    {
      title: 'Assigned TM',
      dataIndex: 'assigned',
      render: (assigned: any) => (
        <div>
          {assigned.map((n: any) => (
            <div>{n.first_name + ' ' + n.last_name}</div>
          ))}
        </div>
      ),
    },
    {
      title: 'Senior Project Manager',
      dataIndex: 'senior_pm',
      render: (senior_pm: any) => (
        <div style={{ color: '#101828', fontWeight: '500' }}>
          {senior_pm?.first_name ? senior_pm?.first_name : ''}{' '}
          {senior_pm?.last_name ? senior_pm?.last_name : ''}
        </div>
      ),
    },
    {
      title: 'Project Manager',
      dataIndex: 'pm',
      render: (pm: any) => (
        <div style={{ color: '#101828', fontWeight: '500' }}>
          {pm?.first_name ? pm?.first_name : ''}{' '}
          {pm?.last_name ? pm?.last_name : ''}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              showDrawer()
              fetchDataById(rowData.id)
            }}
          >
            <i className="icon-eye"></i>
          </Button>
          {(user && user.groups[0].name === 'Business Head') ||
          (user && user.groups[0].name === 'Senior Project Manager') ? (
            <Button
              type="primary"
              className="table-actions__more"
              onClick={() => {
                showModal()
                setrowId(rowData.id)
              }}
            >
              <i className="icon-trash-2"></i>
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ]

  const columnsMobile = [
    {
      title: 'Requests ID',
      dataIndex: ['id_code', 'parent'],
      render: (text: any, row: any) => (
        <div style={{ color: '#101828', fontWeight: '500' }}>
          {row['id_code']} <br />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row['parent'] !== null && row['parent'] !== null ? (
              <CornerDownRight
                style={{
                  color: '#667085',
                }}
              />
            ) : (
              ''
            )}
            <span
              style={{
                marginTop: '4px',
              }}
            >
              {row['parent']}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              showDrawer()
              fetchDataById(rowData.id)
            }}
          >
            <i className="icon-eye"></i>
          </Button>
          {(user && user.groups[0].name === 'Business Head') ||
          (user && user.groups[0].name === 'Senior Project Manager') ? (
            <Button
              type="primary"
              className="table-actions__more"
              onClick={() => {
                showModal()
                setrowId(rowData.id)
              }}
            >
              <i className="icon-trash-2"></i>
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ]

  const [isLoadingExpanded, setisLoadingExpanded] = useState(false)
  const [expandedData, setexpandedData] = useState<any>()

  const fetchDataExpanded = useCallback(async (params?: IPageRequest) => {
    setisLoadingExpanded(true)
    try {
      const res = await services.requests.getParent(params)
      const { results } = res.data

      setexpandedData(results)
      setisLoadingExpanded(false)
    } catch (e) {
      setisLoadingExpanded(false)
      console.log(e)
    }
  }, [])

  const columnsExpanable = [
    {
      title: 'Requests ID',
      dataIndex: 'id_code',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (client: any) => (
        <div className="table__user">
          <div className="table__user-img">
            {client && client.icon ? (
              <img src={client?.icon} alt="" />
            ) : client?.name === undefined || client?.surname === undefined ? (
              ''
            ) : (
              client?.name.charAt(0) + '' + client?.surname.charAt(0)
            )}
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>{`${
              client?.name ? client?.name : ''
            } ${client?.surname ? client?.surname : ''}`}</div>
            <div className="table__user-email">{client?.email}</div>
          </div>
        </div>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: any) => (
        <div className={`table__user-status ${status?.name}`}>
          {status?.name}
        </div>
      ),
    },
    {
      title: 'Assigned TM',
      dataIndex: 'assigned',
      render: (assigned: any) => (
        <div>
          {assigned.map((n: any) => (
            <div>{n.first_name + ' ' + n.last_name}</div>
          ))}
        </div>
      ),
    },
    {
      title: 'Senior Project Manager',
      dataIndex: 'senior_pm',
      render: (senior_pm: any) => (
        <div style={{ color: '#101828', fontWeight: '500' }}>
          {senior_pm?.first_name ? senior_pm?.first_name : ''}{' '}
          {senior_pm?.last_name ? senior_pm?.last_name : ''}
        </div>
      ),
    },
    {
      title: 'Project Manager',
      dataIndex: 'pm',
      render: (pm: any) => (
        <div style={{ color: '#101828', fontWeight: '500' }}>
          {pm?.first_name ? pm?.first_name : ''}{' '}
          {pm?.last_name ? pm?.last_name : ''}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              showDrawer()
              fetchDataById(rowData.id)
            }}
          >
            <i className="icon-eye"></i>
          </Button>
          {(user && user.groups[0].name === 'Business Head') ||
          (user && user.groups[0].name === 'Senior Project Manager') ? (
            <Button
              type="primary"
              className="table-actions__more"
              onClick={() => {
                showModal()
                setrowId(rowData.id)
              }}
            >
              <i className="icon-trash-2"></i>
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ]

  const handleTableChange = (params: IPageRequest) => {
    setTableParams((prev) => ({
      ...prev,
      ...params,
    }))
  }

  const [openFilter, setOpenFilter] = useState(false)
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
    setRowData(null)
    setselectedChargeRate(null)
    setselectedType(null)
    setSelectedClient(null)
    setclientCompany(null)
    setNewClient(null)
    setselctedtls(null)
  }

  const showDrawerFilter = () => {
    setOpenFilter(true)
  }

  const onCloseFilter = () => {
    setOpenFilter(false)
  }

  const handleSubmitFilter = (values: any) => {
    let submitData = { ...values }

    if (datePickerFilter !== null) {
      submitData.datetime = dayjs(datePickerFilter)
        .startOf('day')
        .format('YYYY-MM-DD')
    }
    if (complationPickerFilter !== null) {
      submitData.complation_date = dayjs(complationPickerFilter)
        .startOf('day')
        .format('YYYY-MM-DD')
    }
    if (submitData && submitData.status !== undefined) {
      submitData.status = submitData.status.value
    }

    fetchData({ ...submitData })
  }

  const handleSubmit = (values: any) => {
    let submitData = { ...values }

    if (
      (submitData.assigned &&
        selectedStatus &&
        selectedStatus === 'Delivered') ||
      (submitData.assigned && selectedStatus && selectedStatus === 'Cancelled')
    ) {
      let some: any = []
      let newArr: any
      values.assigned?.map((n: any) => {
        newArr = {
          id: n.id.value ? n.id.value : n.id,
          billable_hours: n.billable_hours,
          non_billable_hours: n.non_billable_hours,
        }
        some.push(newArr)
        return newArr
      })
      submitData.assigned = some
    } else if (submitData.assigned) {
      let some: any = []
      let newArr: any
      values.assigned?.map((n: any) => {
        newArr = { id: n.value ? n.value : n }
        some.push(newArr)
        return newArr
      })
      submitData.assigned = some
    }

    if (submitData.pm) {
      submitData.pm =
        selectedStatus === 'Delivered' || selectedStatus === 'Cancelled'
          ? {
              id: values.pm.value ? values.pm.value : values.pm,
              billable_hours: values.pm_billable_hours
                ? values.pm_billable_hours
                : '',
              non_billable_hours: values.pm_non_billable_hours
                ? values.pm_non_billable_hours
                : '',
            }
          : {
              id: values.pm.value ? values.pm.value : values.pm,
            }
    }
    if (submitData.senior_pm) {
      submitData.senior_pm = {
        id: values.senior_pm.value ? values.senior_pm.value : values.senior_pm,
      }
    }

    if (submitData.company) {
      submitData.company = values.company
    }
    if (values.client) {
      submitData.client = values.client.value
        ? values.client.value
        : values.client
    }
    if (values.subject) {
      submitData.subject = values.subject
    }
    if (values.casecode) {
      submitData.casecode = values.casecode
    }

    submitData.datetime =
      values.datetime &&
      dayjs(datePicker).format('YYYY-MM-DD') +
        'T' +
        dayjs(timePicker).format('HH:mm:ss')

    submitData.id = rowData && rowData.id
    submitData.complation_date =
      values.complation_date && dayjs(complationPicker).format(serverDateFormat)

    if (values.parent) {
      submitData.parent =
        values.parent?.value === 'null'
          ? null
          : values.parent?.value !== 'null'
          ? values.parent?.value
          : values.parent
    }
    if (values.status !== undefined) {
      submitData.status = values.status?.value
        ? values.status.value
        : values.status
    }
    submitData.estimated_hours = values.estimated_hours
    if (submitData.billable_hours) {
      submitData.billable_hours = values.billable_hours
    }
    if (submitData.non_billable_hours) {
      submitData.non_billable_hours = values.non_billable_hours
    }
    submitData.charge_rate = values.charge_rate
    if (values.type_of_work !== undefined) {
      submitData.type_of_work = values.type_of_work?.value
        ? values.type_of_work.value
        : values.type_of_work
    }
    if (values.sector !== null) {
      submitData.sector = values.sector.value
        ? values.sector.value
        : values.sector
    }
    delete submitData.name

    updateRequest(rowData?.id, submitData)
  }

  const [pmsData, sepPmsData] = useState<any>()
  const [statusData, sepStatusData] = useState<any>()
  const [secotrData, sepSecotrData] = useState<any>()
  const [assignedData, setassignedData] = useState<any>()

  const fetchAssigned = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAssigned()
  }, [fetchAssigned])

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.all()
      const { results } = res.data

      sepPmsData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  const [tls, settls] = useState<any>(null)
  const [pms, setpms] = useState<any>(null)
  const [selctedtls, setselctedtls] = useState<any>(null)

  const fetchTL = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getTL()
      const { results } = res.data

      settls(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTL()
  }, [fetchTL])

  const fetchPM = useCallback(async (data?: any) => {
    try {
      const res = await services.user.getPM({ id: data })
      const { results } = res.data

      setpms(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (selctedtls) {
      fetchPM(selctedtls)
    }
  }, [fetchPM, selctedtls])

  const fetchStatuses = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.status.all()
      const { results } = res.data

      sepStatusData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchStatuses()
  }, [fetchStatuses])

  const fetchSector = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.sector.all()
      const { results } = res.data

      sepSecotrData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchSector()
  }, [fetchSector])

  const [types, settypes] = useState<any>()

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  const [clients, setClients] = useState<any>(null)

  const fetchClients = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.clients.all({ page_size: 1000 })
      const { results } = res.data

      setClients(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  const fetchClientsAdd = useCallback(
    async (data: any) => {
      try {
        const res = await services.clients.add(data)

        if (res.status === 200) {
          setNewClient(res.data)
          fetchClients()
        }
      } catch (e: any) {
        message.error(e.response.data.msg)
      }
    },
    [fetchClients],
  )

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  return (
    <div className="requests">
      <div className="requests__title page-title">
        Requests
        {windowWidth && windowWidth < 650 ? (
          <Filter
            onClick={showDrawerFilter}
            style={{ color: '#9092A7', marginRight: '48px' }}
          />
        ) : (
          ''
        )}
      </div>
      <div className="requests__top">
        <div className="requests__top-left">
          <Dropdown
            placement="bottomLeft"
            trigger={['click']}
            overlay={
              <div
                style={{
                  background: '#fff',
                  borderRadius: '12px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
                  minWidth: '280px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <span
                    onClick={() => {
                      setSelectedValue('today')
                      setSelectedValueShow('Today')
                      setdatePickerMain([])
                    }}
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: 'rgba(0, 0, 0, 0.85)',
                      marginBottom: '10px',
                      cursor: 'pointer',
                      flex: '0 1 50%',
                    }}
                  >
                    Today
                  </span>
                  <span
                    onClick={() => {
                      setSelectedValue('week')
                      setSelectedValueShow('Week')
                      setdatePickerMain([])
                    }}
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: 'rgba(0, 0, 0, 0.85)',
                      marginBottom: '10px',
                      cursor: 'pointer',
                      flex: '0 1 50%',
                    }}
                  >
                    Week
                  </span>
                  <span
                    onClick={() => {
                      setSelectedValue('month')
                      setSelectedValueShow('Month')
                      setdatePickerMain([])
                    }}
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: 'rgba(0, 0, 0, 0.85)',
                      marginBottom: '10px',
                      cursor: 'pointer',
                      flex: '0 1 50%',
                    }}
                  >
                    Month
                  </span>
                  <span
                    onClick={() => {
                      setSelectedValue('year')
                      setSelectedValueShow('Year')
                      setdatePickerMain([])
                    }}
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: 'rgba(0, 0, 0, 0.85)',
                      marginBottom: '10px',
                      cursor: 'pointer',
                      flex: '0 1 50%',
                    }}
                  >
                    Year
                  </span>
                </div>
                <RangePicker
                  allowClear={true}
                  style={{
                    // border: 'none',
                    borderRadius: '12px',
                    height: '40px',
                    padding: '10px',
                  }}
                  separator={false}
                  onChange={(el: any) => {
                    setdatePickerMain(el)
                  }}
                  name="rangepicker"
                  value={
                    datePickerMain !== null
                      ? [
                          datePickerMain && datePickerMain[0],
                          datePickerMain && datePickerMain[1],
                        ]
                      : ([] as any)
                  }
                  placement="bottomLeft"
                  clearIcon={<i className="icon-x"></i>}
                  onClick={() => {
                    setSelectedValue('Custom Dates')
                    setSelectedValueShow('Custom Dates')
                  }}
                />
              </div>
            }
          >
            <div
              style={{
                height: '40px',
                background: '#fff',
                borderRadius: '12px',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20px',
                padding: '10px',
                marginRight: '12px',
                minWidth: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {selectedValueShow}
              <i
                className="icon-chevron-down"
                style={{
                  fontSize: '20px',
                  color: 'rgb(102, 112, 133)',
                  marginLeft: '5px',
                }}
              />
            </div>
          </Dropdown>
          <Select
            labelInValue
            placeholder="Project status"
            allowClear={true}
            bordered={false}
            className="custom"
            style={{
              marginBottom: '0',
              background: '#fff',
              borderRadius: '12px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              width: '153px',
              marginRight: '12px',
            }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            onChange={(val) => {
              if (val !== undefined) {
                setTableParams({ ...tableParams, status: val.value })
              } else {
                delete tableParams.status
                setTableParams({ ...tableParams })
              }
            }}
          >
            {statusData &&
              statusData.map((n: any) => (
                <Option value={n.id}>{n.name}</Option>
              ))}
          </Select>
          {windowWidth && windowWidth > 650 ? (
            <Button className="requests__top-filter" onClick={showDrawerFilter}>
              More filters
              <i
                style={{ fontSize: '20px', marginLeft: '8px' }}
                className="icon-Filters-lines"
              ></i>
            </Button>
          ) : (
            ''
          )}
          {!isLoading && (
            <RequestDetail
              onSubmit={handleSubmit}
              open={open}
              onClose={onClose}
              title="More Info"
              rowData={rowData}
              datePicker={datePicker}
              setdatePicker={setdatePicker}
              timePicker={timePicker}
              settimePicker={settimePicker}
              complationPicker={complationPicker}
              setcomplationPicker={setcomplationPicker}
              assigned={assignedData}
              pmsData={pmsData}
              allRequests={allRequests}
              statusData={statusData}
              secotrData={secotrData}
              types={types}
              clients={clients}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              selectedStatus={selectedStatus}
              setselectedStatus={setselectedStatus}
              selectedAssigned={selectedAssigned}
              setSelectedAssigned={setSelectedAssigned}
              clientCompany={clientCompany}
              setclientCompany={setclientCompany}
              selectedChargeRate={selectedChargeRate}
              setselectedChargeRate={setselectedChargeRate}
              selectedType={selectedType}
              setselectedType={setselectedType}
              fetchClientsAdd={fetchClientsAdd}
              setselctedtls={setselctedtls}
              tls={tls}
              pms={pms}
              newClient={newClient}
            />
          )}
          <FilterRequests
            open={openFilter}
            onClose={onCloseFilter}
            onSubmit={handleSubmitFilter}
            complationPickerFilter={complationPickerFilter}
            setcomplationPickerFilter={setcomplationPickerFilter}
            datePickerFilter={datePickerFilter}
            setdatePickerFilter={setdatePickerFilter}
            statusData={statusData}
          />
        </div>
        <div className="requests__top-right">
          <Input
            placeholder="Search"
            style={{ border: 'none' }}
            prefix={
              <i
                className="icon-search"
                style={{
                  color: '#9092A7',
                  marginRight: '8px',
                  fontSize: '20px',
                }}
              ></i>
            }
            allowClear
            onChange={(e) => {
              setSearchValue(e.target.value)
              if (e.target.value === '') {
                delete tableParams.search
                setTableParams({ ...tableParams })
              }
            }}
          />
        </div>
      </div>
      <AntTable
        data={data}
        columns={windowWidth && windowWidth > 650 ? columns : columnsMobile}
        loading={isLoading}
        rowsCount={dataCount.count}
        tableParams={tableParams}
        onTableChange={handleTableChange}
        isLoadingExpanded={isLoadingExpanded}
        expandedData={expandedData}
        willExpand={true}
        expandCols={columnsExpanable}
        fetchDataExpanded={fetchDataExpanded}
      />
      <Modal
        title={<i className="icon-alert-circle"></i>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal__custom"
        width={400}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Confirm
          </Button>,
        ]}
      >
        <div className="modal__custom-title">Delete request</div>
        <div className="modal__custom-subtitle">
          Are you sure you want to delete this request?
        </div>
      </Modal>
    </div>
  )
}

export default Requests
