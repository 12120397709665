import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import SimpleReactLightbox from 'simple-react-lightbox'
import { useAppDispatch } from './app/hooks'
import { updateWindowWidth } from './features/windowWidthSlice'

const App = () => {
  const [detectWidth, setDetectWidth] = useState<any>(0)
  const dispatch = useAppDispatch()

  const detectSize = () => {
    if (window !== undefined) {
      setDetectWidth(window.innerWidth)
    }
  }

  useEffect(() => {
    if (window !== undefined) {
      setDetectWidth(window.innerWidth)
    }

    if (detectWidth) {
      dispatch(updateWindowWidth(detectWidth))
    }

    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectWidth])

  return (
    <BrowserRouter>
      <SimpleReactLightbox>
        <Routes />
      </SimpleReactLightbox>
    </BrowserRouter>
  )
}

export default App
