import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class Dashboard extends BaseApi {
  async all(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/dashboard/${query}`)
  }
}

export default Dashboard
