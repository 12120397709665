import { Button, Drawer, Form, Input, Select } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useAppSelector } from 'src/app/hooks'
import DatePicker from './DatePicker'
import services from 'src/services'
import { Check } from 'react-feather'
import { useHistory } from 'react-router-dom'

interface IProps {
  open: boolean
  onClose: () => void
  title: string
  onSubmit: (values: any) => void
  rowData: any
  complationPicker: any
  setcomplationPicker: any
  assigned: any
  pmsData: any
  allRequests: any
  statusData: any
  types: any
  patchById?: any
}

const { Option } = Select

const SeeMoreClosed: React.FC<IProps> = ({
  open,
  onClose,
  title,
  onSubmit,
  rowData,
  complationPicker,
  setcomplationPicker,
  assigned,
  pmsData,
  allRequests,
  statusData,
  types,
  patchById,
}) => {
  const [form] = Form.useForm()
  const user = useAppSelector((state) => state.user.data)
  const history = useHistory()

  useEffect(() => {
    if (rowData && rowData.complation_date && complationPicker === undefined) {
      setcomplationPicker(dayjs(rowData.complation_date).startOf('day'))
    }
  }, [rowData, complationPicker, setcomplationPicker])

  useEffect(() => {
    form.setFieldsValue({
      name: rowData && rowData.name,
      client:
        rowData &&
        rowData.client &&
        rowData.client?.name +
          ' ' +
          rowData.client?.surname +
          ' ' +
          rowData.client?.email,
      email: rowData && rowData.email,
      type_of_work: rowData && rowData.type_of_work && rowData.type_of_work?.id,
      billable_hours: rowData && rowData.billable_hours,
      non_billable_hours: rowData && rowData.non_billable_hours,
      charge_rate: rowData && rowData.charge_rate,
      senior_pm: rowData && rowData.senior_pm?.id,
      pm: rowData && rowData.pm?.id,
      assigned: rowData && rowData.assigned?.map((n: any) => n.id),
      status: rowData && rowData.status && rowData.status?.id,
      casecode: rowData && rowData.casecode,
      parent: rowData && rowData?.parent === null ? 'null' : rowData?.parent,
      complation_date: rowData && dayjs(rowData.complation_date),
    })
  }, [form, rowData])

  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  useEffect(() => {
    if (windowWidth && windowWidth > 992) {
      if (open) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }, [open, windowWidth])

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      getContainer={false}
      visible={open}
      className="drawer__custom"
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name="name"
          label="Request"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          name="client"
          label="Client"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Input placeholder="" disabled={true} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item name="casecode" label="Case code">
          <Input
            placeholder=""
            suffix={
              <i
                className="icon-edit-2"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          />
        </Form.Item>
        <Form.Item name="charge_rate" label="Charge rate">
          <Input placeholder="" />
        </Form.Item>
        <div className="drawer__custom-double">
          <Form.Item
            name="complation_date"
            label="Complation date"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              suffixIcon={
                <i className="icon-calendar" style={{ color: '#9092a7' }}></i>
              }
              onChange={(el) => {
                setcomplationPicker(el)
              }}
              value={complationPicker}
            />
          </Form.Item>
          <Form.Item name="status" label="Request status">
            <Select
              labelInValue
              placeholder=""
              allowClear={true}
              style={{ marginBottom: '0' }}
              suffixIcon={
                <i
                  className="icon-chevron-down"
                  style={{ fontSize: '20px', color: '#667085' }}
                ></i>
              }
            >
              {statusData &&
                statusData.map((n: any) => (
                  <Option value={n.id}>{n.name}</Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div className="drawer__custom-double">
          <Form.Item
            name="billable_hours"
            label="Billable Hours"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <Input placeholder="" prefix={<i className="icon-clock"></i>} />
          </Form.Item>
          <Form.Item
            name="non_billable_hours"
            label="Non-Billable Hours"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <Input placeholder="" prefix={<i className="icon-clock"></i>} />
          </Form.Item>
        </div>
        <Form.Item
          name="senior_pm"
          label="Senior PM name"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          >
            {pmsData &&
              pmsData.map((n: any) => (
                <Option value={n.id}>
                  {n.profile.first_name + ' ' + n.profile.last_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="pm"
          label="PM name"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          >
            {pmsData &&
              pmsData.map((n: any) => (
                <Option value={n.id}>
                  {n.profile.first_name + ' ' + n.profile.last_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="assigned"
          label="Assigned"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
          style={{ height: 'auto' }}
        >
          <Select
            mode="multiple"
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          >
            {pmsData &&
              pmsData.map((n: any) =>
                n.groups.length !== 0 &&
                n.groups?.map((m: any) => m.name !== 'Business Head') ? (
                  <Option
                    key={n.id}
                    value={n.id}
                    label={n.profile.first_name || n.profile.last_name}
                  >
                    {n.profile.first_name + ' ' + n.profile.last_name}
                  </Option>
                ) : (
                  ''
                ),
              )}
          </Select>
        </Form.Item>
        <Form.Item
          name="type_of_work"
          label="Type of work"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          >
            {types &&
              types.map((n: any) => <Option value={n.id}>{n.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="parent" label="Parent project">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          >
            <Option value="null">No Parent</Option>
            {allRequests &&
              allRequests.map((n: any) => (
                <Option value={n.id}>{n.id_code}</Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: ' 25px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '9px',
            }}
          >
            <Button
              type="primary"
              size="large"
              style={{
                width: '102px',
                height: '40px',
                borderRadius: '8px',
                background: '#F3F7FF',
                border: '1px solid #568EFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '11px',
                color: '#568EFF',
              }}
              // loading={isLoading}
              className="login__btn"
              onClick={onClose}
            >
              <i
                className="icon-upload-cloud"
                style={{ marginRight: '8px', color: '#568EFF' }}
              ></i>
              Cancel
            </Button>
            {history.location.pathname === '/closed' ? (
              <Button
                type="primary"
                size="large"
                style={{
                  width: '102px',
                  height: '40px',
                  borderRadius: '8px',
                  background: '#f18b21',
                  border: '1px solid #f18b21',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '11px',
                  color: '#fff',
                }}
                // loading={isLoading}
                className="login__btn"
                onClick={() => {
                  user && user.groups[0].name === 'Senior Project Manager'
                    ? patchById(rowData.id, { step: 'closed' })
                    : patchById(rowData.id, { step: 'approval' })
                }}
              >
                <Check
                  style={{ marginRight: '8px', color: '#fff', minWidth: 16 }}
                />
                Approve
              </Button>
            ) : (
              ''
            )}
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                width: '102px',
                height: '40px',
                flex: '0 1 50%',
                borderRadius: '8px',
                background: '#28C76F',
                border: '1px solid #28C76F',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              // loading={isLoading}
              className="login__btn"
            >
              <i
                className="icon-external-link"
                style={{ marginRight: '8px' }}
              ></i>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default SeeMoreClosed
