import React from 'react'
import { AreaChart, XAxis, Tooltip, ResponsiveContainer, Area } from 'recharts'

interface IProps {
  data: any
  total: any
}

const LineHours: React.FC<IProps> = ({ data, total }) => {
  return (
    <div className="dashboard__line hours">
      <div className="dashboard__line-top">
        <div className="dashboard__line-info">
          <div className="dashboard__line-title">
            {total && total.total.billable_hours} hrs
          </div>
          <div className="dashboard__line-subtitle">Billable hours</div>
        </div>
        <div
          className="dashboard__line-icon hours"
          style={{ background: '#E9F4FF' }}
        >
          <i className="icon-clock" style={{ color: '#568EFF' }}></i>
        </div>
      </div>
      <div style={{ height: '100px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={730}
            height={250}
            data={data && data.data}
            margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#568EFF" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#568EFF" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name1" style={{ paddingLeft: '30px' }} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#568EFF"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default LineHours
