import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class Invoices extends BaseApi {
  async all(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/settings/invoices/${query}`)
  }
  async allSPM(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(
      `api/v1/settings/invoices/get_unmarked_list/${query}`,
    )
  }
  async getById(id?: any) {
    return await this.api.get(`api/v1/settings/invoices/${id}/`)
  }
  async patchById(id: any, data: any) {
    return await this.api.patch(`api/v1/settings/invoices/${id}/`, data)
  }
  async update(id: string, data?: any) {
    return await this.api.put(`api/v1/settings/invoices/${id}/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
  async delete(id: string) {
    return await this.api.delete(`api/v1/settings/invoices/${id}/`)
  }
  async getConfirm() {
    return await this.api.get(`api/v1/invoice-confirm/`)
  }
  async getPaid(id: string) {
    return await this.api.get(`api/v1/invoice-paid/${id}/`)
  }
  async updatePaid(id: string, data?: any) {
    return await this.api.put(`api/v1/invoice-paid/${id}/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

export default Invoices
