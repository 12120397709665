import { Button, message, Modal, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { defaultDateFormat, serverDateFormat } from 'src/app/constants'
import { useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'
import AntTable from 'src/components/common/AntTable'
import PipelineDetail from 'src/components/common/PipelineDetail'
import services from 'src/services'

const Pipeline = () => {
  const [tableParams, setTableParams] = useState<IPageRequest>({
    page: 1,
    page_size: 10,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [dataCount, setDataCount] = useState({
    count: 0,
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenFile, setIsModalOpenFile] = useState(false)
  const [data, setData] = useState()
  const [rowData, setRowData] = useState<any>(null)
  const [rowId, setrowId] = useState(null)
  const [datePicker, setdatePicker] = useState<any>()
  const [timePicker, settimePicker] = useState<any>()
  const [complationPicker, setcomplationPicker] = useState<any>()
  const [activated, setactivated] = useState(null)
  const user = useAppSelector((state) => state.user.data)
  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    handleDelete()
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showModalFile = () => {
    setIsModalOpenFile(true)
  }

  const handleOkFile = () => {
    setIsModalOpenFile(false)
    setRowData(null)
    setactivated(null)
  }

  const handleCancelFile = () => {
    setIsModalOpenFile(false)
    setRowData(null)
    setactivated(null)
  }

  const fetchData = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.pipeline.all(params)
      const { count, results } = res.data

      setData(results)
      setDataCount({
        count,
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  const handleDelete = async () => {
    if (!rowId) return

    try {
      const res = await services.pipeline.delete(rowId)

      if (res.status === 204) {
        fetchData()
        setIsModalOpen(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchDataById = async (id: string) => {
    setIsLoading(true)
    try {
      const res = await services.pipeline.allDetail(id)
      const { data } = res

      setRowData(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const fetchDataMore = async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.pipeline.more(params)
      const { results } = res.data

      setRowData(results)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    fetchData({ ...tableParams, assigned: 'False' })
  }, [fetchData, tableParams])

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (client: any) => (
        <div className="table__user">
          <div className="table__user-img">
            {client && client.icon ? (
              <img src={client?.icon} alt="" />
            ) : client?.name === undefined || client?.surname === undefined ? (
              ''
            ) : (
              client?.name.charAt(0) + '' + client?.surname.charAt(0)
            )}
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>{`${
              client?.name ? client?.name : ''
            } ${client?.surname ? client?.surname : ''}`}</div>
            <div className="table__user-email">{client?.email}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'datetime',
      render: (datetime: any) => (
        <div
          style={{
            padding: '2px 8px',
            background: '#EAF1FF',
            borderRadius: '16px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#568EFF',
            width: 'fit-content',
          }}
        >
          {dayjs(datetime).format(defaultDateFormat)}
        </div>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'datetime',
      render: (datetime: any) => (
        <div
          style={{
            padding: '2px 8px',
            background: '#EAF1FF',
            borderRadius: '16px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#568EFF',
            width: 'fit-content',
          }}
        >
          {dayjs(datetime).subtract(5, 'hour').format('h A')}
        </div>
      ),
    },
    {
      title: 'Email body',
      dataIndex: 'email_content',
      width: '210px',
      render: (email_content: any, rowData: any) => (
        <div>
          <div style={{ color: '#101828', fontWeight: '500' }}>
            {email_content.title}
          </div>
          <div className="table__user-more">
            {email_content.body}
            <span
              onClick={() => {
                if (rowData) {
                  setactivated(rowData.id)
                  fetchDataMore({ client_id: rowData.client?.id })
                  showModalFile()
                }
              }}
            >
              More
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Attached file',
      dataIndex: 'attachments',
      render: (attachments: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a
            style={{
              color: '#101828',
              fontWeight: '500',
              lineHeight: '100%',
              display: 'flex',
              marginRight: '12px',
            }}
            href={attachments && attachments[0]?.file}
          >
            <i
              className="icon-file"
              style={{
                fontSize: '20px',
                padding: '10px',
                background: '#F4EBFF',
                color: '#7F56D9',
                borderRadius: '50%',
              }}
            ></i>
          </a>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                color: '#101828',
                fontWeight: '500',
                lineHeight: '100%',
              }}
            >
              attachments?.file
            </div>
            <span
              style={{
                color: '#667085',
                fontWeight: '500',
                lineHeight: '100%',
              }}
            >
              created_at
            </span>
          </div>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Tooltip title="Pick Request">
            <Button
              type="primary"
              className="table-actions__goto"
              onClick={() => {
                showDrawer()
                fetchDataById(rowData.id)
              }}
            >
              <i className="icon-send"></i>
            </Button>
          </Tooltip>
          {user && user.groups[0].name === 'Business Head' ? (
            <Button
              type="primary"
              className="table-actions__more"
              onClick={() => {
                showModal()
                setrowId(rowData.id)
              }}
            >
              <i className="icon-trash-2"></i>
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ]
  const columnsMobile = [
    {
      title: '#ID',
      dataIndex: 'id',
    },
    {
      title: 'Email body',
      dataIndex: 'email_content',
      width: '210px',
      render: (email_content: any, rowData: any) => (
        <div>
          <div style={{ color: '#101828', fontWeight: '500' }}>
            {email_content.title}
          </div>
          <div className="table__user-more">
            <span
              onClick={() => {
                if (rowData) {
                  setactivated(rowData.id)
                  fetchDataMore({ client_id: rowData.client?.id })
                  showModalFile()
                }
              }}
            >
              More
            </span>
          </div>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              showDrawer()
              fetchDataById(rowData.id)
            }}
          >
            <i className="icon-send"></i>
          </Button>
          {user && user.groups[0].name === 'Business Head' ? (
            <Button
              type="primary"
              className="table-actions__more"
              onClick={() => {
                showModal()
                setrowId(rowData.id)
              }}
            >
              <i className="icon-trash-2"></i>
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ]

  const handleTableChange = (params: IPageRequest) => {
    setTableParams((prev) => ({
      ...prev,
      ...params,
    }))
  }

  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
    setNewClient(null)
  }

  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [selectedStatus, setselectedStatus] = useState<any>(null)
  const [selectedAssigned, setSelectedAssigned] = useState<any>(null)
  const [clientCompany, setclientCompany] = useState<any>(null)
  const [selectedChargeRate, setselectedChargeRate] = useState<any>(null)
  const [selectedType, setselectedType] = useState<any>(null)

  const createRequest = useCallback(
    async (params?: any) => {
      setIsLoading(true)
      try {
        const res = await services.requests.postRequests(params)

        if (res.status === 201) {
          onClose()
          fetchData()
          setselectedChargeRate(null)
          setselectedType(null)
          setSelectedClient(null)
          setclientCompany(null)
          setselectedStatus(null)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
      }
    },
    [fetchData],
  )

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.assigned) {
      submitData.append(
        'assigned',
        values.assigned?.map((n: any) => (n.value ? n.value : n)),
      )
    }

    if (values.company) {
      submitData.append('company', values.company)
    }
    if (values.subject) {
      submitData.append('subject', values.subject)
    }
    if (values.casecode) {
      submitData.append('casecode', values.casecode)
    }

    submitData.append(
      'datetime',
      values.datetime &&
        dayjs(datePicker).format('YYYY-MM-DD') +
          'T' +
          dayjs(timePicker).format('HH:mm:ss'),
    )
    submitData.append('id', rowData && rowData.id)
    submitData.append(
      'complation_date',
      values.complation_date &&
        dayjs(complationPicker).format(serverDateFormat),
    )

    if (values.pm) {
      submitData.append('pm', values.pm.value ? values.pm.value : values.pm)
    }
    submitData.append(
      'senior_pm',
      values.senior_pm.value ? values.senior_pm.value : values.senior_pm,
    )
    if (rowData.client) {
      submitData.append('client', rowData && rowData.client?.id)
    }
    if (values.parent) {
      submitData.append(
        'parent',
        values.parent?.value === 'null'
          ? ''
          : values.parent?.value !== 'null'
          ? values.parent?.value
          : values.parent,
      )
    }
    if (values.status !== undefined) {
      submitData.append(
        'status',
        values.status?.value ? values.status.value : values.status,
      )
    }
    submitData.append('estimated_hours', values.estimated_hours)
    submitData.append('charge_rate', values.charge_rate)
    if (values.type_of_work !== undefined) {
      submitData.append(
        'type_of_work',
        values.type_of_work?.value
          ? values.type_of_work.value
          : values.type_of_work,
      )
    }
    if (values.sector !== null) {
      submitData.append(
        'sector',
        values.sector.value ? values.sector.value : values.sector,
      )
    }

    createRequest(submitData)
  }

  const [assignedData, setassignedData] = useState<any>()
  const [pmsData, sepPmsData] = useState<any>()
  const [statusData, sepStatusData] = useState<any>()
  const [secotrData, sepSecotrData] = useState<any>()
  const [allRequests, setAllRequests] = useState<any>()

  const fetchAllData = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.requests.getList(params)
      const { results } = res.data

      setAllRequests(results)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAllData()
  }, [fetchAllData])

  const fetchAssigned = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAssigned()
  }, [fetchAssigned])

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.all()
      const { results } = res.data

      sepPmsData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  const fetchStatuses = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.status.all()
      const { results } = res.data

      sepStatusData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchStatuses()
  }, [fetchStatuses])

  const fetchSector = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.sector.all()
      const { results } = res.data

      sepSecotrData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchSector()
  }, [fetchSector])

  const [types, settypes] = useState<any>()

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  const userID = useAppSelector((state) => state.userPipeline.data)

  useEffect(() => {
    if (userID) {
      fetchData({ ...tableParams, search_user: userID })
    }
  }, [fetchData, tableParams, userID])

  const [clients, setClients] = useState<any>(null)

  const fetchClients = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.clients.all({ page_size: 1000 })
      const { results } = res.data

      setClients(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  const [newClient, setNewClient] = useState<any>(null)

  const fetchClientsAdd = useCallback(
    async (data: any) => {
      try {
        const res = await services.clients.add(data)

        if (res.status === 200) {
          setNewClient(res.data)
          fetchClients()
        }
      } catch (e: any) {
        message.error(e.response.data.msg)
      }
    },
    [fetchClients],
  )

  const [tls, settls] = useState<any>(null)
  const [pms, setpms] = useState<any>(null)
  const [selctedtls, setselctedtls] = useState<any>(null)

  const fetchTL = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getTL()
      const { results } = res.data

      settls(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTL()
  }, [fetchTL])

  const fetchPM = useCallback(async (data?: any) => {
    try {
      const res = await services.user.getPM({ id: data })
      const { results } = res.data

      setpms(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (selctedtls) {
      fetchPM(selctedtls)
    }
  }, [fetchPM, selctedtls])

  return (
    <div className="pipeline">
      <div className="pipeline__title page-title">Pipeline</div>
      <AntTable
        data={data}
        columns={windowWidth && windowWidth > 650 ? columns : columnsMobile}
        loading={isLoading}
        rowsCount={dataCount.count}
        tableParams={tableParams}
        onTableChange={handleTableChange}
        filterColumns={['lotId']}
      />
      <Modal
        title={<i className="icon-alert-circle"></i>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal__custom"
        width={400}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Confirm
          </Button>,
        ]}
      >
        <div className="modal__custom-title">Delete request from pipeline</div>
        <div className="modal__custom-subtitle">
          Are you sure you want to delete this pipeline?
        </div>
      </Modal>
      {rowData && activated !== null ? (
        <Modal
          visible={isModalOpenFile}
          onOk={handleOkFile}
          onCancel={handleCancelFile}
          className="modal__file"
          width={560}
          footer={null}
        >
          <div className="modal__file-items">
            {rowData &&
              rowData?.map((n: any) => (
                <div className="modal__file-item">
                  <div
                    className="modal__file-item-top"
                    onClick={() => {
                      setactivated(n.id)
                    }}
                  >
                    <div className="modal__file-item-top-left">
                      <div className="modal__file-item-img">
                        {n.client?.icon ? (
                          <img src={n.client?.icon} alt="" />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="modal__file-item-naming">
                        <div className="modal__file-item-name">
                          {n.client && n.client?.name + ' ' + n.client?.surname}
                        </div>
                        <div className="modal__file-item-email">
                          {n.client && n.client?.email}
                        </div>
                      </div>
                    </div>
                    <div className="modal__file-item-time">
                      {dayjs(n.datetime).format('HH:MM')}
                    </div>
                  </div>
                  {activated !== null && activated === n.id ? (
                    <div className="modal__file-item-bot">
                      <div className="modal__file-item-title">
                        {n.email_content?.title ? n.email_content.title : ''}
                      </div>
                      <div className="modal__file-item-subtitle">
                        {n.email_content?.body ? n.email_content.body : ''}
                      </div>
                      {n.attachments?.map((n: any) => (
                        <a
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            marginTop: '20px',
                          }}
                          href={n.file}
                          className="modal__file-item-file"
                        >
                          <i
                            className="icon-file"
                            style={{
                              fontSize: '20px',
                              padding: '10px',
                              background: '#EAF1FF',
                              color: '#6C99F3',
                              borderRadius: '50%',
                              marginRight: '12px',
                            }}
                          ></i>
                          <div
                            style={{
                              color: '#101828',
                              fontWeight: '500',
                              lineHeight: '150%',
                              fontSize: '14px',
                              maxWidth: '200px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {n.file}
                          </div>
                          <i
                            className="icon-download-cloud"
                            style={{
                              fontSize: '20px',
                              padding: '10px',
                              color: '#9092A7',
                            }}
                          ></i>
                        </a>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}
          </div>
        </Modal>
      ) : (
        ''
      )}
      {rowData && (
        <PipelineDetail
          onSubmit={handleSubmit}
          rowData={rowData}
          open={open}
          onClose={onClose}
          title="Pick Request"
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          selectedStatus={selectedStatus}
          setselectedStatus={setselectedStatus}
          selectedAssigned={selectedAssigned}
          setSelectedAssigned={setSelectedAssigned}
          clientCompany={clientCompany}
          setclientCompany={setclientCompany}
          selectedChargeRate={selectedChargeRate}
          setselectedChargeRate={setselectedChargeRate}
          selectedType={selectedType}
          setselectedType={setselectedType}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
      )}
    </div>
  )
}

export default Pipeline
