import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'

interface IProps {
  data: any
}

const PieCharts: React.FC<IProps> = ({ data }) => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
  return (
    <div className="analytics__pie">
      <div className="analytics__pie-title">Division Breakup</div>
      <div style={{ width: '100%', height: '70%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={100} height={400}>
            <Pie
              data={data}
              cy={100}
              innerRadius={
                // windowWidth && windowWidth < 992 ? 50 :
                80
              }
              outerRadius={
                // windowWidth && windowWidth < 992 ? 70 :
                100
              }
              fill="#8884d8"
              paddingAngle={0}
              dataKey="persent"
            >
              {data &&
                data.map((entry: any, index: any) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              <Legend />
              <Legend iconSize={10} layout="vertical" verticalAlign="bottom" />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="analytics__pie-items">
        <div className="analytics__pie-item">
          <i
            className="icon-monitor"
            style={{ fontSize: '18px', color: '#568EFF' }}
          ></i>
          <div className="analytics__pie-item-text">
            Analytics -&nbsp;
            <span>
              {data && data.find((n: any) => n.name === 'Analytics').persent}%
            </span>
          </div>
        </div>
        <div className="analytics__pie-item">
          <i
            className="icon-book"
            style={{ fontSize: '18px', color: '#FF9F43' }}
          ></i>
          <div className="analytics__pie-item-text">
            Research -&nbsp;
            <span>
              {data && data.find((n: any) => n.name === 'Research').persent}%
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PieCharts
