import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProviderModel } from 'src/app/models/provider'

export interface ProviderState {
  data: IProviderModel | null
}

const initialState: ProviderState = {
  data: null,
}

export const providerSlice = createSlice({
  name: 'crossRoads',
  initialState,
  reducers: {
    setProvider: (state, action: PayloadAction<IProviderModel>) => {
      state.data = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProvider } = providerSlice.actions

export default providerSlice.reducer
