import React from 'react'
import {
  ComposedChart,
  Bar,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  XAxis,
} from 'recharts'

interface IProps {
  data?: any
  customColor: string
  title: string
}

const ComposedAnalitics: React.FC<IProps> = ({ customColor, title, data }) => {
  return (
    <div className="analytics__composed-vertical">
      <div className="analytics__composed-vertical-title">{title}</div>
      <div style={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width="100%" height="90%">
          <ComposedChart
            layout="vertical"
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 50,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" />
            <Tooltip />
            <Bar
              dataKey="count"
              barSize={20}
              fill={customColor}
              radius={[0, 10, 10, 0]}
              label={{ position: 'right' }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default ComposedAnalitics
