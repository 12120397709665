import { Button } from 'antd'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { decrement, increment } from 'src/features/counterSlice'

const Counter = () => {
  const count = useAppSelector((state) => state.counter.value)
  const dispatch = useAppDispatch()

  return (
    <div>
      <Button onClick={() => dispatch(increment())}>Increment</Button>
      <span>{count}</span>
      <Button onClick={() => dispatch(decrement())}>Decrement</Button>
    </div>
  )
}

export default Counter
