import { Pagination, PaginationProps, Table } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import useColumnSearch from 'src/hooks/useColumnSearch'
import useDebounce from 'src/hooks/useDebounce'

interface IProps {
  data: any
  loading: boolean
  columns: object[]
  rowsCount: number
  onTableChange?: (params: { [key: string]: string }) => void
  tableParams?: any
  filterColumns?: string[]
  rowKey?: any
  rowClassName?: any
  components?: any
  setEventTable?: any
  setRowindex?: any
  withArrow?: boolean

  fetchDataExpanded?: any
  isLoadingExpanded?: boolean
  expandedData?: any
  expandCols?: object[]
  willExpand?: boolean
  company?: boolean
}

const AntTable: React.FC<IProps> = ({
  data,
  columns,
  loading,
  rowsCount,
  tableParams,
  onTableChange,
  filterColumns,
  rowKey,
  rowClassName,
  components,
  setEventTable,
  setRowindex,
  withArrow,
  fetchDataExpanded,
  isLoadingExpanded,
  expandedData,
  expandCols,
  willExpand,
  company,
}) => {
  const { upColumns } = useColumnSearch(
    columns,
    filterColumns ? filterColumns : [],
  )
  const [readyFilters, setReadyFilters] = useState<{ [key: string]: string }>(
    {},
  )

  const debouncedFilters = useDebounce(readyFilters, 200)

  const handleTableChange = useCallback(
    (action: string, data: any) => {
      if (!onTableChange) return

      switch (action) {
        case 'customPagination':
          onTableChange(data)
          break

        case 'sort':
          if (!data.sorter.order) return
          // transform ant's default sorting keys to custom
          let query =
            (data.sorter.order === 'descend' ? '-' : '') + data.sorter.field
          onTableChange({
            orderBy: query,
          })
          break

        case 'filter':
          const { filters } = data
          // console.log(filters)

          if (typeof filters === 'object' && Object.keys(filters).length > 0) {
            const allFilters: { [key: string]: string } = {}

            Object.keys(filters).forEach((el) => {
              const filter = filters[el]

              allFilters[el] = filter ? filter.join() : ''
            })

            return setReadyFilters(allFilters)
          }
          setReadyFilters({ statuses: '' })
          break

        default:
          break
      }
    },
    [onTableChange],
  )

  useEffect(() => {
    if (
      typeof debouncedFilters === 'object' &&
      Object.keys(debouncedFilters).length > 0
    )
      onTableChange && onTableChange(debouncedFilters)

    // console.log(debouncedFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters])

  const itemRender: PaginationProps['itemRender'] = (
    _,
    type,
    originalElement,
  ) => {
    if (withArrow) {
      if (type === 'prev') {
        return (
          <div style={{ width: '48px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                border: '1px solid #D0D5DD',
                borderRadius: '12px',
                padding: '8px 14px',
                width: 'fit-content',
              }}
            >
              <div
                style={{
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i
                  style={{ fontSize: '20px', color: '#344054' }}
                  className="icon-arrow-left"
                ></i>
              </div>
            </div>
          </div>
        )
      }
      if (type === 'next') {
        return (
          <div style={{ width: '48px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                border: '1px solid #D0D5DD',
                borderRadius: '12px',
                padding: '8px 14px',
                width: 'fit-content',
              }}
            >
              <div
                style={{
                  transform: 'rotate(180deg)',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i
                  style={{ fontSize: '20px', color: '#344054' }}
                  className="icon-arrow-left"
                ></i>
              </div>
            </div>
          </div>
        )
      }
    } else {
      if (type === 'prev') {
        return (
          <div style={{ width: '114px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                border: '1px solid #D0D5DD',
                borderRadius: '12px',
                padding: '8px 14px',
                width: 'fit-content',
              }}
            >
              <div
                style={{
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i
                  style={{ fontSize: '20px', color: '#344054' }}
                  className="icon-arrow-left"
                ></i>
              </div>
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#344054',
                  paddingLeft: '12px',
                }}
              >
                Previous
              </span>
            </div>
          </div>
        )
      }
      if (type === 'next') {
        return (
          <div style={{ width: '88px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                border: '1px solid #D0D5DD',
                borderRadius: '12px',
                padding: '8px 14px',
                width: 'fit-content',
              }}
            >
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#344054',
                  paddingRight: '12px',
                }}
              >
                Next
              </span>
              <div
                style={{
                  transform: 'rotate(180deg)',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i
                  style={{ fontSize: '20px', color: '#344054' }}
                  className="icon-arrow-left"
                ></i>
              </div>
            </div>
          </div>
        )
      }
    }

    return originalElement
  }

  const [expandedID, setExpandedID] = useState<any>(null)

  return (
    upColumns && (
      <div
        className="ant-table-custom"
        style={{ borderRadius: '12px', background: '#fff' }}
      >
        <Table
          expandedRowKeys={[expandedID]}
          expandable={{
            expandedRowRender: (record: any) => (
              <Table
                loading={isLoadingExpanded}
                dataSource={expandedData}
                pagination={false}
                columns={expandCols}
                style={{
                  maxHeight: '400px',
                  overflow: 'hidden',
                  overflowY: 'auto',
                  borderBottom: '1px solid #000',
                }}
                className="expanedable"
              />
            ),
            showExpandColumn: willExpand ? true : false,
          }}
          onExpand={(expanded, record) => {
            if (expandedID !== record.id) {
              setExpandedID(record.id)
              if (company) {
                fetchDataExpanded(record.id)
              } else {
                fetchDataExpanded({ parent: record.id })
              }
            } else {
              setExpandedID(null)
            }
          }}
          expandRowByClick
          components={components}
          rowClassName={rowClassName}
          onRow={(record, rowIndex: any) => {
            return {
              onFocus: (event) => {
                if (setRowindex) {
                  setRowindex(rowIndex)
                }
              },
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  setEventTable({ record, e })
                }
              },
            }
          }}
          // rowKey={rowKey}
          rowKey={(record: { id: number }) => (willExpand ? record.id : rowKey)}
          loading={loading}
          dataSource={data}
          columns={columns as any}
          pagination={false}
          scroll={{ x: 1 }}
          // style={{ marginTop: '30px' }}
          onChange={(
            // types are any because of custom handling

            // not used since customly implemented
            pagination: any,
            // not used since customly implemented
            filters: any,
            // { column: [currentColumn], columnKey: [key], field: [dataIndex], order: 'descend' | 'ascend'}
            sorter: any,
            // { action: [nameOfAction], currentDataSource: data }
            extra: any,
          ) =>
            handleTableChange(extra.action, { ...pagination, filters, sorter })
          }
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 20,
          }}
          className="table-pagination"
        >
          {tableParams.page > 0 && tableParams.page_size > 0 && (
            <Pagination
              showLessItems={true}
              current={tableParams.page}
              total={rowsCount}
              pageSize={tableParams.page_size}
              onChange={(page, page_size) =>
                handleTableChange('customPagination', { page, page_size })
              }
              itemRender={itemRender}
              style={{
                width: '100%',
                display: 'flex',
                padding: '0 24px',
                justifyContent: 'center',
              }}
            />
          )}
        </div>
      </div>
    )
  )
}

export default AntTable
