import { message } from 'antd'
import axios, { AxiosInstance } from 'axios'
import { LS_ACCESS } from 'src/app/constants'
import { store } from 'src/app/store'
import { setAccessAndUser, signOut } from 'src/features/authSlice'
import { authHeader } from 'src/utils/auth'
import services from '.'

export default class BaseApi {
  api: AxiosInstance
  authApi: AxiosInstance

  constructor() {
    const url = `https://manage.penguin-international.com/`

    this.api = axios.create({
      baseURL: url,
      headers: {
        ...authHeader(),
      },
    })

    // configure requests
    this.api.interceptors.request.use(function (config) {
      config.headers = {
        ...config.headers,
        ...authHeader(),
      }
      return config
    })

    // handle responses
    this.api.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response
      },
      async function (error) {
        // Do something with response error

        if (!error.response) {
          return Promise.reject(error)
        }
        const { dispatch } = store
        if (error.response.status === 401) {
          //Invalid token, or expired state
          try {
            const lsAccess = localStorage.getItem(LS_ACCESS)
            if (!lsAccess) return dispatch(signOut())

            const { data } = await services.auth.refreshToken(
              JSON.parse(lsAccess).refreshToken,
            )

            // extract access and user data
            const access = { ...data.data }
            delete access.user
            const user = { ...data.data.user }

            // set extracted data
            localStorage.setItem(LS_ACCESS, JSON.stringify(access))
            dispatch(setAccessAndUser({ access, user }))
            document.location.replace('/')
          } catch (error) {
            dispatch(signOut())
          }
        }
        // if (error.response.status === 403) {
        //   message.error('You have a limited account')
        // }

        // if (error.response.status === 404) {
        //   // Not found
        //   dispatch(setError("404"));
        // }

        if (error.response.status === 500) {
          // Internal server error
          message.error('Server error 500')
          return
        }

        // if (error.response.status === 400) {
        //   const errorBody = error.response.data

        //   parseErrorBody(errorBody)
        // }

        return Promise.reject(error)
      },
    )

    const authUrl =
      process.env.REACT_APP_URL === 'production'
        ? `${window.location.protocol}//${window.location.host}/auth/api`
        : `${process.env.REACT_APP_HOST}/auth/api`

    this.authApi = axios.create({
      baseURL: authUrl,
      headers: {
        ...authHeader(),
      },
    })
    this.authApi.interceptors.request.use(function (config) {
      config.headers = { ...config.headers, ...authHeader() }
      return config
    })
  }
}
