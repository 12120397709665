import axios, { AxiosInstance } from 'axios'

interface ISignInModel {
  username: string
  password: string
}

class SignIn {
  api: AxiosInstance

  constructor() {
    const url =
      process.env.REACT_APP_URL === 'production'
        ? 'https://manage.penguin-international.com/'
        : 'https://manage.penguin-international.com/'

    this.api = axios.create({
      baseURL: url,
    })
  }

  async signIn(data: ISignInModel) {
    return await this.api.post('/api/v1/account/login/', data)
  }
}

export default new SignIn()
