import { Button, Drawer, Form, Input, Select } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'src/app/hooks'
import user from 'src/resources/images/user.svg'
import { IPageRequest } from 'src/app/models/page'
import services from 'src/services'

interface IProps {
  open: boolean
  onClose: () => void
  title: string
  onSubmit: (values: any) => void
  setRowIcon: any
  resetForm?: boolean
}

const { Option } = Select

const AddClients: React.FC<IProps> = ({
  open,
  onClose,
  title,
  onSubmit,
  setRowIcon,
  resetForm,
}) => {
  const [form] = Form.useForm()

  const [rowIconasd, setRowIconasd] = useState('')
  const file: any = useRef(null)

  const loadIcon = (e: any) => {
    const file = e.target.files[0]
    setRowIconasd(URL.createObjectURL(file))
    setRowIcon(file)
  }

  const [types, settypes] = useState<any>()

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  useEffect(() => {
    if (types) {
      form.setFieldValue('charge_rates', types)
    }
  }, [form, types])

  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  useEffect(() => {
    if (windowWidth && windowWidth > 992) {
      if (open) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }, [open, windowWidth])

  useEffect(() => {
    if (resetForm) {
      form.resetFields()
    }
  }, [resetForm, form])

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      getContainer={false}
      visible={open}
      className="drawer__custom"
    >
      <div className="general__left drawer-img">
        <div>
          <div className="general__left-text">Set company's img</div>
          <div className="general__left-img">
            <img src={rowIconasd ? rowIconasd : user} alt="" />
            <input
              type="file"
              id="file"
              onChange={(e) => {
                loadIcon(e)
              }}
              ref={file}
              style={{ display: 'none' }}
            />
          </div>
        </div>
        <Button
          className="general__left-btn"
          onClick={() => {
            file.current.click()
          }}
          style={{ marginLeft: '17px' }}
        >
          Update
        </Button>
      </div>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name="taxable"
          label="Taxable"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          >
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="company"
          label="Company"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          name="domain"
          label="Domain"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <div
          style={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#344054',
          }}
        >
          Charge Rates
        </div>
        <Form.List name="charge_rates">
          {(fields) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Form.Item
                  {...restField}
                  name={[name, 'price']}
                  label={
                    types &&
                    types?.map((n: any, index: any) =>
                      index === key ? n.name : '',
                    )
                  }
                >
                  <Input />
                </Form.Item>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: ' 25px',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{
              width: '108px',
              height: '40px',
              flex: '0 1 50%',
              borderRadius: '8px',
              background: '#28C76F',
              border: '1px solid #28C76F',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            // loading={isLoading}
            className="login__btn"
          >
            <i
              className="icon-external-link"
              style={{ marginRight: '8px' }}
            ></i>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default AddClients
