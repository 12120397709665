import { Layout } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Menu } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import TheFooter from 'src/components/global/TheFooter'
import TheHeader from 'src/components/global/TheHeader'
import TheSidebar from 'src/components/global/TheSidebar'
import { updateUser } from 'src/features/userSlice'
import services from 'src/services'

export interface MainLayoutProps {
  title: string
  children: React.ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ title, children }) => {
  const [activated, setActivated] = useState(false)
  const windowWidth = useAppSelector((state) => state.windowWidth.data)
  const history = useHistory()
  const dispatch = useAppDispatch()

  // const user = useAppSelector((state) => state.user.data)
  // console.log(user && user.groups[0].name)

  const fetchProfile = useCallback(async () => {
    try {
      const res = await services.user.getProfile()
      const { data } = res

      dispatch(updateUser(data))
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  return (
    <Layout
      style={{
        minHeight: '100vh',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <div
        className="site-layout-inner"
        style={{ display: 'flex', minHeight: '100vh' }}
      >
        <TheSidebar activated={activated} setActivated={setActivated} />
        <Layout className="site-layout">
          {windowWidth &&
          windowWidth < 650 &&
          history.location.pathname === '/dashboard' ? (
            <TheHeader />
          ) : (
            ''
          )}
          {windowWidth && windowWidth > 650 ? <TheHeader /> : ''}
          {windowWidth && windowWidth <= 992 ? (
            <Menu
              color="#9092A7"
              onClick={() => {
                setActivated(true)
              }}
              className={`menu-mobile ${
                windowWidth &&
                windowWidth <= 992 &&
                windowWidth > 650 &&
                history.location.pathname === '/dashboard'
                  ? 'dashboard'
                  : ''
              }`}
            />
          ) : (
            ''
          )}
          <Layout.Content style={{ margin: '24px' }}>
            {/* Switch routes here */}
            {children}
          </Layout.Content>
          <TheFooter />
        </Layout>
      </div>
    </Layout>
  )
}

export { MainLayout }
