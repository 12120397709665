import { Button, Drawer, Dropdown, Input, message } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { UserMinus } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { serverDateFormat } from 'src/app/constants'
import { useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'
import services from 'src/services'
import ProfileEdit from '../common/ProfileEdit'
import RequestDetail from '../common/RequestDetail'
import PipelineDetail from '../common/PipelineDetail'

const TheHeader: React.FC = () => {
  const history = useHistory()
  const [searchValue, setSearchValue] = useState<any>(null)
  const [data, setData] = useState<any>()
  // const [all, setall] = useState(false)
  const [rowIcon, setRowIcon] = useState('')
  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  const fetchData = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.mainSearch.all(params)
      const { data } = res

      setData(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const [profile, setProfile] = useState<any>()

  const fetchProfile = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getProfile()
      const { data } = res

      setProfile(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  const [notifications, setnotifications] = useState<any>()

  const fetchNotifications = useCallback(async () => {
    try {
      const res = await services.notifications.all({ page_size: 1000 })
      const { data } = res

      setnotifications(data)
      // setall(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const notificationsSetReadAll = useCallback(
    async (params?: any) => {
      try {
        const res = await services.notifications.setReadAll(params)
        if (res.status === 200) {
          fetchNotifications()
        }
      } catch (e) {
        console.log(e)
      }
    },
    [fetchNotifications],
  )

  const notificationsReduce = useCallback(
    async (id: any) => {
      try {
        const res = await services.notifications.reduce(id)

        if (res.status === 200) {
          fetchNotifications()
          fetchLeave()
        }
      } catch (e) {
        console.log(e)
      }
    },
    [fetchNotifications],
  )

  useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  // const [dataNew, setdataNew] = useState<any>()

  // useEffect(() => {
  //   // if (!all && notifications) {
  //   //   setdataNew(notifications.results.slice(0, 6))
  //   // }
  //   if (all && notifications) {
  //     setdataNew(notifications.results)
  //   }
  // }, [all, notifications])

  useEffect(() => {
    if (searchValue !== null) {
      const handler = setTimeout(() => {
        fetchData({ search: searchValue })
      }, 300)

      return () => {
        clearTimeout(handler)
      }
    }
  }, [searchValue, fetchData])

  const [open, setOpen] = useState(false)
  const [openPipe, setOpenPipe] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const [openProject, setOpenProject] = useState(false)
  const [datePicker, setdatePicker] = useState<any>()

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const showDrawerPipe = () => {
    setOpenPipe(true)
  }

  const onClosePipe = () => {
    setOpenPipe(false)
    setNewClient(null)
  }

  const [rowData, setRowData] = useState<any>(null)
  const [rowDataProject, setRowDataProject] = useState<any>(null)

  const fetchDataById = async (id: string) => {
    try {
      const res = await services.requests.getDetail(id)
      const { data } = res
      fetchAllData()
      setRowDataProject(data)
    } catch (e) {
      console.log(e)
    }
  }

  const showDrawerInfo = () => {
    setOpenInfo(true)
  }

  const onCloseInfo = () => {
    setOpenInfo(false)
    setRowData(null)
  }

  const showDrawerProject = () => {
    setOpenProject(true)
  }

  const onCloseProject = () => {
    setOpenProject(false)
    setRowDataProject(null)
    setNewClient(null)
    setselctedtls(null)
  }

  const updateRequest = useCallback(
    async (id: string, params?: any) => {
      try {
        const res = await services.user.updateUser(id, params)

        if (res.status === 200) {
          onClose()
          fetchData()
          fetchProfile()
          window.location.reload()
        }
      } catch (e) {
        console.log(e)
      }
    },
    [fetchData, fetchProfile],
  )

  const updateRequestProject = async (id: string, params?: any) => {
    try {
      const res = await services.requests.updateRequests(id, params)

      if (res.status === 200) {
        onClose()
        setdatePicker(undefined)
        settimePicker(undefined)
        setcomplationPicker(undefined)
        setSelectedClient(null)
        setclientCompany(null)
        setselectedStatus(null)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.department) {
      submitData.append(
        'department',
        values.department?.value ? values.department?.value : values.department,
      )
    }
    if (values.groups) {
      submitData.append(
        'groups',
        values.groups?.value
          ? values.groups?.value
          : values.groups?.map((n: any) => (n.value ? n.value : n)),
      )
    }
    if (values.working_mode) {
      submitData.append(
        'working_mode',
        values.working_mode?.value
          ? values.working_mode?.value
          : values.working_mode,
      )
    }
    if (values.designation) {
      submitData.append(
        'designation',
        values.designation?.value
          ? values.designation?.value
          : values.designation,
      )
    }
    if (values.phone) {
      submitData.append('phone', values.phone)
    }
    if (values.hobbies) {
      submitData.append('hobbies', values.hobbies)
    }
    if (values.bio) {
      submitData.append('bio', values.bio)
    }
    if (values.last_name) {
      submitData.append('last_name', values.last_name)
    }
    if (values.first_name) {
      submitData.append('first_name', values.first_name)
    }
    if (values.email) {
      submitData.append('email', values.email)
    }
    if (values.reporting_manger) {
      submitData.append(
        'reporting_manger',
        values.reporting_manger.value
          ? values.reporting_manger.value
          : values.reporting_manger,
      )
    } else {
      submitData.append('reporting_manger', '')
    }
    submitData.append('is_active', values.is_active)
    if (values.birth_date && datePicker !== undefined)
      submitData.append('birth_date', dayjs(datePicker).format('YYYY-MM-DD'))
    if (rowIcon) {
      submitData.append('avatar', rowIcon)
    }

    updateRequest(profile?.id, submitData)
  }

  const handleSubmitPipeline = (values: any) => {
    const submitData = new FormData()

    if (values.assigned) {
      submitData.append(
        'assigned',
        values.assigned?.map((n: any) => (n.value ? n.value : n)),
      )
    }

    if (values.company) {
      submitData.append('company', values.company)
    }
    if (values.subject) {
      submitData.append('subject', values.subject)
    }
    if (values.casecode) {
      submitData.append('casecode', values.casecode)
    }

    submitData.append(
      'datetime',
      values.datetime &&
        dayjs(datePicker).format('YYYY-MM-DD') +
          'T' +
          dayjs(timePicker).format('HH:mm:ss'),
    )
    submitData.append('id', rowData && rowData.id)
    submitData.append(
      'complation_date',
      values.complation_date &&
        dayjs(complationPicker).format(serverDateFormat),
    )
    if (values.pm) {
      submitData.append('pm', values.pm.value ? values.pm.value : values.pm)
    }
    submitData.append(
      'senior_pm',
      values.senior_pm.value ? values.senior_pm.value : values.senior_pm,
    )
    if (rowData.client) {
      submitData.append('client', rowData && rowData.client?.id)
    }
    if (values.parent) {
      submitData.append(
        'parent',
        values.parent?.value === 'null'
          ? null
          : values.parent?.value !== 'null'
          ? values.parent?.value
          : values.parent,
      )
    }
    if (values.status !== undefined) {
      submitData.append(
        'status',
        values.status?.value ? values.status.value : values.status,
      )
    }
    if (values.name !== null) {
      submitData.append('name', values.name)
    }
    submitData.append('scooped_hours', values.scooped_hours)
    submitData.append('estimated_hours', values.estimated_hours)
    submitData.append('charge_rate', values.charge_rate)
    if (values.type_of_work !== undefined) {
      submitData.append(
        'type_of_work',
        values.type_of_work?.value
          ? values.type_of_work.value
          : values.type_of_work,
      )
    }
    if (values.sector !== null) {
      submitData.append(
        'sector',
        values.sector.value ? values.sector.value : values.sector,
      )
    }

    createPipeline(submitData)
  }

  const [rowDataPipe, setRowDataPipe] = useState<any>(null)

  const fetchDataByIdPipe = async (id: string) => {
    try {
      const res = await services.pipeline.allDetail(id)
      const { data } = res

      setRowDataPipe(data)
    } catch (e) {
      console.log(e)
    }
  }

  const createPipeline = useCallback(
    async (params?: any) => {
      try {
        const res = await services.requests.postRequests(params)

        if (res.status === 201) {
          onClose()
          fetchData()
          setselectedChargeRate(null)
          setselectedType(null)
          setSelectedClient(null)
          setclientCompany(null)
          setselectedStatus(null)
        }
      } catch (e) {
        console.log(e)
      }
    },
    [fetchData],
  )

  useEffect(() => {
    if (openInfo || openProject) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [openProject, openInfo])

  const [groups, setgroups] = useState<any>()

  const fetchGroups = async () => {
    try {
      const res = await services.group.all()
      const { results } = res.data

      setgroups(results)
    } catch (e) {
      console.log(e)
    }
  }

  const [leaved, setLeaved] = useState<any>(null)
  const [leavedRow, setLeavedRow] = useState<any>(null)
  const [openLeave, setOpenLeave] = useState(false)

  const showDrawerLeave = () => {
    setOpenLeave(true)
  }

  const onCloseLeave = () => {
    setOpenLeave(false)
  }

  useEffect(() => {
    if (windowWidth && windowWidth > 992) {
      if (openLeave) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }, [openLeave, windowWidth])

  const fetchLeave = async () => {
    try {
      const res = await services.notifications.allLeaved()
      setLeaved(res.data)
      // if (res.data === 200) {
      //   fetchNotifications()
      // }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchLeaveById = async (id: any) => {
    try {
      const res = await services.notifications.getLeavedById(id)
      setLeavedRow(res.data)
    } catch (e) {
      console.log(e)
    }
  }

  const updateLeaveById = async (id: any, data: any) => {
    try {
      const res = await services.notifications.updateLeavedById(id, data)
      if (res.status === 200) {
        setOpenLeave(false)
        setLeavedRow(null)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchLeave()
  }, [])

  useEffect(() => {
    if (rowData) {
      fetchGroups()
    }
  }, [rowData])

  const handleSubmitProject = (values: any) => {
    let submitData = { ...values }

    if (
      (submitData.assigned &&
        selectedStatus &&
        selectedStatus === 'Delivered') ||
      (submitData.assigned && selectedStatus && selectedStatus === 'Cancelled')
    ) {
      let some: any = []
      let newArr: any
      values.assigned?.map((n: any) => {
        newArr = {
          id: n.id.value ? n.id.value : n.id,
          billable_hours: n.billable_hours,
          non_billable_hours: n.non_billable_hours,
        }
        some.push(newArr)
        return newArr
      })
      submitData.assigned = some
    }

    if (
      (submitData.assigned &&
        selectedStatus &&
        selectedStatus !== 'Delivered') ||
      (submitData.assigned && selectedStatus && selectedStatus !== 'Cancelled')
    ) {
      let some: any = []
      let newArr: any
      values.assigned?.map((n: any) => {
        newArr = { id: n.value ? n.value : n }
        some.push(newArr)
        return newArr
      })
      submitData.assigned = some
    }

    if (submitData.pm) {
      submitData.pm =
        selectedStatus === 'Delivered' || selectedStatus === 'Cancelled'
          ? {
              id: values.pm.value ? values.pm.value : values.pm,
              billable_hours: values.pm_billable_hours
                ? values.pm_billable_hours
                : '',
              non_billable_hours: values.pm_non_billable_hours
                ? values.pm_non_billable_hours
                : '',
            }
          : {
              id: values.pm.value ? values.pm.value : values.pm,
            }
    }
    if (submitData.senior_pm) {
      submitData.senior_pm =
        selectedStatus === 'Delivered' || selectedStatus === 'Cancelled'
          ? {
              id: values.senior_pm.value
                ? values.senior_pm.value
                : values.senior_pm,
              billable_hours: values.spm_billable_hours
                ? values.spm_billable_hours
                : '',
              non_billable_hours: values.spm_non_billable_hours
                ? values.spm_non_billable_hours
                : '',
            }
          : {
              id: values.senior_pm.value
                ? values.senior_pm.value
                : values.senior_pm,
            }
    }

    if (submitData.company) {
      submitData.company = values.company
    }
    if (values.client) {
      submitData.client = values.client.value
        ? values.client.value
        : values.client
    }
    if (values.subject) {
      submitData.subject = values.subject
    }
    if (values.casecode) {
      submitData.casecode = values.casecode
    }

    submitData.datetime =
      values.datetime &&
      dayjs(datePicker).format('YYYY-MM-DD') +
        'T' +
        dayjs(timePicker).format('HH:mm:ss')

    submitData.id = rowData && rowData.id
    submitData.complation_date =
      values.complation_date && dayjs(complationPicker).format(serverDateFormat)

    if (values.parent) {
      submitData.parent = values.parent?.value
        ? values.parent?.value
        : values.parent
    }
    if (values.status !== undefined) {
      submitData.status = values.status?.value
        ? values.status.value
        : values.status
    }
    submitData.estimated_hours = values.estimated_hours
    if (submitData.billable_hours) {
      submitData.billable_hours = values.billable_hours
    }
    if (submitData.non_billable_hours) {
      submitData.non_billable_hours = values.non_billable_hours
    }
    submitData.charge_rate = values.charge_rate
    if (values.type_of_work !== undefined) {
      submitData.type_of_work = values.type_of_work?.value
        ? values.type_of_work.value
        : values.type_of_work
    }
    if (values.sector !== null) {
      submitData.sector = values.sector.value
        ? values.sector.value
        : values.sector
    }
    delete submitData.name
    delete submitData.id

    updateRequestProject(rowDataProject?.id, submitData)
  }

  const [timePicker, settimePicker] = useState<any>()
  const [complationPicker, setcomplationPicker] = useState<any>()
  const [pmsData, sepPmsData] = useState<any>()
  const [statusData, sepStatusData] = useState<any>()
  const [secotrData, sepSecotrData] = useState<any>()
  const [assignedData, setassignedData] = useState<any>()
  const [tls, settls] = useState<any>(null)
  const [pms, setpms] = useState<any>(null)
  const [selctedtls, setselctedtls] = useState<any>(null)

  const fetchTL = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getTL()
      const { results } = res.data

      settls(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTL()
  }, [fetchTL])

  const fetchPM = useCallback(async (data?: any) => {
    try {
      const res = await services.user.getPM({ id: data })
      const { results } = res.data

      setpms(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (selctedtls) {
      fetchPM(selctedtls)
    }
  }, [fetchPM, selctedtls])

  const fetchAssigned = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAssigned()
  }, [fetchAssigned])

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.all()
      const { results } = res.data

      sepPmsData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  const fetchStatuses = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.status.all()
      const { results } = res.data

      sepStatusData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchStatuses()
  }, [fetchStatuses])

  const fetchSector = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.sector.all()
      const { results } = res.data

      sepSecotrData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchSector()
  }, [fetchSector])

  const [types, settypes] = useState<any>()

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  const [clients, setClients] = useState<any>(null)

  const fetchClients = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.clients.all({ page_size: 1000 })
      const { results } = res.data

      setClients(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  const [allRequests, setAllRequests] = useState<any>()

  const fetchAllData = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.requests.getList(params)
      const { results } = res.data

      setAllRequests(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [selectedStatus, setselectedStatus] = useState<any>(null)
  const [selectedAssigned, setSelectedAssigned] = useState<any>(null)
  const [clientCompany, setclientCompany] = useState<any>(null)
  const [selectedChargeRate, setselectedChargeRate] = useState<any>(null)
  const [selectedType, setselectedType] = useState<any>(null)

  const [newClient, setNewClient] = useState<any>(null)

  const fetchClientsAdd = useCallback(
    async (data: any) => {
      try {
        const res = await services.clients.add(data)

        if (res.status === 200) {
          setNewClient(res.data)
          fetchClients()
        }
      } catch (e: any) {
        message.error(e.response.data.msg)
      }
    },
    [fetchClients],
  )

  return (
    <Header
      className="d-flex align-items-center justify-content-between header"
      style={searchValue ? { position: 'relative' } : { position: 'initial' }}
    >
      <Input
        allowClear
        className="main-search"
        prefix={
          <i
            className="icon-search"
            style={{ fontSize: '20px', color: '#9092A7', marginRight: '14px' }}
          ></i>
        }
        bordered={false}
        placeholder="Search by Project Name, Project Subject Line, Client Name, Case Code"
        onChange={(e) => {
          setSearchValue(e.target.value)
        }}
      />
      {windowWidth && windowWidth > 650 ? (
        <div className="header__right">
          <div
            className="header__settings"
            onClick={() => history.push('/settings')}
          >
            <i className="icon-settings"></i>
          </div>
          <Dropdown
            overlay={
              <div
                className="header__notification"
                style={{ overflow: 'hidden' }}
              >
                <div
                  style={
                    // notifications ?
                    {
                      maxHeight: '500px',
                      height: '1005',
                      overflowY: 'auto',
                      // overflow: 'hidden',
                    }
                    // : { overflow: 'hidden' }
                  }
                >
                  <div className="header__notification-top">
                    <div className="header__notification-title">
                      Notification
                    </div>
                    <div className="header__notification-count">{`${
                      notifications && notifications.count
                    } New`}</div>
                  </div>
                  {notifications &&
                    notifications.results?.map((n: any) => (
                      <div
                        className="header__notification-item"
                        key={n.id}
                        onClick={() => {
                          notificationsReduce(n.id)
                          if (n.pipeline !== null) {
                            // dispatch(updateUserPipeline(n.pipeline))
                            // history.push('/pipeline')
                            fetchDataByIdPipe(n.pipeline)
                            showDrawerPipe()
                          }
                          if (n.request !== null) {
                            // dispatch(updateUserRequest(n.request))
                            // history.push('/requests')
                            fetchDataById(n.request)
                            showDrawerProject()
                          }
                        }}
                      >
                        <div className="header__notification-item-img">
                          <img
                            src={notifications && notifications.icon}
                            alt=""
                          />
                        </div>
                        <div className="header__notification-item-info">
                          <span>{n.title}</span>
                          <span>
                            {dayjs(n.created_at).format('MMM D, YYYY h:mm A')}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  className="header__notification-btn"
                  onClick={() => {
                    notificationsSetReadAll({ leave_or_notif: 'notif' })
                    // all ? setall(false) : setall(false)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Read all
                </div>
              </div>
            }
            placement="bottom"
            trigger={['click']}
            overlayClassName="header__notification-dropdown"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i
                className="icon-bell"
                style={{
                  fontSize: '20px',
                  color: '#9092A7',
                  cursor: 'pointer',
                }}
              ></i>
              {notifications && notifications.count ? (
                <span
                  style={{
                    fontSize: notifications.count < 10 ? '12px' : '7px',
                  }}
                >
                  {notifications.count}
                </span>
              ) : (
                ''
              )}
            </div>
          </Dropdown>
          <Dropdown
            overlay={
              <div
                className="header__notification"
                style={{ overflow: 'hidden' }}
              >
                <div
                  style={
                    {
                      maxHeight: '500px',
                      height: '1005',
                      overflowY: 'auto',
                      // overflow: 'hidden',
                    }
                    // all
                    //   ?
                    //   : { overflow: 'hidden' }
                  }
                >
                  <div className="header__notification-top">
                    <div className="header__notification-title">
                      Leaves Requests
                    </div>
                    <div className="header__notification-count">{`${
                      leaved && leaved.count
                    } New`}</div>
                  </div>
                  {leaved &&
                    leaved.results?.map((n: any) => (
                      <div
                        className="header__notification-item"
                        key={n.id}
                        onClick={() => {
                          notificationsReduce(n.id)
                          fetchLeaveById(n.leave_user)
                          showDrawerLeave()
                        }}
                      >
                        <div className="header__notification-item-img">
                          <img src={leaved && leaved.icon} alt="" />
                        </div>
                        <div className="header__notification-item-info">
                          <span>{n.title}</span>
                          <span>
                            {dayjs(n.created_at).format('MMM D, YYYY h:mm A')}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  className="header__notification-btn"
                  onClick={() => {
                    notificationsSetReadAll({ leave_or_notif: 'leave' })
                    // all ? setall(false) : setall(true)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Read all
                </div>
              </div>
            }
            placement="bottom"
            trigger={['click']}
            overlayClassName="header__notification-dropdown"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '20px',
              }}
            >
              <UserMinus
                style={{
                  width: '20px',
                  height: '20px',
                  color: '#9092A7',
                  cursor: 'pointer',
                }}
              />
              {leaved && leaved.count ? (
                <span
                  style={{
                    fontSize: leaved.count < 10 ? '12px' : '7px',
                  }}
                >
                  {leaved.count}
                </span>
              ) : (
                ''
              )}
            </div>
          </Dropdown>
          <div
            className="header__profile"
            style={{ cursor: 'pointer' }}
            onClick={showDrawer}
          >
            <div className="header__profile-info">
              <div className="header__profile-info-name">
                {profile && profile.first_name + ' ' + profile.last_name}
              </div>
              <div className="header__profile-info-role">
                {profile && profile.groups.map((n: any) => n.name)}
              </div>
            </div>
            <div className="header__profile-img">
              <img src={profile && profile.avatar} alt="" />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <ProfileEdit
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit}
        data={profile}
        datePicker={datePicker}
        setdatePicker={setdatePicker}
        setRowIcon={setRowIcon}
      />
      {rowData && (
        <ProfileEdit
          open={openInfo}
          onClose={onCloseInfo}
          onSubmit={handleSubmit}
          data={rowData}
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          setRowIcon={setRowIcon}
        />
      )}
      {rowDataProject && (
        <RequestDetail
          onSubmit={handleSubmitProject}
          open={openProject}
          onClose={onCloseProject}
          title="More Info"
          rowData={rowDataProject}
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          selectedStatus={selectedStatus}
          setselectedStatus={setselectedStatus}
          selectedAssigned={selectedAssigned}
          setSelectedAssigned={setSelectedAssigned}
          clientCompany={clientCompany}
          setclientCompany={setclientCompany}
          selectedChargeRate={selectedChargeRate}
          setselectedChargeRate={setselectedChargeRate}
          selectedType={selectedType}
          setselectedType={setselectedType}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
      )}
      {rowDataPipe && (
        <PipelineDetail
          onSubmit={handleSubmitPipeline}
          rowData={rowDataPipe}
          open={openPipe}
          onClose={onClosePipe}
          title="Pick Request"
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          selectedStatus={selectedStatus}
          setselectedStatus={setselectedStatus}
          selectedAssigned={selectedAssigned}
          setSelectedAssigned={setSelectedAssigned}
          clientCompany={clientCompany}
          setclientCompany={setclientCompany}
          selectedChargeRate={selectedChargeRate}
          setselectedChargeRate={setselectedChargeRate}
          selectedType={selectedType}
          setselectedType={setselectedType}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
      )}
      {(searchValue && data && data.projects?.length !== 0) ||
      (searchValue && data && data.members?.length !== 0) ? (
        <div className="header__data">
          <div className="header__data-projects">
            {data && data.projects?.length !== 0 ? (
              <div className="header__data-title">PROJECTS</div>
            ) : (
              ''
            )}
            {data && data.projects?.length !== 0
              ? data.projects?.map((n: any) => (
                  <div
                    className="header__data-projects-item"
                    key={n.id}
                    onClick={() => {
                      showDrawerProject()
                      setRowDataProject(n)
                    }}
                  >
                    {n.name}
                  </div>
                ))
              : ''}
          </div>
          <div className="header__data-members">
            {data && data.members?.length !== 0 ? (
              <div className="header__data-title">Members</div>
            ) : (
              ''
            )}
            {data && data.members?.length !== 0
              ? data.members?.map((n: any) => (
                  <div
                    className="header__data-members-item"
                    key={n.id}
                    onClick={() => {
                      showDrawerInfo()
                      setRowData(n)
                    }}
                  >
                    <img src={n.avatar} alt="" />
                    <div className="header__data-members-item-info">
                      <span>{n.first_name + ' ' + n.last_name}</span>
                      <span>{n.department?.name}</span>
                    </div>
                  </div>
                ))
              : ''}
          </div>
        </div>
      ) : (
        ''
      )}
      {leavedRow && (
        <Drawer
          visible={openLeave}
          onClose={onCloseLeave}
          zIndex={10000}
          title="Apply for leave"
        >
          <div className="leave">
            <div className="general__left drawer-img">
              <div>
                <div className="general__left-text">User's image</div>
                <div className="general__left-img">
                  <img src={leavedRow.avatar} alt="" />
                </div>
              </div>
            </div>
            <table>
              <tr>
                <td>Name</td>
                <td>{leavedRow.first_name}</td>
              </tr>
              <tr>
                <td>Surname</td>
                <td>{leavedRow.last_name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{leavedRow.phone}</td>
              </tr>
              <tr>
                <td>Start of vacation</td>
                <td>
                  {leavedRow.vacation_end
                    ? dayjs(leavedRow.vacation_end).format('D MMM YYYY')
                    : ''}
                </td>
              </tr>
              <tr>
                <td>End of vacation</td>
                <td>
                  {leavedRow.vacation_start
                    ? dayjs(leavedRow.vacation_start).format('D MMM YYYY')
                    : ''}
                </td>
              </tr>
              <tr>
                <td>Reason</td>
                <td>{leavedRow.reason}</td>
              </tr>
            </table>
            {leavedRow.on_vacation === 'empty' ? (
              <div className="leave__btns">
                <Button
                  onClick={() => {
                    updateLeaveById(leavedRow.id, { on_vacation: 'cancel' })
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    updateLeaveById(leavedRow.id, { on_vacation: 'confirm' })
                  }}
                >
                  Submit
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </Drawer>
      )}
    </Header>
  )
}

export default TheHeader
