import { createSlice } from '@reduxjs/toolkit'

export interface userPipelineState {
  data: any | null
}

const initialState: userPipelineState = {
  data: null,
}

export const userPipelineSlice = createSlice({
  name: 'userPipeline',
  initialState,
  reducers: {
    updateUserPipeline: (state, action: any) => {
      state.data = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateUserPipeline } = userPipelineSlice.actions

export default userPipelineSlice.reducer
