import BaseApi from '../base.service'

class Settings extends BaseApi {
  async get() {
    return await this.api.get(`api/v1/account/get-general/`)
  }
  async update(data?: any) {
    return await this.api.put(`api/v1/account/update-general/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
  async updateNotifications() {
    return await this.api.patch(`api/v1/account/general/1/`)
  }
}

export default Settings
