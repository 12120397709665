import React from 'react'

interface IProps {
  customColor: string
  custombackground: string
  title: string
  subtitle: string
  customIson: string
}

const VerticalCard: React.FC<IProps> = ({
  customColor,
  custombackground,
  title,
  subtitle,
  customIson,
}) => {
  return (
    <div className="analytics__vertical">
      <i
        className={customIson}
        style={{ color: customColor, background: custombackground }}
      ></i>
      <div className="analytics__vertical-title">{title}</div>
      <div className="analytics__vertical-subtitle">{subtitle}</div>
    </div>
  )
}

export default VerticalCard
