import { useCallback, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { LS_ACCESS } from 'src/app/constants'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import WithAuth from 'src/components/withAuth'
import {
  clearAccessAndUser,
  setAccessAndUser,
  signOut,
} from 'src/features/authSlice'
import Counter from 'src/pages/Demo/Counter'
import Register from 'src/pages/Register/Register'
import SignIn from 'src/pages/SignIn'
import routes from './private'

const Routes = () => {
  const access = useAppSelector((state) => state.auth.access)
  const hasAccess = localStorage.getItem(LS_ACCESS) || access
  const dispatch = useAppDispatch()
  const history = useHistory()

  const clear = useCallback(() => {
    dispatch(clearAccessAndUser())
  }, [dispatch])

  const [token, setToken] = useState<any>('')
  const [firstReg, setfirstReg] = useState<any>('')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tokenValue = urlParams.get('token')
    const firstRegister = urlParams.get('is_register')
    setToken(tokenValue)
    setfirstReg(firstRegister)
  }, [])

  useEffect(() => {
    if (window.location.pathname === '/token/' && token !== '' && firstReg) {
      localStorage.setItem(
        LS_ACCESS,
        JSON.stringify({
          token: token,
          is_register: firstReg === 'True' ? true : false,
        }),
      )
      dispatch(setAccessAndUser({ token: token } as any))
      if (firstReg === 'True') {
        history.replace('/register')
      }
      if (firstReg === 'False') {
        history.replace('/dashboard')
      }
    }
  }, [history.location, token, dispatch, history, access, firstReg])

  useEffect(() => {
    if (window.location.pathname !== '/token/') {
      const lsAccess = localStorage.getItem(LS_ACCESS)

      // if no access data in LS then redirect to /signin
      if (!lsAccess) {
        clear()
        return history.replace('/signin')
      }
    }
  }, [history, clear])

  const handleFinalRedirect = () => {
    if (hasAccess) {
      const route = routes
      if (route.length === 0) {
        dispatch(signOut())
        return '/signin'
      }

      if (JSON.parse(hasAccess as string).is_register === true) {
        return '/register'
      }
      if (JSON.parse(hasAccess as string).is_register === false) {
        return '/dashboard'
      }
    }

    return '/signin'
  }

  return (
    <Switch>
      {hasAccess &&
        routes.map(({ exact, path, component, layout, title }) => {
          return (
            <Route
              exact={exact}
              path={path}
              key={path}
              render={(props) => (
                <WithAuth
                  component={component}
                  title={title}
                  layout={layout}
                  {...props}
                />
              )}
            />
          )
        })}
      <Route exact path="/signin">
        <SignIn />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/demo">
        <Counter />
      </Route>
      {window.location.pathname !== '/token/' ? (
        <Redirect to={handleFinalRedirect()} />
      ) : (
        ''
      )}
    </Switch>
  )
}

export default Routes
