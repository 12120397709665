import { Button, Drawer, Form, Input, Select } from 'antd'
import React, { useEffect } from 'react'
import DatePicker from './DatePicker'

interface IProps {
  open: boolean
  onClose: () => void
  title: string
  onSubmit: (values: any) => void
  datePicker: any
  setdatePicker: any
  timePicker: any
  settimePicker: any
  complationPicker: any
  setcomplationPicker: any
  assigned: any
  pmsData: any
  allRequests: any
  statusData: any
  secotrData: any
  types: any
  clients: any
  clientCompany: any
  setclientCompany: any
  selectedChargeRate: any
  setselectedChargeRate: any
  selectedClient: any
  setSelectedClient: any
  selectedType: any
  setselectedType: any
  resetForm: any
  setselctedtls: any
  tls: any
  pms: any
  fetchClientsAdd: any
  newClient: any
}

const { Option } = Select

const SeeMoreNew: React.FC<IProps> = ({
  open,
  onClose,
  title,
  onSubmit,
  datePicker,
  setdatePicker,
  timePicker,
  settimePicker,
  complationPicker,
  setcomplationPicker,
  assigned,
  pmsData,
  allRequests,
  statusData,
  secotrData,
  types,
  clients,
  clientCompany,
  setclientCompany,
  selectedChargeRate,
  setselectedChargeRate,
  selectedClient,
  setSelectedClient,
  selectedType,
  setselectedType,
  resetForm,
  setselctedtls,
  tls,
  pms,
  fetchClientsAdd,
  newClient,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (resetForm) {
      form.resetFields()
    }
  }, [resetForm, form])

  useEffect(() => {
    if (selectedType && selectedClient) {
      setselectedChargeRate(
        clients
          .find((n: any) => n.id === selectedClient)
          ?.charge_rates.find((m: any) => m.type_of_work === selectedType)
          .price,
      )
    }
  }, [selectedType, clients, setselectedChargeRate, selectedClient])

  useEffect(() => {
    if (selectedChargeRate !== null) {
      form.setFieldValue('charge_rate', selectedChargeRate)
    }
  }, [form, selectedChargeRate, selectedClient])

  useEffect(() => {
    if (clientCompany) {
      form.setFieldValue('company', clientCompany)
    }
  }, [clientCompany, form])

  useEffect(() => {
    if (selectedClient) {
      const findClient = clients.find((n: any) => n.id === selectedClient)
      setclientCompany(
        findClient?.company ?? findClient?.email.split('@').pop().split('.')[0],
      )
    }
  }, [selectedClient, clients, setclientCompany])

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  useEffect(() => {
    if (newClient !== null) {
      form.setFieldsValue({
        client: newClient.id,
      })
      setSelectedClient(newClient.id)
    }
  }, [newClient, form, setSelectedClient])

  useEffect(() => {
    if (newClient !== null) {
      form.setFieldsValue({
        company: newClient?.email.split('@').pop().split('.')[0],
      })
    }
  }, [newClient, form])

  return (
    <Drawer
      key="2"
      title={title}
      placement="right"
      onClose={onClose}
      getContainer={false}
      visible={open}
      className="drawer__custom"
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name="client"
          label="Client"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder="Client"
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(val) => {
              setSelectedClient(val.value)
            }}
            onInputKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchClientsAdd({ data: e.currentTarget.value })
              }
            }}
          >
            {clients &&
              clients.map((n: any) => (
                <Option value={n.id} label={n.name || n.surname} key={n.id}>
                  {n.name + ', ' + n.surname + ' <' + n.email + '>'}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="company" label="Company">
          <Input placeholder="" disabled />
        </Form.Item>
        <div className="drawer__custom-double">
          <Form.Item
            name="datetime"
            label="Date"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              suffixIcon={
                <i className="icon-calendar" style={{ color: '#9092a7' }}></i>
              }
              onChange={(el) => {
                setdatePicker(el)
              }}
              value={datePicker}
            />
          </Form.Item>
          <Form.Item
            name="datetime"
            label="Time"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              picker="time"
              suffixIcon={<i className="icon-clock"></i>}
              onChange={(el) => {
                settimePicker(el)
              }}
              value={timePicker}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="subject"
          label="Subject Line"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          name="sector"
          label="Sector"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          >
            {secotrData &&
              secotrData.map((n: any) => (
                <Option value={n.id} key={n.id} label={n.name}>
                  {n.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="type_of_work"
          label="Type of work"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(val) => {
              if (val) {
                setselectedType(val.value)
              }
            }}
          >
            {types &&
              types.map((n: any) => (
                <Option value={n.id} key={n.id} label={n.name}>
                  {n.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="parent" label="Parent project">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          >
            <Option value="null">No Parent</Option>
            {allRequests &&
              allRequests.map((n: any) => (
                <Option value={n.id} key={n.id} label={n.name}>
                  {n.id_code}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <div className="drawer__custom-double">
          <Form.Item
            name="estimated_hours"
            label="Est. Effort (hrs)"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <Input placeholder="" prefix={<i className="icon-clock"></i>} />
          </Form.Item>
        </div>
        <div className="drawer__custom-double">
          <Form.Item
            name="complation_date"
            label="Est. Delivery Date"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              suffixIcon={
                <i className="icon-calendar" style={{ color: '#9092a7' }}></i>
              }
              onChange={(el) => {
                setcomplationPicker(el)
              }}
              value={complationPicker}
            />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select
              labelInValue
              placeholder=""
              allowClear={true}
              style={{ marginBottom: '0' }}
              suffixIcon={
                <i
                  className="icon-chevron-down"
                  style={{ fontSize: '20px', color: '#667085' }}
                ></i>
              }
              showSearch
              optionFilterProp="children"
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {statusData &&
                statusData.map((n: any) => (
                  <Option value={n.id} key={n.id} label={n.name}>
                    {n.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item name="charge_rate" label="Charge rate">
          <Input placeholder="" type="number" />
        </Form.Item>
        <Form.Item
          name="senior_pm"
          label="Team Leader"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(val) => setselctedtls(val.value)}
          >
            {tls &&
              tls?.map((n: any) => (
                <Option
                  key={n.id}
                  value={n.id}
                  label={n.profile.first_name || n.profile.last_name}
                >
                  {n.profile.first_name + ' ' + n.profile.last_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="pm"
          label="Project Manager"
          rules={[
            {
              required: false,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          >
            {pms &&
              pms?.map((n: any) => (
                <Option
                  key={n.id}
                  value={n.id}
                  label={n.profile.first_name || n.profile.last_name}
                >
                  {n.profile.first_name + ' ' + n.profile.last_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="assigned"
          label="Assigned Team Members"
          rules={[
            {
              required: false,
              message: 'Fill the field',
            },
          ]}
          style={{ height: 'auto' }}
        >
          <Select
            mode="multiple"
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          >
            {pmsData &&
              pmsData.map((n: any) =>
                n.groups.length !== 0 &&
                n.groups?.find((m: any) => m.name !== 'Business Head') ? (
                  <Option
                    key={n.id}
                    value={n.id}
                    label={n.profile.first_name || n.profile.last_name}
                  >
                    {n.profile.first_name + ' ' + n.profile.last_name}
                  </Option>
                ) : (
                  ''
                ),
              )}
          </Select>
        </Form.Item>
        <Form.Item name="casecode" label="Case code">
          <Input
            placeholder=""
            suffix={
              <i
                className="icon-edit-2"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          />
        </Form.Item>
        <Form.Item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: ' 25px',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{
              width: '108px',
              height: '40px',
              flex: '0 1 50%',
              borderRadius: '8px',
              background: '#28C76F',
              border: '1px solid #28C76F',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            // loading={isLoading}
            className="login__btn"
          >
            <i
              className="icon-external-link"
              style={{ marginRight: '8px' }}
            ></i>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default SeeMoreNew
