import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'src/features/authSlice'
import counterReducer from 'src/features/counterSlice'
import providerReducer from 'src/features/providerSlice'
import tableParamsReducer from 'src/features/tableParamsSlice'
import windowWidthReducer from 'src/features/windowWidthSlice'
import userReducer from 'src/features/userSlice'
import userRequestReducer from 'src/features/userRequestSlice'
import userPipelineReducer from 'src/features/userPipelineSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    provider: providerReducer,
    tableParams: tableParamsReducer,
    windowWidth: windowWidthReducer,
    user: userReducer,
    userRequest: userRequestReducer,
    userPipeline: userPipelineReducer,

    // demo
    counter: counterReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
