import { Button, Modal } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { Filter } from 'react-feather'
import { serverDateFormat } from 'src/app/constants'
import { useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'
import AntTable from 'src/components/common/AntTable'
import FilterClosed from 'src/components/common/FilterClosed'
import SeeMoreClosed from 'src/components/common/SeeMoreClosed'
import services from 'src/services'

const Closed = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [dataCount, setDataCount] = useState({
    count: 0,
  })
  const [data, setData] = useState<any>()
  const [tableParams, setTableParams] = useState<IPageRequest>({
    page: 1,
    page_size: 10,
  })
  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [datePickerFilter, setdatePickerFilter] = useState<any>(null)
  const [rowId, setrowId] = useState(null)
  const windowWidth = useAppSelector((state) => state.windowWidth.data)
  const user = useAppSelector((state) => state.user.data)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    handleDelete()
    setrowId(null)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const columns = [
    {
      title: 'Request',
      dataIndex: 'name',
      render: (name: any) => (
        <div
          style={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#101828',
          }}
        >
          {name}
        </div>
      ),
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (client: any) => (
        <div className="table__user">
          <div className="table__user-img">
            {client && client.icon ? (
              <img src={client?.icon} alt="" />
            ) : client?.name === undefined || client?.surname === undefined ? (
              ''
            ) : (
              client?.name.charAt(0) + '' + client?.surname.charAt(0)
            )}
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>{`${
              client?.name ? client?.name : ''
            } ${client?.surname ? client?.surname : ''}`}</div>
            <div className="table__user-email">{client?.email}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Billiable hours',
      dataIndex: 'billable_hours',
      render: (billable_hours: any) => (
        <div
          style={{
            background: '#EFF8FF',
            borderRadius: '16px',
            padding: '2px 8px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#175CD3',
            width: 'fit-content',
          }}
        >
          {billable_hours} hours
        </div>
      ),
    },
    {
      title: 'CaseCode',
      dataIndex: 'casecode',
      render: (casecode: any) => (
        <div
          style={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#101828',
          }}
        >
          {casecode}
        </div>
      ),
    },
    {
      title: 'Charge rate',
      dataIndex: 'charge_rate',
      render: (charge_rate: any) => (
        <div
          style={{
            background: '#F9F5FF',
            borderRadius: '16px',
            padding: '2px 8px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#6941C6',
            width: 'fit-content',
          }}
        >
          ₹{charge_rate}
        </div>
      ),
    },
    {
      title: 'Parent Project',
      dataIndex: 'parent',
      render: (parent: any) => (
        <div
          style={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#101828',
          }}
        >
          {parent}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__more"
            onClick={() => {
              showModal()
              setrowId(rowData.id)
            }}
          >
            <i className="icon-trash-2"></i>
          </Button>
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              fetchDataById(rowData.id)
              showDrawer()
            }}
          >
            <i className="icon-pen"></i>
          </Button>
        </div>
      ),
    },
  ]

  const columnsMobile = [
    {
      title: 'Request',
      dataIndex: 'name',
      render: (name: any) => (
        <div
          style={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#101828',
          }}
        >
          {name}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__more"
            onClick={() => {
              showModal()
              setrowId(rowData.id)
            }}
          >
            <i className="icon-trash-2"></i>
          </Button>
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              fetchDataById(rowData.id)
              showDrawer()
            }}
          >
            <i className="icon-pen"></i>
          </Button>
        </div>
      ),
    },
  ]

  const fetchDataTeam = useCallback(
    async (params: IPageRequest) => {
      setIsLoading(true)
      try {
        const res =
          user && user.groups[0].name === 'Senior Project Manager'
            ? await services.invoices.allSPM(params)
            : await services.invoices.all(params)

        const { count, results } = res.data

        setData(results)
        setDataCount({
          count,
        })
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
      }
    },
    [user],
  )

  const patchById = async (id: string, data: any) => {
    try {
      const res = await services.invoices.patchById(id, data)
      if (res.status === 200) {
        onClose()
        fetchDataTeam(
          user && user.groups[0].name === 'Senior Project Manager'
            ? {
                ...tableParams,
              }
            : {
                ...tableParams,
                step: 'closed',
              },
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [rowData, setRowData] = useState<any>()

  const fetchDataById = async (id: string) => {
    setIsLoading(true)
    try {
      const res = await services.invoices.getById(id)
      const { data } = res
      setRowData(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    fetchDataTeam(
      user && user.groups[0].name === 'Senior Project Manager'
        ? {
            ...tableParams,
          }
        : {
            ...tableParams,
            step: 'closed',
          },
    )
  }, [fetchDataTeam, tableParams, user])

  const handleTableChange = (params: IPageRequest) => {
    setTableParams((prev) => ({
      ...prev,
      ...params,
    }))
  }

  const [openFilter, setOpenFilter] = useState(false)

  const showDrawerFilter = () => {
    setOpenFilter(true)
  }

  const onCloseFilter = () => {
    setOpenFilter(false)
  }

  const handleSubmitFilter = (values: any) => {
    let submitData = { ...values }

    if (datePickerFilter !== null) {
      submitData.datetime = dayjs(datePickerFilter)
        .startOf('day')
        .format(serverDateFormat)
    }
    if (submitData && submitData.division !== undefined) {
      submitData.division = submitData.division.value
    }
    if (submitData && submitData.parent !== undefined) {
      submitData.parent = submitData.parent.value
    }
    if (submitData && submitData.type_of_work !== undefined) {
      submitData.type_of_work = submitData.type_of_work.value
    }

    fetchDataTeam({ ...submitData })
    onCloseFilter()
  }

  const handleDelete = async () => {
    if (!rowId) return

    try {
      const res = await services.invoices.delete(rowId)

      if (res.status === 204) {
        fetchDataTeam(
          user && user.groups[0].name === 'Senior Project Manager'
            ? {
                ...tableParams,
              }
            : {
                ...tableParams,
                step: 'closed',
              },
        )
        setIsModalOpen(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [parents, setParents] = useState<any>(null)
  const [assignedData, setassignedData] = useState<any>()
  const [pmsData, sepPmsData] = useState<any>()
  const [statusData, sepStatusData] = useState<any>()
  const [complationPicker, setcomplationPicker] = useState<any>()
  const [types, settypes] = useState<any>()

  const fetchParents = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.requests.getList(params)
      const { results } = res.data

      setParents(results)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchParents()
  }, [fetchParents])

  const fetchAssigned = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAssigned()
  }, [fetchAssigned])

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.all()
      const { results } = res.data

      sepPmsData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  const fetchStatuses = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.status.all()
      const { results } = res.data

      sepStatusData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchStatuses()
  }, [fetchStatuses])

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  const updateRequest = useCallback(
    async (id: string, params?: any) => {
      setIsLoading(true)
      try {
        const res = await services.invoices.update(id, params)

        if (res.status === 200) {
          onClose()
          fetchDataTeam(
            user && user.groups[0].name === 'Senior Project Manager'
              ? {
                  ...tableParams,
                }
              : {
                  ...tableParams,
                  step: 'closed',
                },
          )
          setcomplationPicker(undefined)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
      }
    },
    [fetchDataTeam, tableParams, user],
  )

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.casecode) {
      submitData.append('casecode', values.casecode)
    }

    submitData.append('id', rowData && rowData.id)
    submitData.append(
      'complation_date',
      values.complation_date &&
        dayjs(complationPicker).format(serverDateFormat),
    )
    submitData.append(
      'assigned',
      values.assigned?.map((n: any) => (n.value ? n.value : n)),
    )
    submitData.append('pm', values.pm.value ? values.pm.value : values.pm)
    submitData.append(
      'senior_pm',
      values.senior_pm.value ? values.senior_pm.value : values.senior_pm,
    )
    submitData.append('client', rowData && rowData.client?.id)
    if (values.parent) {
      submitData.append(
        'parent',
        values.parent?.value === 'null'
          ? null
          : values.parent?.value !== 'null'
          ? values.parent?.value
          : values.parent,
      )
    }
    if (values.status !== undefined) {
      submitData.append(
        'status',
        values.status?.value ? values.status.value : values.status,
      )
    }
    if (values.name !== null) {
      submitData.append('name', values.name)
    }
    submitData.append('billable_hours', values.billable_hours)
    submitData.append('email', values.email)
    submitData.append('non_billable_hours', values.non_billable_hours)
    submitData.append('charge_rate', values.charge_rate)
    if (values.type_of_work !== undefined) {
      submitData.append(
        'type_of_work',
        values.type_of_work?.value
          ? values.type_of_work.value
          : values.type_of_work,
      )
    }

    updateRequest(rowData?.id, submitData)
  }

  return (
    <div className="closed">
      <div className="closed__top">
        <div className="closed__title page-title">
          Closed Projects
          {windowWidth && windowWidth < 992 ? (
            <Filter
              onClick={showDrawerFilter}
              style={{ color: '#9092A7', marginRight: '48px' }}
            />
          ) : (
            ''
          )}
        </div>
        {windowWidth && windowWidth > 992 ? (
          <Button
            className="closed__filter"
            onClick={showDrawerFilter}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            More filters
            <i
              style={{ fontSize: '20px', marginLeft: '8px' }}
              className="icon-Filters-lines"
            ></i>
          </Button>
        ) : (
          ''
        )}
      </div>
      <AntTable
        data={data}
        columns={windowWidth && windowWidth > 650 ? columns : columnsMobile}
        loading={isLoading}
        rowsCount={dataCount.count}
        tableParams={tableParams}
        onTableChange={handleTableChange}
      />
      <FilterClosed
        open={openFilter}
        onClose={onCloseFilter}
        datePickerFilter={datePickerFilter}
        setdatePickerFilter={setdatePickerFilter}
        onSubmit={handleSubmitFilter}
        parents={parents}
        types={types}
      />
      <Modal
        title={<i className="icon-pen"></i>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal__custom"
        width={400}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Confirm
          </Button>,
        ]}
      >
        <div className="modal__custom-title">Delete pipeline</div>
        <div className="modal__custom-subtitle">
          Are you sure you want to delete this pipeline?
        </div>
      </Modal>
      {!isLoading && (
        <SeeMoreClosed
          open={open}
          onClose={onClose}
          title="Edit"
          onSubmit={handleSubmit}
          rowData={rowData}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={parents}
          statusData={statusData}
          types={types}
          patchById={patchById}
        />
      )}
    </div>
  )
}

export default Closed
