import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class User extends BaseApi {
  async all(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/account/users/get_employees/${query}`)
  }
  async getTL(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/account/users/get_senior_pm/${query}`)
  }
  async getPM(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/account/users/get_senior_pm/${query}`)
  }
  async getProfile() {
    return await this.api.get(`api/v1/account/users/profile/`)
  }
  async getById(id: string) {
    return await this.api.get(`api/v1/account/users/${id}/`)
  }
  async updateUser(id: string, data?: any) {
    return await this.api.put(`api/v1/account/users/${id}/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
  async patchUser(data?: any) {
    return await this.api.patch(`api/v1/account/users/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
  async updateEmployee(id: string, data?: any) {
    return await this.api.put(`api/v1/edit-employee/${id}/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
  async delete(id: string) {
    return await this.api.delete(`api/v1/account/users/${id}/`)
  }

  async deletePipeline(id: string) {
    return await this.api.delete(`api/v1/projects/pipelines/${id}/`)
  }
}

export default User
