import { Button, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import services from 'src/services'

const Templates = () => {
  const [data, setData] = useState<any>()
  const [rowData, setrowData] = useState<any>(null)
  const [rowIndex, setRowIndex] = useState(0)
  const [rowId, setrowId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    handleDelete(rowId)
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const fetchData = useCallback(async () => {
    try {
      const res = await services.email.all()
      const { results } = res.data

      setData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const fetchDataById = useCallback(async (id: any) => {
    try {
      const res = await services.email.allDetail(id)
      const { data } = res

      setrowData(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const handleDelete = async (id: any) => {
    try {
      const res = await services.email.delete(id)

      if (res.status === 204) {
        fetchData()
        setrowData(null)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (rowData) {
      setrowId(rowData.id)
    }
  }, [rowData])

  const [textValue, settextValue] = useState('')

  useEffect(() => {
    if (rowData) {
      settextValue(rowData.desc)
    }
  }, [rowData])

  return (
    <div className="templates">
      <div className="templates__title page-title">Email Templates</div>
      <div
        className="templates__inner"
        style={rowData ? { background: 'transparent' } : {}}
      >
        {rowData === null &&
          data?.map((n: any, index: any) => (
            <div
              className="templates__card"
              key={n}
              onClick={() => {
                fetchDataById(n.id)
                setRowIndex(index)
              }}
            >
              <div className="templates__card-inner">
                <div className="templates__card-left">
                  <div className="templates__card-img">
                    <img src={n.client.icon} alt="" />
                  </div>
                  <div className="templates__card-info">
                    <div className="templates__card-title">
                      {n.client.name + ' ' + n.client.surname}
                    </div>
                    <div className="templates__card-subtitle">{n.title}</div>
                  </div>
                </div>
                <div className="templates__card-time">
                  {dayjs(n.created_at).format('MMM D, YYYY h:mm A')}
                </div>
              </div>
              <div className="templates__card-info-desc">{n.desc}</div>
            </div>
          ))}
        {rowData ? (
          <div className="templates__row">
            <div className="templates__row-top">
              <div className="templates__row-top-left">
                <div className="templates__row-top-left-icon">
                  <i
                    className="icon-chevron-down"
                    onClick={() => {
                      setrowData(null)
                    }}
                  ></i>
                </div>
                <div className="templates__row-top-title">{rowData.title}</div>
              </div>
              <div className="templates__row-top-right">
                <i
                  className="icon-trash-2"
                  onClick={() => {
                    showModal()
                  }}
                ></i>
                <div className="templates__row-top-right-cross"></div>
                <div className="templates__row-top-right-prev">
                  <i
                    className="icon-chevron-down"
                    onClick={() => {
                      fetchDataById(
                        data
                          .filter(
                            (n: any, index: any) => index === rowIndex - 1,
                          )
                          .find((m: any) => m).id,
                      )
                      setRowIndex(rowIndex - 1)
                    }}
                  ></i>
                </div>
                <div className="templates__row-top-right-next">
                  <i
                    className="icon-chevron-down"
                    onClick={() => {
                      fetchDataById(
                        data
                          .filter(
                            (n: any, index: any) => index === rowIndex + 1,
                          )
                          .find((m: any) => m).id,
                      )
                      setRowIndex(rowIndex + 1)
                    }}
                  ></i>
                </div>
              </div>
            </div>
            <div className="templates__row-bottom">
              <div className="templates__row-profile">
                <div className="templates__row-profile-left">
                  <div className="templates__row-profile-img">
                    <img src={rowData.client.icon} alt="" />
                  </div>
                  <div className="templates__row-profile-info">
                    <div className="templates__row-profile-name">
                      {rowData.client.name + ' ' + rowData.client.surname}
                    </div>
                    <div className="templates__row-profile-email">
                      {rowData.client.email}
                    </div>
                  </div>
                </div>
                <div className="templates__row-profile-time">
                  {dayjs(rowData.created_at).format('MMM D, YYYY h:mm A')}
                </div>
              </div>
              <div className="templates__row-desc">
                <div className="templates__row-desc-title">
                  Email description
                </div>
                <TextArea
                  className="templates__row-desc-textarea"
                  style={{
                    minHeight: '187px',
                    height: '100%',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#344054',
                  }}
                  onChange={(e) => {
                    settextValue(e.target.value)
                  }}
                  value={textValue}
                />
                <Button>Send now</Button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal
        title={<i className="icon-alert-circle"></i>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal__custom"
        width={400}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Confirm
          </Button>,
        ]}
      >
        <div className="modal__custom-title">Delete template</div>
        <div className="modal__custom-subtitle">
          Are you sure you want to delete this template?
        </div>
      </Modal>
    </div>
  )
}

export default Templates
