/* eslint-disable react-hooks/rules-of-hooks */
import pageTitles from 'src/app/pageTitles'
import { MainLayout } from 'src/layouts'
import Analytics from 'src/pages/Analytics/Analytics'
import Approval from 'src/pages/Approval/Approval'
import Closed from 'src/pages/Closed/Closed'
import Dashboard from 'src/pages/Dashboard/Dashboard'
import Notify from 'src/pages/Notify/Notify'
import Paid from 'src/pages/Paid/Paid'
import Pipeline from 'src/pages/Pipeline/Pipeline'
import Requests from 'src/pages/Requests/Requests'
import Settings from 'src/pages/Settings/Settings'
import Team from 'src/pages/Team/Team'
import Templates from 'src/pages/Templates/Templates'
import { roles } from 'src/utils/getRole'

const routes = [
  {
    exact: true,
    path: '/dashboard',
    title: pageTitles.dashboard,
    component: Dashboard,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-dashboard"></i>,
    inSideBar: true,
  },

  {
    exact: true,
    path: '/requests',
    title: pageTitles.requests,
    component: Requests,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-hard-drive"></i>,
    inSideBar: true,
  },
  {
    exact: true,
    path: '/pipeline',
    title: pageTitles.pipeline,
    component: Pipeline,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-roadmap"></i>,
    inSideBar: true,
  },

  {
    exact: true,
    path: '/team',
    title: pageTitles.team,
    component: Team,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-users"></i>,
    inSideBar: true,
  },
  {
    exact: true,
    path: '/analytics',
    title: pageTitles.analytics,
    component: Analytics,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-trending-up"></i>,
    inSideBar: true,
  },
  {
    exact: true,
    path: '/templates',
    title: pageTitles.templates,
    component: Templates,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-mail"></i>,
    inSideBar: true,
  },

  {
    exact: true,
    path: '/closed',
    title: pageTitles.closed,
    component: Closed,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-hard-drive"></i>,
    inSideBar: true,
  },
  {
    exact: true,
    path: '/approval',
    title: pageTitles.approval,
    component: Approval,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-clipboard"></i>,
    inSideBar: true,
  },
  {
    exact: true,
    path: '/paid',
    title: pageTitles.paid,
    component: Paid,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-check-circle"></i>,
    inSideBar: true,
  },

  {
    exact: true,
    path: '/settings',
    title: pageTitles.settings,
    component: Settings,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-dangerL"></i>,
    inSideBar: false,
  },
  {
    exact: true,
    path: '/notify',
    title: pageTitles.notify,
    component: Notify,
    layout: MainLayout,
    role: [roles.user],
    icon: <i className="icon-dangerL"></i>,
    inSideBar: false,
  },
]

export default routes
