import { message } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { serverDateFormat } from 'src/app/constants'
import { IPageRequest } from 'src/app/models/page'
import RequestDetail from 'src/components/common/RequestDetail'
import PipelineDetail from 'src/components/common/PipelineDetail'
import services from 'src/services'

const Notify = () => {
  const [notifications, setnotifications] = useState<any>()

  const fetchNotifications = useCallback(async () => {
    try {
      const res = await services.notifications.all({ page_size: 1000 })
      const { data } = res

      setnotifications(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  const [openPipe, setOpenPipe] = useState(false)
  const [openProject, setOpenProject] = useState(false)
  const [datePicker, setdatePicker] = useState<any>()

  const showDrawerPipe = () => {
    setOpenPipe(true)
  }

  const onClosePipe = () => {
    setOpenPipe(false)
    setNewClient(null)
  }

  const showDrawerProject = () => {
    setOpenProject(true)
  }

  const onCloseProject = () => {
    setOpenProject(false)
    setRowDataProject(null)
    setNewClient(null)
    setselctedtls(null)
  }

  const [rowDataProject, setRowDataProject] = useState<any>(null)

  const fetchDataById = async (id: string) => {
    try {
      const res = await services.requests.getDetail(id)
      const { data } = res
      fetchAllData()
      setRowDataProject(data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmitProject = (values: any) => {
    let submitData = { ...values }

    if (
      (submitData.assigned &&
        selectedStatus &&
        selectedStatus === 'Delivered') ||
      (submitData.assigned && selectedStatus && selectedStatus === 'Cancelled')
    ) {
      let some: any = []
      let newArr: any
      values.assigned?.map((n: any) => {
        newArr = {
          id: n.id.value ? n.id.value : n.id,
          billable_hours: n.billable_hours,
          non_billable_hours: n.non_billable_hours,
        }
        some.push(newArr)
        return newArr
      })
      submitData.assigned = some
    }

    if (
      (submitData.assigned &&
        selectedStatus &&
        selectedStatus !== 'Delivered') ||
      (submitData.assigned && selectedStatus && selectedStatus !== 'Cancelled')
    ) {
      let some: any = []
      let newArr: any
      values.assigned?.map((n: any) => {
        newArr = { id: n.value ? n.value : n }
        some.push(newArr)
        return newArr
      })
      submitData.assigned = some
    }

    if (submitData.pm) {
      submitData.pm =
        selectedStatus === 'Delivered' || selectedStatus === 'Cancelled'
          ? {
              id: values.pm.value ? values.pm.value : values.pm,
              billable_hours: values.pm_billable_hours
                ? values.pm_billable_hours
                : '',
              non_billable_hours: values.pm_non_billable_hours
                ? values.pm_non_billable_hours
                : '',
            }
          : {
              id: values.pm.value ? values.pm.value : values.pm,
            }
    }
    if (submitData.senior_pm) {
      submitData.senior_pm =
        selectedStatus === 'Delivered' || selectedStatus === 'Cancelled'
          ? {
              id: values.senior_pm.value
                ? values.senior_pm.value
                : values.senior_pm,
              billable_hours: values.spm_billable_hours
                ? values.spm_billable_hours
                : '',
              non_billable_hours: values.spm_non_billable_hours
                ? values.spm_non_billable_hours
                : '',
            }
          : {
              id: values.senior_pm.value
                ? values.senior_pm.value
                : values.senior_pm,
            }
    }

    if (submitData.company) {
      submitData.company = values.company
    }
    if (values.client) {
      submitData.client = values.client.value
        ? values.client.value
        : values.client
    }
    if (values.subject) {
      submitData.subject = values.subject
    }
    if (values.casecode) {
      submitData.casecode = values.casecode
    }

    submitData.datetime =
      values.datetime &&
      dayjs(datePicker).format('YYYY-MM-DD') +
        'T' +
        dayjs(timePicker).format('HH:mm:ss')

    submitData.id = rowDataProject && rowDataProject.id
    submitData.complation_date =
      values.complation_date && dayjs(complationPicker).format(serverDateFormat)

    if (values.parent) {
      submitData.parent = values.parent?.value
        ? values.parent?.value
        : values.parent
    }
    if (values.status !== undefined) {
      submitData.status = values.status?.value
        ? values.status.value
        : values.status
    }
    submitData.estimated_hours = values.estimated_hours
    if (submitData.billable_hours) {
      submitData.billable_hours = values.billable_hours
    }
    if (submitData.non_billable_hours) {
      submitData.non_billable_hours = values.non_billable_hours
    }
    submitData.charge_rate = values.charge_rate
    if (values.type_of_work !== undefined) {
      submitData.type_of_work = values.type_of_work?.value
        ? values.type_of_work.value
        : values.type_of_work
    }
    if (values.sector !== null) {
      submitData.sector = values.sector.value
        ? values.sector.value
        : values.sector
    }
    delete submitData.name
    delete submitData.id

    updateRequestProject(rowDataProject?.id, submitData)
  }

  const updateRequestProject = async (id: string, params?: any) => {
    try {
      const res = await services.requests.updateRequests(id, params)

      if (res.status === 200) {
        onCloseProject()
        setdatePicker(undefined)
        settimePicker(undefined)
        setcomplationPicker(undefined)
        setSelectedClient(null)
        setclientCompany(null)
        setselectedStatus(null)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [timePicker, settimePicker] = useState<any>()
  const [complationPicker, setcomplationPicker] = useState<any>()
  const [pmsData, sepPmsData] = useState<any>()
  const [statusData, sepStatusData] = useState<any>()
  const [secotrData, sepSecotrData] = useState<any>()
  const [assignedData, setassignedData] = useState<any>()
  const [tls, settls] = useState<any>(null)
  const [pms, setpms] = useState<any>(null)
  const [selctedtls, setselctedtls] = useState<any>(null)

  const fetchTL = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getTL()
      const { results } = res.data

      settls(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTL()
  }, [fetchTL])

  const fetchPM = useCallback(async (data?: any) => {
    try {
      const res = await services.user.getPM({ id: data })
      const { results } = res.data

      setpms(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (selctedtls) {
      fetchPM(selctedtls)
    }
  }, [fetchPM, selctedtls])

  const fetchAssigned = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAssigned()
  }, [fetchAssigned])

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.all()
      const { results } = res.data

      sepPmsData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  const fetchStatuses = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.status.all()
      const { results } = res.data

      sepStatusData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchStatuses()
  }, [fetchStatuses])

  const fetchSector = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.sector.all()
      const { results } = res.data

      sepSecotrData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchSector()
  }, [fetchSector])

  const [types, settypes] = useState<any>()

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  const [clients, setClients] = useState<any>(null)

  const fetchClients = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.clients.all({ page_size: 1000 })
      const { results } = res.data

      setClients(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  const [allRequests, setAllRequests] = useState<any>()

  const fetchAllData = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.requests.getList(params)
      const { results } = res.data

      setAllRequests(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [selectedStatus, setselectedStatus] = useState<any>(null)
  const [selectedAssigned, setSelectedAssigned] = useState<any>(null)
  const [clientCompany, setclientCompany] = useState<any>(null)
  const [selectedChargeRate, setselectedChargeRate] = useState<any>(null)
  const [selectedType, setselectedType] = useState<any>(null)

  const [rowDataPipe, setRowDataPipe] = useState<any>(null)

  const fetchDataByIdPipe = async (id: string) => {
    try {
      const res = await services.pipeline.allDetail(id)
      const { data } = res

      setRowDataPipe(data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmitPipeline = (values: any) => {
    const submitData = new FormData()

    if (values.assigned) {
      submitData.append(
        'assigned',
        values.assigned?.map((n: any) => (n.value ? n.value : n)),
      )
    }

    if (values.company) {
      submitData.append('company', values.company)
    }
    if (values.subject) {
      submitData.append('subject', values.subject)
    }
    if (values.casecode) {
      submitData.append('casecode', values.casecode)
    }

    submitData.append(
      'datetime',
      values.datetime &&
        dayjs(datePicker).format('YYYY-MM-DD') +
          'T' +
          dayjs(timePicker).format('HH:mm:ss'),
    )
    submitData.append('id', rowDataPipe && rowDataPipe.id)
    submitData.append(
      'complation_date',
      values.complation_date &&
        dayjs(complationPicker).format(serverDateFormat),
    )

    if (values.pm) {
      submitData.append('pm', values.pm.value ? values.pm.value : values.pm)
    }
    submitData.append(
      'senior_pm',
      values.senior_pm.value ? values.senior_pm.value : values.senior_pm,
    )
    if (values.client) {
      submitData.append(
        'client',
        values.client.value ? values.client.value : values.client,
      )
    }
    if (values.parent) {
      submitData.append(
        'parent',
        values.parent?.value === 'null'
          ? ''
          : values.parent?.value !== 'null'
          ? values.parent?.value
          : values.parent,
      )
    }
    if (values.status !== undefined) {
      submitData.append(
        'status',
        values.status?.value ? values.status.value : values.status,
      )
    }
    if (values.name !== null) {
      submitData.append('name', values.name)
    }
    submitData.append('scooped_hours', values.scooped_hours)
    submitData.append('estimated_hours', values.estimated_hours)
    submitData.append('charge_rate', values.charge_rate)
    if (values.type_of_work !== undefined) {
      submitData.append(
        'type_of_work',
        values.type_of_work?.value
          ? values.type_of_work.value
          : values.type_of_work,
      )
    }
    if (values.sector !== null) {
      submitData.append(
        'sector',
        values.sector.value ? values.sector.value : values.sector,
      )
    }

    createPipeline(submitData)
  }

  const createPipeline = useCallback(
    async (params?: any) => {
      try {
        const res = await services.requests.postRequests(params)

        if (res.status === 201) {
          onClosePipe()
          fetchNotifications()
          setselectedChargeRate(null)
          setselectedType(null)
          setSelectedClient(null)
          setclientCompany(null)
          setselectedStatus(null)
        }
      } catch (e) {
        console.log(e)
      }
    },
    [fetchNotifications],
  )

  const [newClient, setNewClient] = useState<any>(null)

  const fetchClientsAdd = useCallback(
    async (data: any) => {
      try {
        const res = await services.clients.add(data)

        if (res.status === 200) {
          setNewClient(res.data)
          fetchClients()
        }
      } catch (e: any) {
        message.error(e.response.data.msg)
      }
    },
    [fetchClients],
  )

  return (
    <div>
      <div
        style={{
          display: 'flex',
          paddingBottom: '24px',
          justifyContent: 'space-between',
        }}
      >
        <div
          className="header__notification-title"
          style={{
            fontWeight: '600',
            fontSize: '24px',
            lineHeight: '29px',
            color: '#344054',
          }}
        >
          Notification
        </div>
        <div
          className="header__notification-count"
          style={{
            marginRight: '50px',
          }}
        >{`${notifications && notifications.count} New`}</div>
      </div>
      <div
        className="header__notification"
        style={{ overflow: 'hidden', width: '100%' }}
      >
        <div>
          {notifications &&
            notifications.results?.map((n: any) => (
              <div
                className="header__notification-item"
                key={n.id}
                onClick={() => {
                  if (n.pipeline !== null) {
                    fetchDataByIdPipe(n.pipeline)
                    showDrawerPipe()
                  }
                  if (n.request !== null) {
                    fetchDataById(n.request)
                    showDrawerProject()
                  }
                }}
              >
                <div className="header__notification-item-img">
                  <img src={notifications && notifications.icon} alt="" />
                </div>
                <div className="header__notification-item-info">
                  <span>{n.title}</span>
                  <span>
                    {dayjs(n.created_at).format('MMM D, YYYY h:mm A')}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
      {rowDataProject && (
        <RequestDetail
          onSubmit={handleSubmitProject}
          open={openProject}
          onClose={onCloseProject}
          title="More Info"
          rowData={rowDataProject}
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          selectedStatus={selectedStatus}
          setselectedStatus={setselectedStatus}
          selectedAssigned={selectedAssigned}
          setSelectedAssigned={setSelectedAssigned}
          clientCompany={clientCompany}
          setclientCompany={setclientCompany}
          selectedChargeRate={selectedChargeRate}
          setselectedChargeRate={setselectedChargeRate}
          selectedType={selectedType}
          setselectedType={setselectedType}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
      )}
      {rowDataPipe && (
        <PipelineDetail
          onSubmit={handleSubmitPipeline}
          rowData={rowDataPipe}
          open={openPipe}
          onClose={onClosePipe}
          title="Pick Request"
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          selectedStatus={selectedStatus}
          setselectedStatus={setselectedStatus}
          selectedAssigned={selectedAssigned}
          setSelectedAssigned={setSelectedAssigned}
          clientCompany={clientCompany}
          setclientCompany={setclientCompany}
          selectedChargeRate={selectedChargeRate}
          setselectedChargeRate={setselectedChargeRate}
          selectedType={selectedType}
          setselectedType={setselectedType}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
      )}
    </div>
  )
}

export default Notify
