import { Input } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { ColumnType } from 'antd/lib/table'
import { SearchOutlined } from '@ant-design/icons'
// import Highlighter from 'react-highlight-words'
import { FilterDropdownProps } from 'antd/lib/table/interface'

function useColumnSearch<T>(
  columns: ColumnType<T>[],
  upgradeColumns: (keyof Partial<T>)[],
) {
  // const [searchText, setSearchText] = useState('')
  // const [searchedColumn, setSearchedColumn] = useState('')
  const [upColumns, setUpColumns] = useState<ColumnType<T>[] | null>(null)
  const [params, setParams] = useState<{ [key: string]: string }>({})

  const getColumnSearchProps = useCallback(
    (dataIndex: any) => {
      const searchInput: { current: any | null } = { current: null }

      return {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
        }: FilterDropdownProps) => {
          return (
            <div style={{ padding: 8, maxWidth: 200 }}>
              <Input
                ref={(node) => {
                  searchInput.current = node
                }}
                placeholder={`Поиск ${
                  columns.find((m: any) => m.dataIndex === dataIndex)?.title
                }`}
                value={selectedKeys[0]}
                onChange={(e) => {
                  const val = e.target.value
                  if (val) {
                    setSelectedKeys([val])
                    handleSearch([val], confirm, dataIndex)
                  } else {
                    setSelectedKeys([])
                    handleSearch([], confirm, dataIndex)
                  }

                  setParams((prev) => ({
                    ...prev,
                    [dataIndex]: val,
                  }))
                }}
                onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, dataIndex)
                }
                allowClear
              />
            </div>
          )
        },
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: string | number | boolean, record: any) =>
          record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(String(value).toLowerCase())
            : '',
        onFilterDropdownVisibleChange: (visible: any) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100)
          }
        },
        // render: (text: any) =>
        //   searchedColumn === dataIndex ? (
        //     <Highlighter
        //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //       searchWords={[searchText]}
        //       autoEscape
        //       textToHighlight={text ? text.toString() : ''}
        //     />
        //   ) : (
        //     text
        //   ),
      }
    },
    [columns],
  )

  useEffect(() => {
    if (!upColumns) {
      const upgradedColumns = columns.map((col) => {
        let newCol = { ...col }
        upgradeColumns.forEach((uCol) => {
          if (col.dataIndex === uCol) {
            newCol = { ...newCol, ...getColumnSearchProps(uCol) }
          }
        })
        return newCol
      })
      setUpColumns(upgradedColumns)
    }
  }, [upgradeColumns, columns, getColumnSearchProps, upColumns])

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm({ closeDropdown: false })
    // setSearchText(selectedKeys[0])
    // setSearchedColumn(dataIndex)
  }

  return {
    getColumnSearchProps,
    upColumns,
    params,
  }
}

export default useColumnSearch
