import { IPageRequest } from './models/page'

export const LS_ACCESS = 'LS_ACCESS'

export const initialPageData: IPageRequest = {
  pageNumber: 1,
  pageSize: 10,
}

export const messageTexts = {
  saveSuccess: 'Успешно сохранено',
  deleteSuccess: 'Успешно удалено',
}

export const CTAButtons: { [key: string]: string } = {
  create: 'Создать',
  cancel: 'Отменить',
  delete: 'Удалить',
  no: 'Отмена',
  yes: 'Да',
  update: 'Сохранить',
  add: 'Добавить',
}

export const formFieldTexts: { [key: string]: string } = {
  required: 'Заполните необходимое поле',
}

export const defaultDateTimeFormat: string = 'DD-MM-YYYY HH:mm:ss'
export const defaultDateFormat: string = 'DD-MM-YYYY'
export const serverDateFormat: string = 'YYYY-MM-DDTHH:mm:ss'
