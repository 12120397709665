import { Button, Drawer, Form, Input, Select } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useAppSelector } from 'src/app/hooks'
import DatePicker from './DatePicker'

interface IProps {
  open: boolean
  onClose: () => void
  title: string
  onSubmit: (values: any) => void
  rowData: any
  datePicker: any
  setdatePicker: any
  timePicker: any
  settimePicker: any
  complationPicker: any
  setcomplationPicker: any
  assigned: any
  pmsData: any
  allRequests: any
  statusData: any
  secotrData: any
  types: any
  clients: any
  selectedClient: any
  setSelectedClient: any
  selectedStatus: any
  setselectedStatus: any
  selectedAssigned: any
  setSelectedAssigned: any
  clientCompany: any
  setclientCompany: any
  selectedChargeRate: any
  setselectedChargeRate: any
  selectedType: any
  setselectedType: any
  setselctedtls: any
  tls: any
  pms: any
  fetchClientsAdd: any
  newClient: any
}

const { Option } = Select

const PipelineDetail: React.FC<IProps> = ({
  open,
  onClose,
  title,
  onSubmit,
  rowData,
  datePicker,
  setdatePicker,
  timePicker,
  settimePicker,
  complationPicker,
  setcomplationPicker,
  assigned,
  pmsData,
  allRequests,
  statusData,
  secotrData,
  types,
  clients,
  selectedClient,
  setSelectedClient,
  selectedStatus,
  setselectedStatus,
  selectedAssigned,
  setSelectedAssigned,
  clientCompany,
  setclientCompany,
  selectedChargeRate,
  setselectedChargeRate,
  selectedType,
  setselectedType,
  setselctedtls,
  tls,
  pms,
  fetchClientsAdd,
  newClient,
}) => {
  const [form] = Form.useForm()
  const user = useAppSelector((state) => state.user.data)

  useEffect(() => {
    if (selectedType && selectedClient) {
      setselectedChargeRate(
        clients
          .find((n: any) => n.id === selectedClient)
          .charge_rates.find((m: any) => m.type_of_work === selectedType).price,
      )
    }
  }, [selectedType, clients, setselectedChargeRate, selectedClient])

  useEffect(() => {
    if (rowData && !selectedType) {
      setselectedChargeRate(rowData.type_of_work?.price)
    }
  }, [rowData, selectedType, setselectedChargeRate])

  useEffect(() => {
    if (selectedChargeRate !== null) {
      form.setFieldValue('charge_rate', selectedChargeRate)
    }
  }, [form, selectedChargeRate, selectedClient])

  useEffect(() => {
    if (selectedClient && Array.isArray(clients) && clients.length) {
      const findClient = clients.find((n: any) => n.id === selectedClient)
      setclientCompany(
        findClient?.company ?? findClient?.email.split('@').pop().split('.')[0],
      )
    }
  }, [selectedClient, clients, setclientCompany])

  useEffect(() => {
    if (clientCompany) {
      form.setFieldValue('company', clientCompany)
    }
  }, [clientCompany, form])

  useEffect(() => {
    if (rowData && rowData.datetime && datePicker === undefined) {
      setdatePicker(dayjs(rowData.datetime))
    }
  }, [rowData, datePicker, setdatePicker])

  useEffect(() => {
    if (rowData && rowData.datetime && timePicker === undefined) {
      settimePicker(dayjs(rowData.datetime))
    }
  }, [rowData, timePicker, settimePicker])

  useEffect(() => {
    if (rowData && rowData.datetime && complationPicker === undefined) {
      setcomplationPicker(dayjs(rowData.datetime).startOf('day'))
    }
  }, [rowData, complationPicker, setcomplationPicker])

  useEffect(() => {
    form.setFieldsValue({
      company: rowData && rowData.client?.email.split('@').pop().split('.')[0],
      subject: rowData && rowData.subject,
      sector: rowData && rowData.sector && rowData.sector?.id,
      type_of_work: rowData && rowData.type_of_work && rowData.type_of_work?.id,
      scooped_hours: rowData && rowData.scooped_hours,
      estimated_hours: rowData && rowData.estimated_hours,
      charge_rate: rowData && rowData.charge_rate,
      senior_pm: rowData && rowData.senior_pm?.id,
      pm: rowData && rowData.pm?.id,
      status: rowData && rowData.status && rowData.status?.id,
      casecode: rowData && rowData.casecode,
      parent: rowData && rowData?.parent === null ? 'null' : rowData?.parent,
      datetime: rowData && dayjs(rowData.datetime),
      complation_date: rowData && dayjs(rowData.datetime),
    })
  }, [form, rowData])

  useEffect(() => {
    if (newClient === null) {
      form.setFieldsValue({
        client: rowData && rowData.client?.id,
      })
    }
  }, [newClient, form, rowData])

  useEffect(() => {
    if (newClient !== null) {
      form.setFieldsValue({
        client: newClient.id,
      })
    }
  }, [newClient, form])

  useEffect(() => {
    if (newClient !== null) {
      form.setFieldsValue({
        company: newClient.email.split('@').pop().split('.')[0],
      })
    }
  }, [newClient, form])

  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  useEffect(() => {
    if (windowWidth && windowWidth > 992) {
      if (open) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }, [open, windowWidth])

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      getContainer={false}
      visible={open}
      className="drawer__custom"
      zIndex={10000}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name="client"
          label="Client"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(val) => {
              setSelectedClient(val.value)
            }}
            onInputKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchClientsAdd({ data: e.currentTarget.value })
              }
            }}
          >
            {clients &&
              clients.map((n: any) => (
                <Option value={n.id} label={n.name || n.surname} key={n.id}>
                  {n.name + ', ' + n.surname + ' <' + n.email + '>'}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="company" label="Company">
          <Input placeholder="" disabled />
        </Form.Item>
        <div className="drawer__custom-double">
          <Form.Item
            name="datetime"
            label="Date"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              suffixIcon={
                <i className="icon-calendar" style={{ color: '#9092a7' }}></i>
              }
              onChange={(el) => {
                setdatePicker(el)
              }}
              value={datePicker}
            />
          </Form.Item>
          <Form.Item
            name="datetime"
            label="Time"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              picker="time"
              suffixIcon={<i className="icon-clock"></i>}
              onChange={(el) => {
                settimePicker(el)
              }}
              value={timePicker}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="subject"
          label="Subject Line"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          name="sector"
          label="Sector"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          >
            {secotrData &&
              secotrData.map((n: any) => (
                <Option value={n.id}>{n.name}</Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="type_of_work"
          label="Type of work"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            onChange={(val) => {
              setselectedType(val.value)
            }}
          >
            {types &&
              types.map((n: any) => <Option value={n.id}>{n.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="parent" label="Parent project">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          >
            <Option value="null">No Parent</Option>
            {allRequests &&
              allRequests.map((n: any) => (
                <Option value={n.id}>{n.id_code}</Option>
              ))}
          </Select>
        </Form.Item>
        <div className="drawer__custom-double">
          <Form.Item
            name="estimated_hours"
            label="Est. Effort (hrs)"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <Input placeholder="" prefix={<i className="icon-clock"></i>} />
          </Form.Item>
        </div>
        <div className="drawer__custom-double">
          <Form.Item
            name="complation_date"
            label="Est. Delivery Date"
            rules={[
              {
                required: true,
                message: 'Fill the field',
              },
            ]}
          >
            <DatePicker
              suffixIcon={
                <i className="icon-calendar" style={{ color: '#9092a7' }}></i>
              }
              onChange={(el) => {
                setcomplationPicker(el)
              }}
              value={complationPicker}
            />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select
              labelInValue
              placeholder=""
              allowClear={true}
              style={{ marginBottom: '0' }}
              suffixIcon={
                <i
                  className="icon-chevron-down"
                  style={{ fontSize: '20px', color: '#667085' }}
                ></i>
              }
            >
              {statusData &&
                statusData.map((n: any) => (
                  <Option value={n.id}>{n.name}</Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item name="charge_rate" label="Charge rate">
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          name="senior_pm"
          label="Team Leader"
          rules={[
            {
              required: true,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(val) => setselctedtls(val.value)}
          >
            {tls &&
              tls?.map((n: any) => (
                <Option
                  key={n.id}
                  value={n.id}
                  label={n.profile.first_name || n.profile.last_name}
                >
                  {n.profile.first_name + ' ' + n.profile.last_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="pm"
          label="Project Manager"
          rules={[
            {
              required: false,
              message: 'Fill the field',
            },
          ]}
        >
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          >
            {pms &&
              pms?.map((n: any) => (
                <Option
                  key={n.id}
                  value={n.id}
                  label={n.profile.first_name || n.profile.last_name}
                >
                  {n.profile.first_name + ' ' + n.profile.last_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="assigned"
          label="Assigned Team Members"
          rules={[
            {
              required: false,
              message: 'Fill the field',
            },
          ]}
          style={{ height: 'auto' }}
        >
          <Select
            mode="multiple"
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            suffixIcon={
              <i
                className="icon-chevron-down"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
            showSearch
            optionFilterProp="children"
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          >
            {pmsData &&
              pmsData.map((n: any) =>
                n.groups.length !== 0 &&
                n.groups?.find((m: any) => m.name !== 'Business Head') ? (
                  <Option
                    key={n.id}
                    value={n.id}
                    label={n.profile.first_name || n.profile.last_name}
                  >
                    {n.profile.first_name + ' ' + n.profile.last_name}
                  </Option>
                ) : (
                  ''
                ),
              )}
          </Select>
        </Form.Item>
        <Form.Item name="casecode" label="Case code">
          <Input
            placeholder=""
            suffix={
              <i
                className="icon-edit-2"
                style={{ fontSize: '20px', color: '#667085' }}
              ></i>
            }
          />
        </Form.Item>
        {(user && user.groups[0].name === 'Business Head') ||
        (user && user.groups[0].name === 'Senior Project Manager') ? (
          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: ' 25px',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                width: '108px',
                height: '40px',
                flex: '0 1 50%',
                borderRadius: '8px',
                background: '#28C76F',
                border: '1px solid #28C76F',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              // loading={isLoading}
              className="login__btn"
            >
              <i
                className="icon-external-link"
                style={{ marginRight: '8px' }}
              ></i>
              Submit
            </Button>
          </Form.Item>
        ) : (
          ''
        )}
      </Form>
    </Drawer>
  )
}

export default PipelineDetail
