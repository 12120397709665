import { Button, Drawer, Form, Input, Select, Space } from 'antd'
import React, { useEffect } from 'react'
import { useAppSelector } from 'src/app/hooks'
import DatePicker from './DatePicker'

const { Option } = Select

interface IProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: any) => void
  complationPickerFilter: any
  setcomplationPickerFilter: any
  datePickerFilter: any
  setdatePickerFilter: any
  statusData: any
}

const FilterRequests: React.FC<IProps> = ({
  open,
  onClose,
  onSubmit,
  complationPickerFilter,
  setcomplationPickerFilter,
  datePickerFilter,
  setdatePickerFilter,
  statusData,
}) => {
  const [form] = Form.useForm()

  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  useEffect(() => {
    if (windowWidth && windowWidth > 992) {
      if (open) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }, [open, windowWidth])

  return (
    <Drawer
      title="Filter"
      placement="right"
      onClose={onClose}
      getContainer={false}
      visible={open}
      className="drawer__custom"
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name="datetime" label="Date">
          <Space direction="vertical">
            <DatePicker
              suffixIcon={<i className="icon-calendar"></i>}
              onChange={(el) => {
                setdatePickerFilter(el)
              }}
              value={datePickerFilter}
            />
          </Space>
        </Form.Item>
        <Form.Item name="request" label="Request ">
          <Input placeholder="Search by request and subject line" />
        </Form.Item>
        <Form.Item name="client" label="Client">
          <Input placeholder="Search by client’s name" />
        </Form.Item>
        <Form.Item name="tm_and_senior_pm" label="PM/TM ">
          <Input placeholder="Search by PM’s and TM’s names" />
        </Form.Item>
        <Form.Item name="type_of_work" label="Type of work">
          <Input placeholder="Search by type of work" />
        </Form.Item>
        <Form.Item name="sector" label="Sector">
          <Input placeholder="Search by type of sector" />
        </Form.Item>
        <Form.Item name="status" label="Request status">
          <Select
            labelInValue
            placeholder="Search by request status"
            allowClear={true}
            style={{ marginBottom: '0' }}
          >
            {statusData &&
              statusData.map((n: any) => (
                <Option value={n.id}>{n.name}</Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="complation_date" label="Request complation date">
          <Space direction="vertical">
            <DatePicker
              suffixIcon={<i className="icon-calendar"></i>}
              onChange={(el) => {
                setcomplationPickerFilter(el)
              }}
              value={complationPickerFilter}
            />
          </Space>
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '9px',
            }}
          >
            <Button
              type="primary"
              size="large"
              style={{
                width: '108px',
                height: '40px',
                borderRadius: '8px',
                background: '#568EFF',
                border: '1px solid #568EFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '11px',
              }}
              // loading={isLoading}
              className="login__btn"
              onClick={() => {
                form.resetFields()
                setcomplationPickerFilter(null)
                setdatePickerFilter(null)
              }}
            >
              <i className="icon-rotate-cw" style={{ marginRight: '8px' }}></i>
              Clear all
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                width: '108px',
                height: '40px',
                borderRadius: '8px',
                background: '#28C76F',
                border: '1px solid #28C76F',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              // loading={isLoading}
              className="login__btn"
            >
              <i
                className="icon-external-link"
                style={{ marginRight: '8px' }}
              ></i>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FilterRequests
