import { message } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { User } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { serverDateFormat } from 'src/app/constants'
import { useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'
import services from 'src/services'
import ProfileEdit from '../common/ProfileEdit'
import SeeMoreNew from '../common/SeeMoreNew'

const TheFooter = () => {
  const [openProfile, setOpenProfile] = useState(false)
  const [datePicker, setdatePicker] = useState<any>()
  const [rowIcon, setRowIcon] = useState('')
  const history = useHistory()
  const user = useAppSelector((state) => state.user.data)

  const showDrawerProfile = () => {
    setOpenProfile(true)
  }

  const onCloseProfile = () => {
    setOpenProfile(false)
    document.body.style.overflow = 'unset'
  }

  const [open, setOpen] = useState(false)
  const [datePickerNew, setdatePickerNew] = useState<any>()
  const [timePicker, settimePicker] = useState<any>()
  const [complationPicker, setcomplationPicker] = useState<any>()

  const showDrawer = () => {
    setOpen(true)
    setresetForm(false)
  }

  const onClose = () => {
    setOpen(false)
    document.body.style.overflow = 'unset'
    setselectedChargeRate(null)
    setselectedType(null)
    setSelectedClient(null)
    setclientCompany(null)
    setNewClient(null)
  }

  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [clientCompany, setclientCompany] = useState<any>(null)
  const [selectedChargeRate, setselectedChargeRate] = useState<any>(null)
  const [selectedType, setselectedType] = useState<any>(null)

  const [resetForm, setresetForm] = useState(false)

  const updateRequestNew = useCallback(async (params?: any) => {
    try {
      const res = await services.requests.postRequests(params)

      if (res.status === 201) {
        setresetForm(true)
        onClose()
        setdatePicker(undefined)
        settimePicker(undefined)
        setcomplationPicker(undefined)
        setselectedChargeRate(null)
        setselectedType(null)
        setSelectedClient(null)
        setclientCompany(null)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  const handleSubmitNew = (values: any) => {
    const submitData = new FormData()

    if (values.company) {
      submitData.append('company', values.company)
    }
    if (values.subject) {
      submitData.append('subject', values.subject)
    }
    if (values.casecode) {
      submitData.append('casecode', values.casecode)
    }

    submitData.append(
      'datetime',
      values.datetime &&
        dayjs(datePicker).format('YYYY-MM-DD') +
          'T' +
          dayjs(timePicker).format('HH:mm:ss'),
    )
    submitData.append(
      'complation_date',
      values.complation_date &&
        dayjs(complationPicker).format(serverDateFormat),
    )
    submitData.append(
      'assigned',
      values.assigned?.map((n: any) => (n.value ? n.value : n)),
    )
    submitData.append('pm', values.pm.value ? values.pm.value : values.pm)
    submitData.append(
      'senior_pm',
      values.senior_pm.value ? values.senior_pm.value : values.senior_pm,
    )
    // submitData.append(
    //   'client',
    //   values.client.value ? values.client.value : values.client,
    // )
    if (values.parent) {
      submitData.append(
        'parent',
        values.parent?.value === 'null'
          ? null
          : values.parent?.value !== 'null'
          ? values.parent?.value
          : values.parent,
      )
    }
    if (values.status !== undefined) {
      submitData.append(
        'status',
        values.status?.value ? values.status.value : values.status,
      )
    }
    if (values.client) {
      submitData.append(
        'client',
        values.client?.value ? values.status.value : values.status,
      )
    }
    submitData.append('estimated_hours', values.estimated_hours)
    submitData.append('charge_rate', values.charge_rate)
    if (values.type_of_work !== undefined) {
      submitData.append(
        'type_of_work',
        values.type_of_work?.value
          ? values.type_of_work.value
          : values.type_of_work,
      )
    }
    if (values.sector !== null) {
      submitData.append(
        'sector',
        values.sector.value ? values.sector.value : values.sector,
      )
    }

    updateRequestNew(submitData)
  }

  const [assignedData, setassignedData] = useState<any>()
  const [pmsData, sepPmsData] = useState<any>()
  const [statusData, sepStatusData] = useState<any>()
  const [secotrData, sepSecotrData] = useState<any>()
  const [allRequests, setAllRequests] = useState<any>()
  const [clients, setclients] = useState<any>()

  const fetchAssigned = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAssigned()
  }, [fetchAssigned])

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.all()
      const { results } = res.data

      sepPmsData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  const fetchStatuses = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.status.all()
      const { results } = res.data

      sepStatusData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchStatuses()
  }, [fetchStatuses])

  const fetchSector = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.sector.all()
      const { results } = res.data

      sepSecotrData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchSector()
  }, [fetchSector])

  const [types, settypes] = useState<any>()

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  const fetchAllData = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.requests.getList(params)
      const { results } = res.data

      setAllRequests(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAllData()
  }, [fetchAllData])

  const fetchClients = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.clients.all({ page_size: 1000 })
      const { results } = res.data

      setclients(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  //   HUY
  const [profile, setProfile] = useState<any>()

  const fetchProfile = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getProfile()
      const { data } = res

      setProfile(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  const updateRequest = useCallback(
    async (id: string, params?: any) => {
      try {
        const res = await services.user.updateUser(id, params)

        if (res.status === 200) {
          onCloseProfile()
          fetchProfile()
        }
      } catch (e) {
        console.log(e)
      }
    },
    [fetchProfile],
  )

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.department) {
      submitData.append('department', values.department)
    }
    if (values.groups) {
      submitData.append(
        'groups',
        values.groups?.map((n: any) => (n.value ? n.value : n)),
      )
    }
    if (values.working_mode) {
      submitData.append('working_mode', values.working_mode)
    }
    if (values.phone) {
      submitData.append('phone', values.phone)
    }
    if (values.hobbies) {
      submitData.append('hobbies', values.hobbies)
    }
    if (values.bio) {
      submitData.append('bio', values.bio)
    }
    if (values.last_name) {
      submitData.append('last_name', values.last_name)
    }
    if (values.first_name) {
      submitData.append('first_name', values.first_name)
    }
    if (values.email) {
      submitData.append('email', values.email)
    }
    if (values.reporting_manger) {
      submitData.append(
        'reporting_manger',
        values.reporting_manger.value
          ? values.reporting_manger.value
          : values.reporting_manger,
      )
    } else {
      submitData.append('reporting_manger', '')
    }
    submitData.append('is_active', values.is_active)
    if (values.birth_date && datePicker !== undefined)
      submitData.append('birth_date', dayjs(datePicker).format('YYYY-MM-DD'))
    if (rowIcon) {
      submitData.append('avatar', rowIcon)
    }

    updateRequest(profile?.id, submitData)
  }

  const [tls, settls] = useState<any>(null)
  const [pms, setpms] = useState<any>(null)
  const [selctedtls, setselctedtls] = useState<any>(null)

  const fetchTL = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getTL()
      const { results } = res.data

      settls(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTL()
  }, [fetchTL])

  const fetchPM = useCallback(async (data?: any) => {
    try {
      const res = await services.user.getPM({ id: data })
      const { results } = res.data

      setpms(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (selctedtls) {
      fetchPM(selctedtls)
    }
  }, [fetchPM, selctedtls])

  const [newClient, setNewClient] = useState<any>(null)

  const fetchClientsAdd = useCallback(
    async (data: any) => {
      try {
        const res = await services.clients.add(data)

        if (res.status === 200) {
          setNewClient(res.data)
          fetchClients()
        }
      } catch (e: any) {
        message.error(e.response.data.msg)
      }
    },
    [fetchClients],
  )

  return (
    <div>
      <div className="footer">
        <div className="footer__items">
          <Link to="/dashboard" className="footer__item">
            <i
              className={`icon-dashboard ${
                history.location.pathname === '/dashboard' ? 'active' : ''
              }`}
            />
          </Link>
          <Link to="/notify" className="footer__item">
            <i
              className={`icon-bell ${
                history.location.pathname === '/notify' ? 'active' : ''
              }`}
            />
          </Link>
          {(user && user.groups[0].name === 'Business Head') ||
          (user && user.groups[0].name === 'Senior Project Manager') ? (
            <div className="footer__item">
              <i
                className={`icon-x ${open ? 'active' : ''}`}
                onClick={() => {
                  showDrawer()
                }}
              ></i>
            </div>
          ) : (
            ''
          )}
          <Link to="/settings" className="footer__item">
            <i
              className={`icon-settings ${
                history.location.pathname === '/settings' ? 'active' : ''
              }`}
            />
          </Link>
          <div className="footer__item">
            <User
              onClick={() => {
                showDrawerProfile()
              }}
              className={openProfile ? 'active' : ''}
            />
          </div>
        </div>
      </div>
      {open && (
        <SeeMoreNew
          open={open}
          onClose={onClose}
          title="Add request"
          onSubmit={handleSubmitNew}
          datePicker={datePickerNew}
          setdatePicker={setdatePickerNew}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          clientCompany={clientCompany}
          setclientCompany={setclientCompany}
          selectedChargeRate={selectedChargeRate}
          setselectedChargeRate={setselectedChargeRate}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          selectedType={selectedType}
          setselectedType={setselectedType}
          resetForm={resetForm}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
      )}
      {openProfile && (
        <ProfileEdit
          open={openProfile}
          onClose={onCloseProfile}
          onSubmit={handleSubmit}
          data={profile}
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          setRowIcon={setRowIcon}
        />
      )}
    </div>
  )
}

export default TheFooter
