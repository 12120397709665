import React from 'react'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { useAppSelector } from 'src/app/hooks'
interface IProps {
  data: any
}

const ComposedCharts: React.FC<IProps> = ({ data }) => {
  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  return (
    <div className="analytics__composed">
      <div className="analytics__composed-title">Number of projects</div>
      <div
        className="analytics__composed-inner"
        style={{ width: '100%', height: '100%' }}
      >
        <ResponsiveContainer width="100%" height="95%">
          <ComposedChart
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis width={windowWidth && windowWidth > 650 ? 30 : 20} />
            <Tooltip />
            <Bar
              dataKey="value"
              barSize={20}
              fill="#568EFF"
              radius={[10, 10, 0, 0]}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default ComposedCharts
