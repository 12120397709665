import { Button, message, Modal, Select, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { CornerDownRight } from 'react-feather'
import { defaultDateFormat, serverDateFormat } from 'src/app/constants'
import { useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'
import AntTable from 'src/components/common/AntTable'
import services from 'src/services'
import LineHours from './components/LineHours'
import LineTasks from './components/LineTasks'
import RadialTasks from './components/RadialTasks'
import TabsTeam from './components/TabsTeam'
import RequestDetail from 'src/components/common/RequestDetail'
import PipelineDetail from 'src/components/common/PipelineDetail'

const { Option } = Select

const Dashboard = () => {
  const [tableParams, setTableParams] = useState<IPageRequest>({
    page: 1,
    page_size: 10,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingR, setIsLoadingR] = useState(true)
  const [dataCount, setDataCount] = useState({
    count: 0,
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [openRequest, setOpenRequest] = useState(false)
  const [data, setData] = useState<any>()
  const [team, setTeam] = useState<any>()
  const [gapSelect, setGapSelect] = useState('Today')
  const [rowId, setrowId] = useState(null)
  const [rowData, setRowData] = useState<any>(null)
  const [rowDataRequest, setRowDataRequest] = useState<any>(null)
  const windowWidth = useAppSelector((state) => state.windowWidth.data)
  const user = useAppSelector((state) => state.user.data)

  const fetchData = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.dashboard.all(params)

      setData(res.data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (gapSelect === 'Today') {
      fetchData({ time: 1 })
    }
    if (gapSelect === 'Week') {
      fetchData({ time: 7 })
    }
    if (gapSelect === 'Month') {
      fetchData({ time: 30 })
    }
    if (gapSelect === 'Year') {
      fetchData({ time: 365 })
    }
  }, [gapSelect, fetchData])

  const fetchDataTeam = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.pipeline.all(params)
      const { count, results } = res.data

      setTeam(results)
      setDataCount({
        count,
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (
      (user && user.groups[0].name === 'Business Head') ||
      (user && user.groups[0].name === 'Senior Project Manager')
    ) {
      fetchDataTeam({ ...tableParams, assigned: 'False' })
    }
  }, [fetchDataTeam, tableParams, user])

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
    setNewClient(null)
  }

  const showDrawerRequest = () => {
    setOpenRequest(true)
  }

  const onCloseRequest = () => {
    setOpenRequest(false)
    setNewClient(null)
    setselctedtls(null)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    handleDelete()
    setrowId(null)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setrowId(null)
  }

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (client: any) => (
        <div className="table__user">
          <div className="table__user-img">
            {client && client?.icon ? (
              <img src={client?.icon} alt="" />
            ) : client?.name === undefined || client?.surname === undefined ? (
              ''
            ) : (
              client?.name.charAt(0) +
              '' +
              (client?.surname ? client?.surname.charAt(0) : '')
            )}
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>{`${
              client?.name ? client?.name : ''
            } ${client?.surname ? client?.surname : ''}`}</div>
            <div className="table__user-email">{client?.email}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'datetime',
      render: (datetime: any) => (
        <div
          style={{
            padding: '2px 8px',
            background: '#EAF1FF',
            borderRadius: '16px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#568EFF',
            width: 'fit-content',
          }}
        >
          {dayjs(datetime).format(defaultDateFormat)}
        </div>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'datetime',
      render: (datetime: any) => (
        <div
          style={{
            padding: '2px 8px',
            background: '#EAF1FF',
            borderRadius: '16px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#568EFF',
            width: 'fit-content',
          }}
        >
          {dayjs(datetime).subtract(5, 'hour').format('h A')}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Tooltip title="Pick Request">
            <Button
              type="primary"
              className="table-actions__goto"
              onClick={() => {
                showDrawer()
                fetchDataById(rowData.id)
              }}
            >
              <i className="icon-send"></i>
            </Button>
          </Tooltip>
          {user && user.groups[0].name === 'Business Head' ? (
            <Button
              type="primary"
              className="table-actions__more"
              onClick={() => {
                showModal()
                setrowId(rowData.id)
              }}
            >
              <i className="icon-trash-2"></i>
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ]

  const columnsMobile = [
    {
      title: '#ID',
      dataIndex: 'id',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (client: any) => (
        <div className="table__user">
          <div className="table__user-img">
            {client && client.icon ? (
              <img src={client?.icon} alt="" />
            ) : client?.name === undefined || client?.surname === undefined ? (
              ''
            ) : (
              client?.name.charAt(0) + '' + client?.surname.charAt(0)
            )}
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>{`${
              client?.name ? client?.name : ''
            } ${client?.surname ? client?.surname : ''}`}</div>
            <div className="table__user-email">{client?.email}</div>
          </div>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Tooltip title="Pick Request">
            <Button
              type="primary"
              className="table-actions__goto"
              onClick={() => {
                showDrawer()
                fetchDataById(rowData.id)
              }}
            >
              <i className="icon-send"></i>
            </Button>
          </Tooltip>
          {user && user.groups[0].name === 'Business Head' ? (
            <Button
              type="primary"
              className="table-actions__more"
              onClick={() => {
                showModal()
                setrowId(rowData.id)
              }}
            >
              <i className="icon-trash-2"></i>
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ]

  const columnsUser = [
    {
      title: 'Requests ID',
      dataIndex: ['id_code', 'parent'],
      render: (text: any, row: any) => (
        <div style={{ color: '#101828', fontWeight: '500' }}>
          {row['id_code']} <br />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row['parent'] !== null && row['parent'] !== null ? (
              <CornerDownRight
                style={{
                  color: '#667085',
                }}
              />
            ) : (
              ''
            )}
            <span
              style={{
                marginTop: '4px',
              }}
            >
              {row['parent']}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (client: any) => (
        <div className="table__user">
          <div className="table__user-img">
            {client && client.icon ? (
              <img src={client?.icon} alt="" />
            ) : client?.name === undefined || client?.surname === undefined ? (
              ''
            ) : (
              client?.name.charAt(0) + '' + client?.surname.charAt(0)
            )}
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>{`${
              client?.name ? client?.name : ''
            } ${client?.surname ? client?.surname : ''}`}</div>
            <div className="table__user-email">{client?.email}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: any) => (
        <div className={`table__user-status ${status?.name}`}>
          {status?.name}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              showDrawerRequest()
              fetchDataByIdRequest(rowData.id)
            }}
          >
            <i className="icon-eye"></i>
          </Button>
        </div>
      ),
    },
  ]

  const columnsMobileUser = [
    {
      title: 'Requests ID',
      dataIndex: ['id_code', 'parent'],
      render: (text: any, row: any) => (
        <div style={{ color: '#101828', fontWeight: '500' }}>
          {row['id_code']} <br />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row['parent'] !== null && row['parent'] !== null ? (
              <CornerDownRight
                style={{
                  color: '#667085',
                }}
              />
            ) : (
              ''
            )}
            <span
              style={{
                marginTop: '4px',
              }}
            >
              {row['parent']}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              showDrawerRequest()
              fetchDataByIdRequest(rowData.id)
            }}
          >
            <i className="icon-eye"></i>
          </Button>
        </div>
      ),
    },
  ]

  const [requests, setrequests] = useState<any>()

  const fetchRequest = useCallback(async (params?: IPageRequest) => {
    setIsLoadingR(true)
    try {
      const res = await services.requests.getList(params)
      const { count, results } = res.data

      setrequests(results)
      setDataCount({
        count,
      })
      setIsLoadingR(false)
    } catch (e) {
      setIsLoadingR(false)
      console.log(e)
    }
  }, [])

  const [allRequests, setAllRequests] = useState<any>()

  const fetchAllData = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.requests.getList(params)
      const { results } = res.data

      setAllRequests(results)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAllData()
  }, [fetchAllData])

  useEffect(() => {
    if (
      (user && user.groups[0].name !== 'Business Head') ||
      (user && user.groups[0].name !== 'Senior Project Manager')
    ) {
      fetchRequest({ ...tableParams, search_user: user.id, default_today: 1 })
    }
  }, [tableParams, fetchRequest, user])

  const handleTableChange = (params: IPageRequest) => {
    setTableParams((prev) => ({
      ...prev,
      ...params,
    }))
  }

  const handleDelete = async () => {
    if (!rowId) return

    try {
      const res = await services.user.deletePipeline(rowId)

      if (res.status === 204) {
        fetchDataTeam({ ...tableParams, assigned: 'False' })
        setIsModalOpen(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchDataById = async (id: string) => {
    setIsLoading(true)
    try {
      const res = await services.pipeline.allDetail(id)
      const { data } = res

      setRowData(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const createRequest = useCallback(
    async (params?: any) => {
      setIsLoading(true)

      try {
        const response = await services.requests.postRequests(params)

        if (response.status === 201 && rowData?.id) {
          const deleteResponse = await services.user.deletePipeline(rowData.id)

          if (deleteResponse.status === 204) {
            fetchDataTeam({ ...tableParams, assigned: 'False' })
            onClose()
            fetchData()
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [fetchData, rowData, tableParams],
  )

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.company) {
      submitData.append('company', values.company)
    }
    if (values.subject) {
      submitData.append('subject', values.subject)
    }
    if (values.casecode) {
      submitData.append('casecode', values.casecode)
    }

    submitData.append(
      'datetime',
      values.datetime &&
        dayjs(datePicker).format('YYYY-MM-DD') +
          'T' +
          dayjs(timePicker).format('HH:mm:ss'),
    )
    submitData.append('id', rowData && rowData.id)
    submitData.append(
      'complation_date',
      values.complation_date &&
        dayjs(complationPicker).format(serverDateFormat),
    )
    if (values.assigned) {
      submitData.append(
        'assigned',
        values.assigned?.map((n: any) => (n.value ? n.value : n)),
      )
    }
    if (values.pm) {
      submitData.append('pm', values.pm.value ? values.pm.value : values.pm)
    }
    submitData.append(
      'senior_pm',
      values.senior_pm.value ? values.senior_pm.value : values.senior_pm,
    )
    if (rowData.client) {
      submitData.append('client', rowData && rowData.client?.id)
    }
    if (values.parent) {
      submitData.append(
        'parent',
        values.parent?.value === 'null'
          ? ''
          : values.parent?.value !== 'null'
          ? values.parent?.value
          : values.parent,
      )
    }
    if (values.status !== undefined) {
      submitData.append(
        'status',
        values.status?.value ? values.status.value : values.status,
      )
    }
    if (values.name !== null) {
      submitData.append('name', values.name)
    }
    submitData.append('estimated_hours', values.estimated_hours)
    submitData.append('charge_rate', values.charge_rate)
    if (values.type_of_work !== undefined) {
      submitData.append(
        'type_of_work',
        values.type_of_work?.value
          ? values.type_of_work.value
          : values.type_of_work,
      )
    }
    if (values.sector !== null) {
      submitData.append(
        'sector',
        values.sector.value ? values.sector.value : values.sector,
      )
    }

    createRequest(submitData)
  }

  const [profile, setProfile] = useState<any>()

  const fetchProfile = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getProfile()
      const { data } = res

      setProfile(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  const [pendingNotif, setPendingNotif] = useState(false)
  const [blue, setblue] = useState<any>(null)
  const [red, setRed] = useState<any>(null)
  const [orange, setorange] = useState<any>(null)
  const [green, setgreen] = useState<any>(null)

  useEffect(() => {
    if (profile) {
      const websocket = new WebSocket(
        `wss://api.penguin.exadot.io/ws/notifications/room_${profile?.id}/`,
      )

      websocket.onopen = () => {
        console.log('connected')
      }

      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data)
        if (data.message.type === 'pipeline') {
          setblue(data.message)
        }
        if (data.message.type === 'errors') {
          setRed(data.message)
        }
        if (data.message.type === 'user-log-out') {
          setorange(data.message)
        }
        if (data.message.type === 'project') {
          setgreen(data.message)
        }
      }

      return () => {
        websocket.close()
      }
    }
  }, [profile])

  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [clientCompany, setclientCompany] = useState<any>(null)
  const [selectedStatus, setselectedStatus] = useState<any>(null)
  const [selectedAssigned, setSelectedAssigned] = useState<any>(null)
  const [selectedChargeRate, setselectedChargeRate] = useState<any>(null)
  const [selectedType, setselectedType] = useState<any>(null)
  const [datePicker, setdatePicker] = useState<any>()
  const [complationPicker, setcomplationPicker] = useState<any>()
  const [timePicker, settimePicker] = useState<any>()
  const [pmsData, sepPmsData] = useState<any>()
  const [statusData, sepStatusData] = useState<any>()
  const [secotrData, sepSecotrData] = useState<any>()
  const [assignedData, setassignedData] = useState<any>()

  const [selectedClientPipeline, setSelectedClientPipeline] =
    useState<any>(null)
  const [selectedStatusPipeline, setselectedStatusPipeline] =
    useState<any>(null)
  const [selectedAssignedPipeline, setSelectedAssignedPipeline] =
    useState<any>(null)
  const [clientCompanyPipeline, setclientCompanyPipeline] = useState<any>(null)
  const [selectedChargeRatePipeline, setselectedChargeRatePipeline] =
    useState<any>(null)
  const [selectedTypePipeline, setselectedTypePipeline] = useState<any>(null)
  const [tls, settls] = useState<any>(null)
  const [pms, setpms] = useState<any>(null)
  const [selctedtls, setselctedtls] = useState<any>(null)

  const fetchTL = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getTL()
      const { results } = res.data

      settls(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTL()
  }, [fetchTL])

  const fetchPM = useCallback(async (data?: any) => {
    try {
      const res = await services.user.getPM({ id: data })
      const { results } = res.data

      setpms(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (selctedtls) {
      fetchPM(selctedtls)
    }
  }, [fetchPM, selctedtls])

  const fetchAssigned = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAssigned()
  }, [fetchAssigned])

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.all()
      const { results } = res.data

      sepPmsData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  const fetchStatuses = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.status.all()
      const { results } = res.data

      sepStatusData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchStatuses()
  }, [fetchStatuses])

  const fetchSector = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.sector.all()
      const { results } = res.data

      sepSecotrData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchSector()
  }, [fetchSector])

  const [types, settypes] = useState<any>()

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  const [clients, setClients] = useState<any>(null)

  const fetchClients = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.clients.all({ page_size: 1000 })
      const { results } = res.data

      setClients(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  const fetchDataByIdRequest = async (id: string) => {
    setIsLoading(true)
    try {
      const res = await services.requests.getDetail(id)
      const { data } = res

      setRowDataRequest(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const [newClient, setNewClient] = useState<any>(null)

  const fetchClientsAdd = useCallback(
    async (data: any) => {
      try {
        const res = await services.clients.add(data)

        if (res.status === 200) {
          setNewClient(res.data)
          fetchClients()
        }
      } catch (e: any) {
        message.error(e.response.data.msg)
      }
    },
    [fetchClients],
  )

  return (
    <div className="dashboard">
      <div className="">
        {blue || red || orange || green ? (
          <div className="dashboard__pending">
            <div
              className={`dashboard__pending-items ${
                pendingNotif ? 'active' : ''
              }`}
            >
              {blue && (
                <div
                  className="dashboard__pending-item"
                  style={{ background: 'rgba(0, 133, 255, 0.12)' }}
                >
                  <div className="dashboard__pending-item-left">
                    <i
                      className="icon-paperclip"
                      style={{ color: '#568EFF' }}
                    ></i>
                    <span style={{ color: '#568EFF' }}>{blue.msg}</span>
                  </div>
                  <div className="dashboard__pending-item-close">
                    <i
                      className="icon-x"
                      style={{ color: '#568EFF', cursor: 'pointer' }}
                    ></i>
                  </div>
                </div>
              )}
              {red && (
                <div
                  className="dashboard__pending-item"
                  style={{ background: 'rgba(234, 84, 85, 0.12)' }}
                >
                  <div className="dashboard__pending-item-left">
                    <i
                      className="icon-alert-circle"
                      style={{ color: '#EA5455' }}
                    ></i>
                    <span style={{ color: '#EA5455' }}>{red.msg}</span>
                  </div>
                  <div className="dashboard__pending-item-close">
                    <i
                      className="icon-x"
                      style={{ color: '#EA5455', cursor: 'pointer' }}
                    ></i>
                  </div>
                </div>
              )}
              {orange && (
                <div
                  className="dashboard__pending-item"
                  style={{ background: 'rgba(255, 202, 68, 0.12)' }}
                >
                  <div className="dashboard__pending-item-left">
                    <i
                      className="icon-hard-drive"
                      style={{ color: '#FF9F43' }}
                    ></i>
                    <span style={{ color: '#FF9F43' }}>{orange.msg}</span>
                  </div>
                  <div className="dashboard__pending-item-close">
                    <i
                      className="icon-x"
                      style={{ color: '#FF9F43', cursor: 'pointer' }}
                    ></i>
                  </div>
                </div>
              )}
              {green && (
                <div
                  className="dashboard__pending-item"
                  style={{ background: 'rgba(40, 199, 111, 0.12)' }}
                >
                  <div className="dashboard__pending-item-left">
                    <i
                      className="icon-hard-drive"
                      style={{ color: '#00CB65' }}
                    ></i>
                    <span style={{ color: '#00CB65' }}>{green.msg}</span>
                  </div>
                  <div className="dashboard__pending-item-close">
                    <i
                      className="icon-x"
                      style={{ color: '#00CB65', cursor: 'pointer' }}
                      onClick={() => {
                        setgreen(null)
                      }}
                    ></i>
                  </div>
                </div>
              )}
            </div>
            <div className="dashboard__pending-btn">
              <i className="icon-chevron-down"></i>
            </div>
          </div>
        ) : (
          ''
        )}
        <div
          className="dashboard__top"
          style={blue || red || orange || green ? {} : { marginTop: '0' }}
        >
          <div className="dashboard__title page-title">Dashboard</div>
          {windowWidth && windowWidth < 650 ? (
            <i
              className="icon-paperclip"
              style={{
                color: '#9092A7',
                fontSize: '24px',
                position: 'absolute',
                top: '27px',
                right: '66px',
              }}
              onClick={() => {
                pendingNotif ? setPendingNotif(false) : setPendingNotif(true)
              }}
            ></i>
          ) : (
            ''
          )}
          <div className="dashboard__today">
            {windowWidth && windowWidth > 650 ? (
              <Select
                onChange={(e) => {
                  setGapSelect(e)
                }}
                defaultValue={gapSelect}
                className="gap-select"
                bordered={false}
                suffixIcon={
                  <i
                    className="icon-chevron-down"
                    style={{
                      fontSize: '20px',
                      color: '#9092A7',
                      marginLeft: '12px',
                    }}
                  ></i>
                }
              >
                <Option value="Today">Today</Option>
                <Option value="Week">Week</Option>
                <Option value="Month">Month</Option>
                <Option value="Year">Year</Option>
              </Select>
            ) : (
              <div className="dashboard__today-mobile">
                <span
                  onClick={() => {
                    setGapSelect('Today')
                  }}
                  className={gapSelect === 'Today' ? 'active' : ''}
                >
                  Today
                </span>
                <span
                  onClick={() => {
                    setGapSelect('Month')
                  }}
                  className={gapSelect === 'Month' ? 'active' : ''}
                >
                  Month
                </span>
                <span
                  onClick={() => {
                    setGapSelect('Year')
                  }}
                  className={gapSelect === 'Year' ? 'active' : ''}
                >
                  Year
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="dashboard__inner">
          <div className="dashboard__left">
            <div className="dashboard__left-top">
              <LineHours data={data && data.data} total={data && data.this} />
              <LineTasks data={data && data.data} total={data && data.this} />
            </div>
            <div
              style={{
                fontWeight: '500',
                fontSize: '18px',
                lineHeight: '22px',
                color: '#5e5873',
                marginBottom: '10px',
              }}
            >
              {(user && user.groups[0].name === 'Business Head') ||
              (user && user.groups[0].name === 'Senior Project Manager')
                ? 'Pipeline'
                : 'Requests'}
            </div>
            {(user && user.groups[0].name === 'Business Head') ||
            (user && user.groups[0].name === 'Senior Project Manager') ? (
              <AntTable
                // rowKey={(record: any) => record.id}
                data={team}
                columns={
                  windowWidth && windowWidth > 500 ? columns : columnsMobile
                }
                loading={isLoading}
                rowsCount={dataCount.count}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                filterColumns={['lotId']}
                withArrow={windowWidth && windowWidth < 992 ? true : false}
              />
            ) : (
              <AntTable
                // rowKey={(record: any) => record.id}
                data={requests}
                columns={
                  windowWidth && windowWidth > 500
                    ? columnsUser
                    : columnsMobileUser
                }
                loading={isLoadingR}
                rowsCount={dataCount.count}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                filterColumns={['lotId']}
                withArrow={windowWidth && windowWidth < 992 ? true : false}
              />
            )}
          </div>
          <div className="dashboard__right">
            <RadialTasks data={data && data.assigned_tasks} />
            <TabsTeam />
          </div>
        </div>
      </div>
      {!isLoading && (
        <RequestDetail
          open={openRequest}
          onClose={onCloseRequest}
          title="More Info"
          rowData={rowDataRequest}
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          clientCompany={clientCompany}
          setclientCompany={setclientCompany}
          selectedStatus={selectedStatus}
          setselectedStatus={setselectedStatus}
          selectedAssigned={selectedAssigned}
          setSelectedAssigned={setSelectedAssigned}
          selectedChargeRate={selectedChargeRate}
          setselectedChargeRate={setselectedChargeRate}
          selectedType={selectedType}
          setselectedType={setselectedType}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
      )}
      <Modal
        title={<i className="icon-pen"></i>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal__custom"
        width={400}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Confirm
          </Button>,
        ]}
      >
        <div className="modal__custom-title">Delete request</div>
        <div className="modal__custom-subtitle">
          Are you sure you want to delete this request?
        </div>
      </Modal>
      {rowData && (
        <PipelineDetail
          onSubmit={handleSubmit}
          rowData={rowData}
          open={open}
          onClose={onClose}
          title="Pick Request"
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          selectedClient={
            selectedClientPipeline ? selectedClientPipeline : rowData.client?.id
          }
          setSelectedClient={setSelectedClientPipeline}
          selectedStatus={selectedStatusPipeline}
          setselectedStatus={setselectedStatusPipeline}
          selectedAssigned={selectedAssignedPipeline}
          setSelectedAssigned={setSelectedAssignedPipeline}
          clientCompany={clientCompanyPipeline}
          setclientCompany={setclientCompanyPipeline}
          selectedChargeRate={selectedChargeRatePipeline}
          setselectedChargeRate={setselectedChargeRatePipeline}
          selectedType={selectedTypePipeline}
          setselectedType={setselectedTypePipeline}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
      )}
    </div>
  )
}

export default Dashboard
