import { Tabs } from 'antd'
import React from 'react'
import { useAppSelector } from 'src/app/hooks'
import Clients from '../Clients/Clients'
import Employees from '../Employees/Employees'
import General from '../General/General'
import Notifications from '../Notifications/Notifications'

const Settings = () => {
  const windowWidth = useAppSelector((state) => state.windowWidth.data)
  const user = useAppSelector((state) => state.user.data)

  return (
    <div className="settings">
      <div className="settings__title">Settings</div>
      <div className="settings__body">
        <div className="settings__tabs">
          <Tabs tabPosition={windowWidth && windowWidth > 992 ? 'left' : 'top'}>
            {user && user.groups[0].name === 'Business Head' ? (
              <Tabs.TabPane tab="General" key="item-1">
                <General />
              </Tabs.TabPane>
            ) : (
              ''
            )}
            {(user && user.groups[0].name === 'Business Head') ||
            (user && user.groups[0].name === 'Senior Project Manager') ? (
              <Tabs.TabPane tab="Employees" key="item-2">
                <Employees />
              </Tabs.TabPane>
            ) : (
              ''
            )}
            {(user && user.groups[0].name === 'Business Head') ||
            (user && user.groups[0].name === 'Senior Project Manager') ? (
              <Tabs.TabPane tab="Company" key="item-3">
                <Clients />
              </Tabs.TabPane>
            ) : (
              ''
            )}
            <Tabs.TabPane tab="Notifications" key="item-4">
              <Notifications />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default Settings
