import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class Analytics extends BaseApi {
  async getUsers(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/analytics/${query}`)
  }
  async getDevision(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/analytics/devision/${query}`)
  }
  async getProjects(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/analytics/projects/${query}`)
  }
  async getSectors(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/analytics/sector/${query}`)
  }
  async getWorks(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/analytics/works/${query}`)
  }
  async getClients(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/analytics/clients/${query}`)
  }
}

export default Analytics
