import { Button, Checkbox } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { IPageRequest } from 'src/app/models/page'
import services from 'src/services'

const Notifications = () => {
  const [data, setData] = useState<any>()
  const [project, setproject] = useState<boolean>()
  const [pending, setpending] = useState<boolean>()
  const [errors, seterrors] = useState<boolean>()
  const [logout, setlogout] = useState<boolean>()

  useEffect(() => {
    if (data) {
      setproject(data.notif_new_project)
      setpending(data.notif_pinding_project)
      seterrors(data.errors)
      setlogout(data.log_out)
    }
  }, [data])

  const fetchData = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.settings.get()
      const { data } = res

      setData(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const updateRequest = useCallback(async (params?: any) => {
    try {
      const res = await services.settings.update(params)
      console.log(res)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const handleSubmit = () => {
    const submitData = new FormData()
    submitData.append('notif_new_project', project as any)
    submitData.append('notif_pinding_project', pending as any)
    submitData.append('errors', errors as any)
    submitData.append('log_out', logout as any)

    updateRequest(submitData)
  }

  return (
    <div className="notifications">
      <div className="notifications__title">Notifications</div>
      <div className="notifications__subtitle">
        We need permission from your browser to show notifications. Request
        permission
      </div>
      <div className="notifications__head">
        <span>Type</span>
        <span>ACTION</span>
      </div>
      <div className="notifications__items">
        <div className="notifications__item">
          <div className="notifications__item-text">
            New project in pipeline
          </div>
          <div className="notifications__item-btn">
            <Checkbox
              onChange={(e) => {
                setproject(e.target.checked)
              }}
              value={project}
              checked={project}
            />
          </div>
        </div>
        <div className="notifications__item">
          <div className="notifications__item-text">
            Pending projects to requests
          </div>
          <div className="notifications__item-btn">
            <Checkbox
              onChange={(e) => {
                setpending(e.target.checked)
              }}
              value={pending}
              checked={pending}
            />
          </div>
        </div>
        <div className="notifications__item">
          <div className="notifications__item-text">Errors</div>
          <div className="notifications__item-btn">
            <Checkbox
              onChange={(e) => {
                seterrors(e.target.checked)
              }}
              value={errors}
              checked={errors}
            />
          </div>
        </div>
        <div className="notifications__item">
          <div className="notifications__item-text">User wanted log out</div>
          <div className="notifications__item-btn">
            <Checkbox
              onChange={(e) => {
                setlogout(e.target.checked)
              }}
              value={logout}
              checked={logout}
            />
          </div>
        </div>
      </div>
      <div className="notifications__btns">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={{
              width: '95px',
              height: '44px',
              border: '1px solid #9092A7',
              background: '#fff',
              borderRadius: '12px',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#9092A7',
            }}
          >
            Discard
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            onClick={handleSubmit}
            style={{
              width: '143px',
              borderRadius: '12px',
              height: '44px',
              marginLeft: '17px',
            }}
            className="login__btn"
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Notifications
