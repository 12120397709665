import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class Clients extends BaseApi {
  async all(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/projects/clients/${query}`)
  }
  async add(data: any) {
    return await this.api.post(`api/v1/projects/create-client/`, data)
  }
  async getCompany(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/projects/clients/get_companies/${query}`)
  }
  async allDetails(id: string) {
    return await this.api.get(`api/v1/projects/clients/${id}/`)
  }
  async delete(id: string) {
    return await this.api.delete(`api/v1/projects/clients/${id}/`)
  }
  async updateUser(id: string, data?: any) {
    return await this.api.put(`api/v1/projects/clients/${id}/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
  async post(data?: any) {
    return await this.api.post(`api/v1/projects/clients/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

export default Clients
