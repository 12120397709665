import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { IPageRequest } from 'src/app/models/page'
import AntTable from 'src/components/common/AntTable'
import services from 'src/services'

const TabsTeam: React.FC = () => {
  const [unassignedTableParams, setUnassignedTableParams] =
    useState<IPageRequest>({
      page: 1,
      page_size: 4,
    })

  const [freeTimeTableParams, setFreeTimeTableParams] = useState<IPageRequest>({
    page: 1,
    page_size: 4,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setactiveTab] = useState(1)

  const [teamUnassigned, setTeamUnassigned] = useState<any>()
  const [teamGettingFree, setTeamGettingFree] = useState<any>()

  const fetchDataTeam = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.team.all(params)
      const { count, results } = res.data

      setTeamUnassigned({ results, count })

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  const fetchGettingFree = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.team.gettingFree(params)
      const { results, count } = res.data

      const filteredData = results.filter(
        (res: any) => res.free_time !== null || undefined || '',
      )

      setTeamGettingFree({
        count,
        results: filteredData,
      })

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (activeTab === 1) {
      fetchDataTeam({ ...unassignedTableParams, assigned: 'False' })
    } else {
      fetchGettingFree({ ...freeTimeTableParams })
    }
  }, [fetchGettingFree, unassignedTableParams, freeTimeTableParams, activeTab])

  const handleTableChange = (params: IPageRequest) => {
    if (activeTab === 1) {
      setUnassignedTableParams((prev) => ({
        ...prev,
        ...params,
      }))
    } else {
      setFreeTimeTableParams((prev) => ({
        ...prev,
        ...params,
      }))
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'profile',
      key: 'profile',
      render: (profile: any) => (
        <div className="table__user">
          <div>
            <div className="table__user-img">
              <img src={profile?.avatar} alt="" />
            </div>
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>
              {profile?.first_name ? profile?.first_name : ''}
              {profile?.last_name ? profile?.last_name : ''}
            </div>
            {/* <div className="table__user-email">{profile?.email}</div> */}
          </div>
        </div>
      ),
    },
    {
      title: 'Since',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (start_time: string) => (
        <div
          style={{
            background: '#F9F5FF',
            borderRadius: '16px',
            minWidth: '51px',
            width: 'fit-content',
            height: '22px',
            padding: '2px 8px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#6941C6',
          }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {start_time
              ? // start_time.split(',')[0] +
                //   ' ' +
                //   start_time.split(' ').pop().split(':').shift() +
                //   ':' +
                //   start_time.substring(
                //     start_time.indexOf(':') + 1,
                //     start_time.lastIndexOf(':'),
                //   )
                start_time.includes(',')
                ? start_time.split(',')[0] +
                  ' ' +
                  start_time
                    .split(',')[1]
                    .slice(0, 6)
                    .split(':')
                    .slice(0, 2)
                    .join(':')
                : start_time.slice(0, 5).split(':').slice(0, 2).join(':')
              : ''}
          </div>
        </div>
      ),
    },
  ]

  const columns2 = [
    {
      title: 'Name',
      dataIndex: 'profile',
      key: 'profile',
      render: (profile: any) => (
        <div className="table__user">
          <div>
            <div className="table__user-img">
              <img src={profile?.avatar} alt="" />
            </div>
          </div>
          <div className="table__user-info">
            <div className="table__user-name" style={{ color: '#101828' }}>
              {profile?.first_name ? profile?.first_name : ''}
              {profile?.last_name ? profile?.last_name : ''}
            </div>
            {/* <div className="table__user-email">{profile?.email}</div> */}
          </div>
        </div>
      ),
    },
    {
      title: 'Got free time',
      dataIndex: 'free_time',
      render: (free_time: string) => (
        <div
          style={{
            background: '#F9F5FF',
            borderRadius: '16px',
            width: 'fit-content',
            height: '22px',
            padding: '2px 8px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#6941C6',
          }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {free_time}
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className="dashboard__tabs" style={{ overflowY: 'auto' }}>
      <div className="dashboard__tabs-switcher">
        <div className="dashboard__tabs-switcher-btns">
          <button
            className={`dashboard__tabs-switcher-btn ${
              activeTab === 1 ? 'active' : ''
            }`}
            onClick={() => {
              setactiveTab(1)
            }}
          >
            Unassigned
          </button>
          <button
            className={`dashboard__tabs-switcher-btn ${
              activeTab === 2 ? 'active' : ''
            }`}
            onClick={() => {
              setactiveTab(2)
            }}
          >
            Getting free
          </button>
        </div>
      </div>
      <div className="dashboard__tabs-top">
        <div className="dashboard__tabs-top-title">
          {activeTab === 1 ? 'Unassigned team' : "Who's getting free?"}
        </div>
        <div className="dashboard__tabs-top-members">
          {activeTab === 1 ? teamUnassigned?.count : teamGettingFree?.count}{' '}
          members
        </div>
      </div>
      <AntTable
        // rowKey={(record: any) => record.id}
        data={
          activeTab === 1 ? teamUnassigned?.results : teamGettingFree?.results
        }
        columns={activeTab === 1 ? columns : columns2}
        loading={isLoading}
        rowsCount={
          activeTab === 1 ? teamUnassigned?.count : teamGettingFree?.count
        }
        tableParams={
          activeTab === 1 ? unassignedTableParams : freeTimeTableParams
        }
        onTableChange={handleTableChange}
        filterColumns={['lotId']}
        withArrow={true}
      />
    </div>
  )
}

export default TabsTeam
