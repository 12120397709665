import { getDecodedJwt } from 'src/utils/auth'

const roleKey = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'

export const roles = {
  user: 'User',
  pm: 'PM',
  senior: 'Senior',
}

export const IsUser = () => {
  return getDecodedJwt()[roleKey] === roles.user
}

export const IsPM = () => {
  return getDecodedJwt()[roleKey] === roles.pm
}

export const IsSenior = () => {
  return getDecodedJwt()[roleKey] === roles.senior
}

export default function getRole() {
  return getDecodedJwt()[roleKey]
}
