import React, { useEffect, useState } from 'react'
import { Layout, Form, Input, Button, message } from 'antd'
import signIn from 'src/services/signIn'
import { useAppDispatch } from 'src/app/hooks'
import { setAccessAndUser } from 'src/features/authSlice'
import { LS_ACCESS } from 'src/app/constants'
import { useHistory } from 'react-router-dom'
import loginbg from 'src/resources/images/LoginBg.png'
import microsoft from 'src/resources/images/microsoft.svg'

const SignIn: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    if (localStorage.getItem(LS_ACCESS)) history.push('/')
  }, [history])

  const handleSubmit = async (values: {
    username: string
    password: string
  }) => {
    const { username, password } = values
    setIsLoading(true)
    try {
      const { data } = await signIn.signIn({ username, password })

      // extract access and user data
      const access = { ...data }
      delete access.user

      // set extracted data
      localStorage.setItem(LS_ACCESS, JSON.stringify(access))
      dispatch(setAccessAndUser(access))

      setIsLoading(false)
      if (access && access.is_register === true) {
        history.push('/register')
      }
      if (access && access.is_register === false) {
        history.push('/dashboard')
      }
    } catch (e: any) {
      setIsLoading(false)
      if (e) {
        message.error(e.response.data.message)
      }
      console.log(e)
    }
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fff',
      }}
      className="login"
    >
      <div className="login__info-wrapper">
        <div
          className="login__info-wrapper-inner"
          style={{ maxWidth: '343px' }}
        >
          <h1 className="login__title">Welcome to Penguin Int! 👋🏻</h1>
          <h2 className="login__subtitle">
            Please sign-in to your account and start the adventure
          </h2>
          <Form
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            style={{ maxWidth: 450, width: '100%' }}
            className="login__form"
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Login' as string }]}
              label="Email"
            >
              <Input
                size="large"
                placeholder="Login"
                disabled={isLoading}
                autoFocus
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Password' }]}
              label="Password"
            >
              <Input size="large" type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item style={{ marginTop: 20 }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: '100%' }}
                loading={isLoading}
                className="login__btn"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
          <a
            href="https://manage.penguin-international.com/accounts/microsoft/login/"
            className="login__btn-microsoft"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={microsoft} alt="" />
            Sign in with Microsoft
          </a>
        </div>
      </div>
      <div className="login__info-img signin-page">
        <img src={loginbg} alt="" />
      </div>
    </Layout>
  )
}

export default SignIn
