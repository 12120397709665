import { Button, Drawer, Form, Layout, Menu, Modal, Space, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { FolderMinus } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'
import { LS_ACCESS, serverDateFormat } from 'src/app/constants'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'
import pageTitles from 'src/app/pageTitles'
import { signOut } from 'src/features/authSlice'
import logo from 'src/resources/images/logo.png'
import services from 'src/services'
import DatePicker from '../common/DatePicker'
import SeeMoreNew from '../common/SeeMoreNew'

const { Sider } = Layout

interface IProps {
  activated: boolean
  setActivated: any
}

const TheSidebar: React.FC<IProps> = ({ activated, setActivated }) => {
  const [collapsed, setCollapsed] = useState(false)
  const access = useAppSelector((state) => state.auth.access)
  const hasAccess = localStorage.getItem(LS_ACCESS) || access
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [openLeave, setOpenLeave] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const windowWidth = useAppSelector((state) => state.windowWidth.data)
  const user = useAppSelector((state) => state.user.data)
  const [form] = Form.useForm()

  const [datePicker, setdatePicker] = useState<any>()
  const [timePicker, settimePicker] = useState<any>()
  const [complationPicker, setcomplationPicker] = useState<any>()

  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [clientCompany, setclientCompany] = useState<any>(null)
  const [selectedChargeRate, setselectedChargeRate] = useState<any>(null)
  const [selectedType, setselectedType] = useState<any>(null)

  const showDrawer = () => {
    setOpen(true)
    setresetForm(false)
  }

  const onClose = () => {
    setOpen(false)
    setselectedChargeRate(null)
    setselectedType(null)
    setSelectedClient(null)
    setclientCompany(null)
    setNewClient(null)
  }

  const showDrawerLeave = () => {
    setOpenLeave(true)
  }

  const onCloseLeave = () => {
    setOpenLeave(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    dispatch(signOut())
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const [resetForm, setresetForm] = useState(false)

  const updateRequest = useCallback(async (params?: any) => {
    try {
      const res = await services.requests.postRequests(params)

      if (res.status === 201) {
        setresetForm(true)
        onClose()
        setdatePicker(undefined)
        settimePicker(undefined)
        setcomplationPicker(undefined)
        setselectedChargeRate(null)
        setselectedType(null)
        setSelectedClient(null)
        setclientCompany(null)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  const [datePickerStart, setdatePickerStart] = useState<any>(null)
  const [datePickerEnd, setdatePickerEnd] = useState<any>(null)

  const leaveRequest = useCallback(async (params?: any) => {
    try {
      const res = await services.requests.leaveRequest(params)

      if (res.status === 200) {
        onCloseLeave()
        setdatePickerStart(null)
        setdatePickerEnd(null)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  const handleSubmitLeave = (values: any) => {
    let submitData = { ...values }

    if (datePickerStart !== null) {
      submitData.start_date = dayjs(datePickerStart)
        .startOf('day')
        .format('YYYY-MM-DD')
    }
    if (datePickerEnd !== null) {
      submitData.end_date = dayjs(datePickerEnd)
        .startOf('day')
        .format('YYYY-MM-DD')
    }

    leaveRequest(submitData)
  }

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.company) {
      submitData.append('company', values.company)
    }
    if (values.subject) {
      submitData.append('subject', values.subject)
    }
    if (values.casecode) {
      submitData.append('casecode', values.casecode)
    }

    submitData.append(
      'datetime',
      values.datetime &&
        dayjs(datePicker).format('YYYY-MM-DD') +
          'T' +
          dayjs(timePicker).format('HH:mm:ss'),
    )
    submitData.append(
      'complation_date',
      values.complation_date &&
        dayjs(complationPicker).format(serverDateFormat),
    )
    if (values.assigned ) {
      submitData.append(
        'assigned',
        values.assigned?.map((n: any) => (n.value ? n.value : n)),
      )

    }
    if (values.pm) {
      submitData.append('pm', values.pm.value ? values.pm.value : values.pm)

    }
    submitData.append(
      'senior_pm',
      values.senior_pm.value ? values.senior_pm.value : values.senior_pm,
    )
    submitData.append(
      'client',
      values.client.value ? values.client.value : values.client,
    )
    if (values.parent) {
      submitData.append(
        'parent',
        values.parent?.value === 'null'
          ? ''
          : values.parent?.value !== 'null'
          ? values.parent?.value
          : values.parent,
      )
    }
    if (values.status !== undefined) {
      submitData.append(
        'status',
        values.status?.value ? values.status.value : values.status,
      )
    }
    // if (values.client !== undefined) {
    //   submitData.append(
    //     'client',
    //     values.client?.value ? values.status.value : values.status,
    //   )
    //   console.log(values.client)
    // }
    submitData.append('estimated_hours', values.estimated_hours)
    submitData.append('charge_rate', values.charge_rate)
    if (values.type_of_work !== undefined) {
      submitData.append(
        'type_of_work',
        values.type_of_work?.value
          ? values.type_of_work.value
          : values.type_of_work,
      )
    }
    if (values.sector !== null) {
      submitData.append(
        'sector',
        values.sector.value ? values.sector.value : values.sector,
      )
    }

    updateRequest(submitData)
  }

  const [assignedData, setassignedData] = useState<any>()
  const [pmsData, sepPmsData] = useState<any>()
  const [statusData, sepStatusData] = useState<any>()
  const [secotrData, sepSecotrData] = useState<any>()
  const [allRequests, setAllRequests] = useState<any>()
  const [clients, setclients] = useState<any>()

  const fetchAssigned = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAssigned()
  }, [fetchAssigned])

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.all()
      const { results } = res.data

      sepPmsData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  const fetchStatuses = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.status.all()
      const { results } = res.data

      sepStatusData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchStatuses()
  }, [fetchStatuses])

  const fetchSector = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.sector.all()
      const { results } = res.data

      sepSecotrData(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchSector()
  }, [fetchSector])

  const [types, settypes] = useState<any>()

  const fetchTypes = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.types.all()
      const { results } = res.data

      settypes(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTypes()
  }, [fetchTypes])

  const fetchAllData = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.requests.getList(params)
      const { results } = res.data

      setAllRequests(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchAllData()
  }, [fetchAllData])

  const fetchClients = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.clients.all({ page_size: 1000 })
      const { results } = res.data

      setclients(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  useEffect(() => {
    if (activated) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [activated])

  const [tls, settls] = useState<any>(null)
  const [pms, setpms] = useState<any>(null)
  const [selctedtls, setselctedtls] = useState<any>(null)

  const fetchTL = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.user.getTL()
      const { results } = res.data

      settls(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchTL()
  }, [fetchTL])

  const fetchPM = useCallback(async (data?: any) => {
    try {
      const res = await services.user.getPM({ id: data })
      const { results } = res.data

      setpms(results)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (selctedtls) {
      fetchPM(selctedtls)
    }
  }, [fetchPM, selctedtls])

  const [newClient, setNewClient] = useState<any>(null)

  const fetchClientsAdd = useCallback(
    async (data: any) => {
      try {
        const res = await services.clients.add(data)

        if (res.status === 200) {
          setNewClient(res.data)
          fetchClients()
        }
      } catch (e: any) {
        message.error(e.response.data.msg)
      }
    },
    [fetchClients],
  )

  return (
    <Sider
      // collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      width={'260px'}
      className={`main-sider ${open ? 'activeDrawer' : ''} ${
        activated ? 'activated' : ''
      }`}
    >
      <div
        className="main-sider-inner"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div className="main-sider-top">
            <div className="main-sider-top-img">
              <img src={logo} alt="" />
            </div>
            {windowWidth && windowWidth <= 992 ? (
              <i
                className="icon-x"
                onClick={() => {
                  setActivated(false)
                }}
                style={{
                  color: '#9092A7',
                  fontSize: '20px',
                }}
              ></i>
            ) : (
              <i className="icon-disc"></i>
            )}
          </div>
          {hasAccess && (
            <Menu
              activeKey={location.pathname}
              mode="inline"
              selectedKeys={[location.pathname]}
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingRight: '17px',
              }}
            >
              {/* {routes.map(
                (route) =>
                  route.inSideBar && (
                    <SidebarItem
                      key={route.path}
                      url={route.path}
                      title={route.title}
                      icon={route.icon}
                    />
                  ),
              )} */}
              <Menu.Item
                key="/dashboard"
                icon={<i className="icon-dashboard"></i>}
                style={{
                  paddingLeft: '18px',
                  height: '70px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Link style={{ flex: 'none' }} to="/dashboard">
                  {pageTitles.dashboard}
                </Link>
              </Menu.Item>
              <Menu.SubMenu
                title="Projects"
                icon={<i className="icon-projects"></i>}
                style={{
                  paddingLeft: '18px',
                }}
              >
                <Menu.Item
                  key="/requests"
                  icon={<i className="icon-hard-drive"></i>}
                  style={{
                    paddingLeft: '40px',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Link style={{ flex: 'none' }} to="/requests">
                    {pageTitles.requests}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="/pipeline"
                  icon={<i className="icon-roadmap"></i>}
                  style={{
                    paddingLeft: '40px',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Link style={{ flex: 'none' }} to="/pipeline">
                    {pageTitles.pipeline}
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
              {(user && user.groups[0].name === 'Business Head') ||
              (user && user.groups[0].name === 'Senior Project Manager') ? (
                <Menu.Item
                  key="/team"
                  icon={<i className="icon-users"></i>}
                  style={{
                    paddingLeft: '18px',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Link style={{ flex: 'none' }} to="/team">
                    {pageTitles.team}
                  </Link>
                </Menu.Item>
              ) : (
                ''
              )}
              <Menu.Item
                key="/analytics"
                icon={<i className="icon-trending-up"></i>}
                style={{
                  paddingLeft: '18px',
                  height: '70px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Link style={{ flex: 'none' }} to="/analytics">
                  {pageTitles.analytics}
                </Link>
              </Menu.Item>
              {(user && user.groups[0].name === 'Business Head') ||
              (user && user.groups[0].name === 'Senior Project Manager') ? (
                <Menu.Item
                  key="/templates"
                  icon={<i className="icon-mail"></i>}
                  style={{
                    paddingLeft: '18px',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Link style={{ flex: 'none' }} to="/templates">
                    {pageTitles.templates}
                  </Link>
                </Menu.Item>
              ) : (
                ''
              )}

              {(user && user.groups[0].name === 'Business Head') ||
              (user && user.groups[0].name === 'Senior Project Manager') ? (
              <Menu.SubMenu
                title="Invoices"
                icon={<i className="icon-dollar"></i>}
                style={{
                  paddingLeft: '18px',
                }}
              >
                {(user && user.groups[0].name === 'Business Head') ||
                (user && user.groups[0].name === 'Senior Project Manager') ? (
                  <Menu.Item
                    key="/closed"
                    icon={<i className="icon-hard-drive"></i>}
                    style={{
                      paddingLeft: '40px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Link style={{ flex: 'none' }} to="/closed">
                      {pageTitles.closed}
                    </Link>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {user && user.groups[0].name === 'Business Head' ? (
                  <Menu.Item
                    key="/approval"
                    icon={<i className="icon-clipboard"></i>}
                    style={{
                      paddingLeft: '40px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Link style={{ flex: 'none' }} to="/approval">
                      {pageTitles.approval}
                    </Link>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {user && user.groups[0].name === 'Business Head' ? (
                  <Menu.Item
                    key="/paid"
                    icon={<i className="icon-check-circle"></i>}
                    style={{
                      paddingLeft: '40px',
                      height: '70px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Link style={{ flex: 'none' }} to="/paid">
                      {pageTitles.paid}
                    </Link>
                  </Menu.Item>
                ) : (
                  ''
                )}
              </Menu.SubMenu>
              ) : (
                  ''
                )}
            </Menu>
          )}
        </div>
        <div className="main-sider-bot">
          {(windowWidth &&
            windowWidth > 992 &&
            user &&
            user.groups[0].name === 'Business Head') ||
          (windowWidth &&
            windowWidth > 992 &&
            user &&
            user.groups[0].name === 'Senior Project Manager') ? (
            <div className="main-sider-bot-item" onClick={showDrawer}>
              <i className="icon-folder-plus"></i>Add a request
            </div>
          ) : (
            ''
          )}
          {user && user.groups[0].name !== 'Business Head' ? (
            <div className="main-sider-bot-item" onClick={showDrawerLeave}>
              <FolderMinus
                style={{
                  width: '18px',
                  height: '18px',
                  marginRight: '10px',
                }}
              />
              Apply for leave
            </div>
          ) : (
            ''
          )}
          <div className="main-sider-bot-item" onClick={showModal}>
            <i className="icon-log-out"></i>Log out
          </div>
        </div>
        <Modal
          title={<i className="icon-log-out"></i>}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="modal__custom"
          width={400}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Confirm
            </Button>,
          ]}
        >
          <div className="modal__custom-title">Log out</div>
          <div className="modal__custom-subtitle">
            Are you sure you want to log out from platform?
          </div>
        </Modal>
        <SeeMoreNew
          open={open}
          onClose={onClose}
          title="Add request"
          onSubmit={handleSubmit}
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          timePicker={timePicker}
          settimePicker={settimePicker}
          complationPicker={complationPicker}
          setcomplationPicker={setcomplationPicker}
          assigned={assignedData}
          pmsData={pmsData}
          allRequests={allRequests}
          statusData={statusData}
          secotrData={secotrData}
          types={types}
          clients={clients}
          clientCompany={clientCompany}
          setclientCompany={setclientCompany}
          selectedChargeRate={selectedChargeRate}
          setselectedChargeRate={setselectedChargeRate}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          selectedType={selectedType}
          setselectedType={setselectedType}
          resetForm={resetForm}
          setselctedtls={setselctedtls}
          tls={tls}
          pms={pms}
          fetchClientsAdd={fetchClientsAdd}
          newClient={newClient}
        />
        <Drawer
          onClose={onCloseLeave}
          visible={openLeave}
          title="Apply for leave"
          placement="right"
          className="drawer__custom"
        >
          <Form form={form} onFinish={handleSubmitLeave}>
            <Form.Item name="start_date" label="Date">
              <Space direction="vertical">
                <DatePicker
                  suffixIcon={<i className="icon-calendar"></i>}
                  onChange={(el) => {
                    setdatePickerStart(el)
                  }}
                  value={datePickerStart}
                />
              </Space>
            </Form.Item>
            <Form.Item name="end_date" label="Date">
              <Space direction="vertical">
                <DatePicker
                  suffixIcon={<i className="icon-calendar"></i>}
                  onChange={(el) => {
                    setdatePickerEnd(el)
                  }}
                  value={datePickerEnd}
                />
              </Space>
            </Form.Item>
            <Form.Item name="message" label="Request ">
              <TextArea placeholder="" />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '9px',
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  style={{
                    width: '108px',
                    height: '40px',
                    borderRadius: '8px',
                    background: '#568EFF',
                    border: '1px solid #568EFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '11px',
                  }}
                  // loading={isLoading}
                  className="login__btn"
                  onClick={() => {
                    form.resetFields()
                    setdatePickerStart(null)
                    setdatePickerEnd(null)
                  }}
                >
                  <i
                    className="icon-rotate-cw"
                    style={{ marginRight: '8px' }}
                  ></i>
                  Clear all
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    width: '108px',
                    height: '40px',
                    borderRadius: '8px',
                    background: '#28C76F',
                    border: '1px solid #28C76F',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  // loading={isLoading}
                  className="login__btn"
                >
                  <i
                    className="icon-external-link"
                    style={{ marginRight: '8px' }}
                  ></i>
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    </Sider>
  )
}

export default TheSidebar
