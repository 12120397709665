import React from 'react'

interface IProps {
  customColor: string
  custombackground: string
  title: string
  subtitle: string
  customIson: string
}

const HorizontalCard: React.FC<IProps> = ({
  customColor,
  custombackground,
  title,
  subtitle,
  customIson,
}) => {
  return (
    <div className="analytics__horizontal">
      <div className="analytics__horizontal-info">
        <div className="analytics__horizontal-title">{title}</div>
        <div className="analytics__horizontal-subtitle">{subtitle}</div>
      </div>
      <i
        className={customIson}
        style={{ color: customColor, background: custombackground }}
      ></i>
    </div>
  )
}

export default HorizontalCard
