import jwtDecode from 'jwt-decode'
import { LS_ACCESS } from 'src/app/constants'
import { IAccessModel } from 'src/app/models/access'

export function authHeader() {
  const user: IAccessModel = JSON.parse(
    localStorage.getItem(LS_ACCESS) || 'null',
  )

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token, 'api-version': '1.0' }
  }
}

export function getDecodedJwt(): { [key: string]: string } {
  return jwtDecode(localStorage.getItem(LS_ACCESS)!)
}
