import { Button, Drawer, Form, Input, Select, Space } from 'antd'
import React, { useEffect } from 'react'
import { useAppSelector } from 'src/app/hooks'
import DatePicker from './DatePicker'

const { Option } = Select

interface IProps {
  open: boolean
  onClose: () => void
  datePickerFilter: any
  setdatePickerFilter: any
  onSubmit: (values: any) => void
  parents: any
  types: any
}

const FilterClosed: React.FC<IProps> = ({
  open,
  onClose,
  datePickerFilter,
  setdatePickerFilter,
  onSubmit,
  parents,
  types,
}) => {
  const [form] = Form.useForm()

  const windowWidth = useAppSelector((state) => state.windowWidth.data)

  useEffect(() => {
    if (windowWidth && windowWidth > 992) {
      if (open) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }, [open, windowWidth])

  return (
    <Drawer
      title="Filter"
      placement="right"
      onClose={onClose}
      getContainer={false}
      visible={open}
      className="drawer__custom"
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item label="Client" name="client">
          <Input placeholder="" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input placeholder="" />
        </Form.Item>
        <Form.Item name="date" label="Date">
          <Space direction="vertical">
            <DatePicker
              suffixIcon={<i className="icon-calendar"></i>}
              onChange={(el) => {
                setdatePickerFilter(el)
              }}
              value={datePickerFilter}
            />
          </Space>
        </Form.Item>
        <Form.Item label="Parent Project" name="parent">
          <Select
            labelInValue
            placeholder="Project"
            allowClear={true}
            style={{ marginBottom: '0' }}
          >
            <Option value="null">No Parent</Option>
            {parents &&
              parents.map((n: any) => (
                <Option value={n.id} key={n.id}>
                  {n.id_code}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Type of work" name="type_of_work">
          <Select
            labelInValue
            placeholder="Type of work"
            allowClear={true}
            style={{ marginBottom: '0' }}
          >
            {types &&
              types.map((n: any) => (
                <Option value={n.id} key={n.id}>
                  {n.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '9px',
            }}
          >
            <Button
              type="primary"
              size="large"
              style={{
                width: '108px',
                height: '40px',
                borderRadius: '8px',
                background: '#568EFF',
                border: '1px solid #568EFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '11px',
              }}
              // loading={isLoading}
              className="login__btn"
              onClick={() => {
                form.resetFields()
                setdatePickerFilter(null)
              }}
            >
              <i className="icon-rotate-cw" style={{ marginRight: '8px' }}></i>
              Clear all
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                width: '108px',
                height: '40px',
                borderRadius: '8px',
                background: '#28C76F',
                border: '1px solid #28C76F',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              // loading={isLoading}
              className="login__btn"
            >
              <i
                className="icon-external-link"
                style={{ marginRight: '8px' }}
              ></i>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FilterClosed
