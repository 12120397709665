import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class Pipeline extends BaseApi {
  async all(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/projects/pipelines/${query}`)
  }
  async allDetail(id: string) {
    return await this.api.get(`api/v1/projects/pipelines/${id}`)
  }
  async more(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/projects/pipelines/${query}`)
  }
  async delete(id: string) {
    return await this.api.delete(`api/v1/projects/pipelines/${id}`)
  }
}

export default Pipeline
