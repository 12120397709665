import { Button, Modal } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { IPageRequest } from 'src/app/models/page'
import AntTable from 'src/components/common/AntTable'
import SeeMoreEmployees from 'src/components/common/SeeMoreEmployees'
import services from 'src/services'

const Employees = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [tableParams, setTableParams] = useState<IPageRequest>({
    page: 1,
    page_size: 10,
  })
  const [dataCount, setDataCount] = useState({
    count: 0,
  })
  const [data, setData] = useState()
  const [rowIcon, setRowIcon] = useState('')
  const [datePicker, setdatePicker] = useState<any>()

  const fetchData = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.user.all(params)
      const { count, results } = res.data

      setData(results)
      setDataCount({
        count,
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchData(tableParams)
  }, [fetchData, tableParams])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'profile',
      render: (profile: any) => (
        <div className="table__user">
          <div className="table__user-img">
            <img src={profile?.avatar} alt="" />
          </div>
          <div className="table__user-info">
            <div className="table__user-name">
              {profile?.first_name + ' ' + profile?.last_name}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'department',
      render: (department: any) => (
        <div
          style={{
            padding: '2px 8px',
            background: '#F9F5FF',
            borderRadius: '16px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#6941C6',
            width: 'fit-content',
          }}
        >
          {department}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 110,
      render: (_: any, rowData: any) => (
        <div className="table-actions">
          <Button
            type="primary"
            className="table-actions__more"
            onClick={() => {
              showModal()
              setrowId(rowData.id)
            }}
          >
            <i className="icon-trash-2"></i>
          </Button>
          <Button
            type="primary"
            className="table-actions__goto"
            onClick={() => {
              showDrawer()
              fetchDataById(rowData.id)
            }}
          >
            <i className="icon-pen"></i>
          </Button>
        </div>
      ),
    },
  ]

  const handleTableChange = (params: IPageRequest) => {
    setTableParams((prev) => ({
      ...prev,
      ...params,
    }))
  }

  const [open, setOpen] = useState(false)
  const [rowData, setRowData] = useState<any>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rowId, setrowId] = useState(null)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
    setRowData(null)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = (val: any) => {
    setIsModalOpen(false)
    handleDelete()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleDelete = async () => {
    if (!rowId) return

    try {
      const res = await services.user.delete(rowId)
      if (res.status === 204) {
        fetchData(tableParams)
        setIsModalOpen(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchDataById = async (id: string) => {
    setIsLoading(true)
    try {
      const res = await services.user.getById(id)
      const { data } = res
      setRowData(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  // const handleSubmit = (values: any) => {
  //   const submitData = new FormData()

  //   if (values.designation) {
  //     submitData.append(
  //       'designation',
  //       values.designation?.value
  //         ? values.designation?.value
  //         : values.designation,
  //     )
  //   }
  //   if (values.first_name) {
  //     submitData.append('first_name', values.first_name)
  //   }
  //   if (values.last_name) {
  //     submitData.append('last_name', values.last_name)
  //   }
  //   if (values.email) {
  //     submitData.append('email', values.email)
  //   }
  //   if (values.department) {
  //     submitData.append(
  //       'department',
  //       values.department?.value ? values.department?.value : values.department,
  //     )
  //   }
  //   if (rowIcon) {
  //     submitData.append('icon', rowIcon)
  //   }

  //   updateRequest(rowData?.id, submitData)
  // }

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.designation) {
      submitData.append(
        'designation',
        values.designation?.value
          ? values.designation?.value
          : values.designation,
      )
    }
    if (values.department) {
      submitData.append(
        'department',
        values.department.value ? values.department.value : values.department,
      )
    }
    if (values.groups) {
      submitData.append(
        'groups',
        values.groups.value ? values.groups.value : values.groups,
      )
    }
    if (values.working_mode) {
      submitData.append(
        'working_mode',
        values.working_mode.value
          ? values.working_mode.value
          : values.working_mode,
      )
    }
    if (values.phone) {
      submitData.append('phone', values.phone)
    }
    if (values.hobbies) {
      submitData.append('hobbies', values.hobbies)
    }
    if (values.bio) {
      submitData.append('bio', values.bio)
    }
    if (values.last_name) {
      submitData.append('last_name', values.last_name)
    }
    if (values.first_name) {
      submitData.append('first_name', values.first_name)
    }
    if (values.email) {
      submitData.append('email', values.email)
    }
    if (values.reporting_manger) {
      submitData.append(
        'reporting_manger',
        values.reporting_manger.value
          ? values.reporting_manger.value
          : values.reporting_manger,
      )
    } else {
      submitData.append('reporting_manger', '')
    }
    submitData.append('is_active', values.is_active)
    if (values.birth_date && datePicker !== undefined)
      submitData.append('birth_date', dayjs(datePicker).format('YYYY-MM-DD'))
    if (rowIcon) {
      submitData.append('avatar', rowIcon)
    }

    updateRequest(rowData?.id, submitData)
  }

  const updateRequest = useCallback(
    async (id: string, params?: any) => {
      setIsLoading(true)
      try {
        const res = await services.user.updateUser(id, params)

        if (res.status === 200) {
          onClose()
          fetchData(tableParams)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
      }
    },
    [fetchData, tableParams],
  )

  return (
    <div className="employees">
      <div className="employees__title">Employees</div>
      <AntTable
        data={data}
        columns={columns}
        loading={isLoading}
        rowsCount={dataCount.count}
        tableParams={tableParams}
        onTableChange={handleTableChange}
      />
      {!isLoading && (
        <SeeMoreEmployees
          onSubmit={handleSubmit}
          open={open}
          onClose={onClose}
          title="Edit employee"
          data={rowData}
          datePicker={datePicker}
          setdatePicker={setdatePicker}
          setRowIcon={setRowIcon}
        />
      )}
      <Modal
        title={<i className="icon-alert-circle"></i>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal__custom"
        width={400}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Confirm
          </Button>,
        ]}
      >
        <div className="modal__custom-title">Delete employee</div>
        <div className="modal__custom-subtitle">
          Are you sure you want to delete from platform?
        </div>
      </Modal>
    </div>
  )
}

export default Employees
