import { Button, Drawer, Form, Input, Select } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import services from 'src/services'
import DatePicker from './DatePicker'
import TextArea from 'antd/lib/input/TextArea'
import dayjs from 'dayjs'
import user from 'src/resources/images/user.svg'
import { useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'

const { Option, OptGroup } = Select

interface IProps {
  open: boolean
  onSubmit: (values: any) => void
  onClose: any
  data: any
  datePicker: any
  setdatePicker: any
  setRowIcon: any
}

const ProfileEdit: React.FC<IProps> = ({
  open,
  onSubmit,
  onClose,
  data,
  datePicker,
  setdatePicker,
  setRowIcon,
}) => {
  const [form] = Form.useForm()
  const [groups, setgroups] = useState<any>()
  const userRole = useAppSelector((state) => state.user.data)
  const isBusinessHead = userRole && userRole.groups[0].name === 'Business Head'

  const fetchGroups = async () => {
    try {
      const res = await services.group.all()
      const { results } = res.data

      setgroups(results)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchGroups()
  }, [])

  const [departments, setdepartments] = useState<any>()

  const fetchDepartments = async () => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setdepartments(results)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchDepartments()
  }, [])

  const [designation, setdesignation] = useState<any>()

  const fetchDesignation = async () => {
    try {
      const res = await services.designations.all()
      const { results } = res.data

      setdesignation(results)
    } catch (e) {
      console.log(e)
    }
  }

  const [pmsData, setPmsData] = useState<any>()

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.group.allRM()
      const { data } = res

      setPmsData(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  useEffect(() => {
    fetchDesignation()
  }, [])

  useEffect(() => {
    if (data && data.birth_date && datePicker === undefined) {
      setdatePicker(dayjs(data.birth_date))
    }
  }, [data, datePicker, setdatePicker])

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        is_active: data.is_active ? 'true' : 'false',
        reporting_manger: data.reporting_manger,
        working_mode: data.working_mode,
        phone: data.phone,
        designation: data.designation,
        hobbies: data.hobbies,
        bio: data.bio,
        groups: data.groups?.map((n: any) => n.id),
        department: data.department?.id,
        birth_date: data.birth_date === null ? dayjs() : dayjs(data.birth_date),
      })
    }
  }, [form, data])

  const [rowIconasd, setRowIconasd] = useState('')
  const file: any = useRef(null)

  const loadIcon = (e: any) => {
    const file = e.target.files[0]
    setRowIconasd(URL.createObjectURL(file))
    setRowIcon(file)
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  return (
    <Drawer
      key="1"
      title="Profile"
      placement="right"
      onClose={onClose}
      getContainer={false}
      visible={open}
      className="drawer__custom"
    >
      <div className="general__left drawer-img">
        <div>
          <div className="general__left-text">Profile Picture</div>
          <div className="general__left-img">
            <img
              src={
                rowIconasd
                  ? rowIconasd
                  : data && data.avatar
                  ? data.avatar
                  : user
              }
              alt=""
            />
            <input
              type="file"
              id="file"
              onChange={(e) => {
                loadIcon(e)
              }}
              ref={file}
              style={{ display: 'none' }}
            />
          </div>
        </div>
        <Button
          className="general__left-btn"
          onClick={() => {
            file.current.click()
          }}
          style={{ marginLeft: '17px' }}
        >
          Update
        </Button>
      </div>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item label="First name" name="first_name">
          <Input placeholder="" disabled={true} />
        </Form.Item>
        <Form.Item label="Last name" name="last_name">
          <Input placeholder="" disabled={true} />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input placeholder="" disabled={true} />
        </Form.Item>
        <Form.Item label="Activity status" name="is_active">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            disabled={true}
          >
            <Option value="true">Active</Option>
            <Option value="false">Inactive</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Reporting manager" name="reporting_manger">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            disabled={isBusinessHead ? false : true}
          >
            <OptGroup label="Business Head">
              {pmsData &&
                pmsData.bh?.map((n: any) => (
                  <Option value={n.id} key={n.id}>
                    {n.profile.first_name + ' ' + n.profile.last_name}
                  </Option>
                ))}
            </OptGroup>
            <OptGroup label="Senior Project Manager">
              {pmsData &&
                pmsData.spm?.map((n: any) => (
                  <Option value={n.id} key={n.id}>
                    {n.profile.first_name + ' ' + n.profile.last_name}
                  </Option>
                ))}
            </OptGroup>
          </Select>
        </Form.Item>
        <Form.Item label="Designation" name="designation">
          <Input
            placeholder=""
            disabled={isBusinessHead ? false : true}
            style={{ marginBottom: '0' }}
            allowClear={true}
          />

          {/* <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            disabled={isBusinessHead ? false : true}
          >
            {designation &&
              designation.map((n: any) => (
                <Option value={n.id} key={n.id}>
                  {n.name}
                </Option>
              ))}
          </Select> */}
        </Form.Item>
        <Form.Item label="Current role" name="groups">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
            disabled={
              (userRole && userRole.groups[0].name === 'Project Manager') ||
              (userRole && userRole.groups[0].name === 'Team Member')
                ? true
                : false
            }
          >
            {isBusinessHead
              ? groups?.map((n: any) => (
                  <Option value={n.id} key={n.id}>
                    {n.name}
                  </Option>
                ))
              : userRole && userRole.groups[0].name === 'Senior Project Manager'
              ? groups
                  ?.filter((m: any) => m.name !== 'Business Head')
                  .map((n: any) => (
                    <Option value={n.id} key={n.id}>
                      {n.name}
                    </Option>
                  ))
              : groups
                  ?.filter(
                    (m: any) =>
                      m.name !== 'Business Head' &&
                      m.name !== 'Senior Project Manager',
                  )
                  .map((n: any) => (
                    <Option value={n.id} key={n.id}>
                      {n.name}
                    </Option>
                  ))}
          </Select>
        </Form.Item>
        <Form.Item label="Current department" name="department">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
          >
            {departments &&
              departments.map((n: any) => (
                <Option value={n.id} key={n.id}>
                  {n.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Working mode" name="working_mode">
          <Select
            labelInValue
            placeholder=""
            allowClear={true}
            style={{ marginBottom: '0' }}
          >
            <Option value="office">Office</Option>
            <Option value="online">Online</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Phone number" name="phone">
          <Input placeholder="" maxLength={10} />
        </Form.Item>
        <Form.Item label="Date of birth" name="birth_date">
          <DatePicker
            suffixIcon={<i className="icon-calendar"></i>}
            onChange={(el) => {
              setdatePicker(el)
            }}
            value={datePicker}
            format={'D MMM YYYY'}
          />
        </Form.Item>
        <Form.Item label="Hobbies" name="hobbies">
          <Input />
        </Form.Item>
        <Form.Item label="About me" name="bio">
          <TextArea placeholder="" style={{ resize: 'none' }} />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{
              width: '108px',
              height: '40px',
              flex: '0 1 50%',
              borderRadius: '8px',
              background: '#28C76F',
              border: '1px solid #28C76F',
            }}
            // loading={isLoading}
            className="login__btn"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default ProfileEdit
