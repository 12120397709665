import { createSlice } from '@reduxjs/toolkit'

export interface WindowWidthState {
  data: number | null
}

const initialState: WindowWidthState = {
  data: null,
}

export const windowWidthSlice = createSlice({
  name: 'windowWidth',
  initialState,
  reducers: {
    updateWindowWidth: (state, action: any) => {
      state.data = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateWindowWidth } = windowWidthSlice.actions

export default windowWidthSlice.reducer
