import { createSlice } from '@reduxjs/toolkit'

export interface userRequestState {
  data: any | null
}

const initialState: userRequestState = {
  data: null,
}

export const userRequestSlice = createSlice({
  name: 'userRequest',
  initialState,
  reducers: {
    updateUserRequest: (state, action: any) => {
      state.data = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateUserRequest } = userRequestSlice.actions

export default userRequestSlice.reducer
