import Analytics from './analytics'
import Auth from './auth'
import Clients from './clients'
import Dashboard from './dashboard'
import Departments from './departments'
import Designations from './designations'
import Email from './email'
import Excel from './excel'
import Group from './group'
import Invoices from './invoices'
import MainSearch from './mainSearch'
import Microsoft from './microsoft'
import Notifications from './notifications'
import Pipeline from './pipeline'
import Requests from './requests'
import Sector from './sector'
import Settings from './settings'
import Status from './status'
import Team from './team'
import Types from './types'
import User from './user'

class Services {
  auth: Auth
  user: User
  dashboard: Dashboard
  analytics: Analytics
  requests: Requests
  invoices: Invoices
  clients: Clients
  departments: Departments
  email: Email
  group: Group
  mainSearch: MainSearch
  notifications: Notifications
  pipeline: Pipeline
  settings: Settings
  team: Team
  status: Status
  sector: Sector
  types: Types
  excel: Excel
  microsoft: Microsoft
  designations: Designations

  constructor() {
    this.auth = new Auth()
    this.user = new User()
    this.dashboard = new Dashboard()
    this.analytics = new Analytics()
    this.requests = new Requests()
    this.invoices = new Invoices()
    this.clients = new Clients()
    this.departments = new Departments()
    this.email = new Email()
    this.group = new Group()
    this.mainSearch = new MainSearch()
    this.notifications = new Notifications()
    this.pipeline = new Pipeline()
    this.settings = new Settings()
    this.team = new Team()
    this.status = new Status()
    this.sector = new Sector()
    this.types = new Types()
    this.excel = new Excel()
    this.microsoft = new Microsoft()
    this.designations = new Designations()
  }
}

export default new Services()
