export const paramsToQuery = (params: any) => {
  if (Object.keys(params) && Object.keys(params).length === 0) return ''

  const queryString = Object.keys(params)
    .filter((key) => !!params[key])
    .map((key) => `${key}=${params[key]}`)
    .join('&')

  return '?' + queryString
}
