import { Button, Form, Input, Layout, Select } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LS_ACCESS } from 'src/app/constants'
import { IPageRequest } from 'src/app/models/page'
import DatePicker from 'src/components/common/DatePicker'
import loginbg from 'src/resources/images/SigninBg.png'
import services from 'src/services'

const { Option, OptGroup } = Select

const Register: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [datePicker, setdatePicker] = useState<any>()
  const [form] = Form.useForm()
  const history = useHistory()

  const updateRequest = async (id: string, params?: any) => {
    try {
      const res = await services.user.updateUser(id, params)

      if (res.status === 200) {
        history.push('/dashboard')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [profile, setProfile] = useState<any>()

  const fetchProfile = async () => {
    try {
      const res = await services.user.getProfile()
      const { data } = res

      setProfile(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  const [designation, setdesignation] = useState<any>()

  const fetchDesignation = async () => {
    try {
      const res = await services.designations.all()
      const { results } = res.data

      setdesignation(results)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchDesignation()
  }, [])

  const handleSubmit = (values: any) => {
    const submitData = new FormData()

    if (values.designation) {
      submitData.append(
        'designation',
        values.designation?.value
          ? values.designation?.value
          : values.designation,
      )
    }
    if (values.reporting_manger) {
      submitData.append(
        'reporting_manger',
        values.reporting_manger.value
          ? values.reporting_manger.value
          : values.reporting_manger,
      )
    } else {
      submitData.append('reporting_manger', '')
    }
    if (values.department) {
      submitData.append('department', values.department)
    }
    if (values.groups) {
      submitData.append('groups', values.groups)
    }
    if (values.working_mode) {
      submitData.append('working_mode', values.working_mode)
    }
    if (values.phone) {
      submitData.append('phone', values.phone)
    }
    if (values.hobbies) {
      submitData.append('hobbies', values.hobbies)
    }
    if (values.bio) {
      submitData.append('bio', values.bio)
    }
    if (values.birth_date && datePicker !== undefined) {
      submitData.append('birth_date', dayjs(datePicker).format('YYYY-MM-DD'))
    }

    updateRequest(profile?.id, submitData)
  }

  const [groups, setgroups] = useState<any>()

  const fetchGroups = async () => {
    setIsLoading(true)
    try {
      const res = await services.group.all()
      const { results } = res.data

      setgroups(results)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    fetchGroups()
  }, [])

  const [assignedData, setassignedData] = useState<any>()

  const fetchAssigned = async () => {
    try {
      const res = await services.departments.all()
      const { results } = res.data

      setassignedData(results)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchAssigned()
  }, [])

  const [pmsData, setPmsData] = useState<any>()

  const fetchPMs = useCallback(async (params?: IPageRequest) => {
    try {
      const res = await services.group.allRM()
      const { data } = res

      setPmsData(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchPMs()
  }, [fetchPMs])

  useEffect(() => {
    if (profile && profile.birth_date && datePicker === undefined) {
      setdatePicker(dayjs(profile.birth_date))
    }
  }, [profile, datePicker, setdatePicker])

  useEffect(() => {
    form.setFieldsValue({
      birth_date:
        profile && profile.birth_date ? dayjs(profile.birth_date) : dayjs(),
    })
  }, [form, profile])

  useEffect(() => {
    form.setFieldsValue({
      reporting_manger: profile && profile.reporting_manger,
      hobbies: profile && profile.hobbies,
      bio: profile && profile.bio,
      designation: profile && profile.designation,
      groups: profile && profile.groups?.map((n: any) => n.id),
      department: profile && profile.department?.id,
      working_mode: profile && profile.working_mode,
      phone: profile && profile.phone,
    })
  }, [form, profile])

  useEffect(() => {
    const hasAccess: any = localStorage.getItem(LS_ACCESS)

    if (hasAccess && JSON.parse(hasAccess).is_register === false) {
      history.push('/dashboard')
    }
  }, [history])

  return (
    <>
      <Layout
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#fff',
        }}
        className="login"
      >
        <div className="login__info-wrapper">
          <div
            className="login__info-wrapper-inner"
            style={{ maxWidth: '343px' }}
          >
            <h1 className="login__title">Personal information</h1>
            <h2 className="login__subtitle">
              Please update some personal information details to continue in our
              platform
            </h2>
            <Form
              form={form}
              name="normal_login"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              style={{ maxWidth: 450, width: '100%' }}
              className="login__form"
            >
              <Form.Item label="Reporting manager" name="reporting_manger">
                <Select
                  labelInValue
                  placeholder=""
                  allowClear={true}
                  style={{ marginBottom: '0' }}
                >
                  <OptGroup label="Business Head">
                    {pmsData &&
                      pmsData.bh?.map((n: any) => (
                        <Option value={n.id} key={n.id}>
                          {n.profile.first_name + ' ' + n.profile.last_name}
                        </Option>
                      ))}
                  </OptGroup>
                  <OptGroup label="Senior Project Manager">
                    {pmsData &&
                      pmsData.spm?.map((n: any) => (
                        <Option value={n.id} key={n.id}>
                          {n.profile.first_name + ' ' + n.profile.last_name}
                        </Option>
                      ))}
                  </OptGroup>
                </Select>
              </Form.Item>
              <Form.Item label="Designation" name="designation">
                <Input
                  placeholder=""
                  // disabled={
                  //   profile && profile.groups[0]?.name === 'Business Head'
                  //     ? false
                  //     : true
                  // }
                  style={{ marginBottom: '0' }}
                  allowClear={true}
                />
                {/* <Select
                  labelInValue
                  placeholder=""
                  allowClear={true}
                  style={{ marginBottom: '0' }}
                  // disabled={
                  //   profile && profile.groups[0]?.name === 'Business Head'
                  //     ? false
                  //     : true
                  // }
                >
                  {designation &&
                    designation.map((n: any) => (
                      <Option value={n.id} key={n.id}>
                        {n.name}
                      </Option>
                    ))}
                </Select> */}
              </Form.Item>
              <Form.Item
                name="groups"
                rules={[{ required: true, message: 'Current role' as string }]}
                label="Current role"
              >
                <Select
                // disabled={
                //   (profile &&
                //     profile.groups[0]?.name === 'Project Manager') ||
                //   (profile && profile.groups[0]?.name === 'Team Member')
                //     ? true
                //     : false
                // }
                >
                  {/* {profile && profile.groups[0]?.name === 'Business Head'
                    ? groups?.map((n: any) => (
                        <Option value={n.id} key={n.id}>
                          {n.name}
                        </Option>
                      ))
                    : profile &&
                      profile.groups[0]?.name === 'Senior Project Manager'
                    ? groups
                        ?.filter(
                          (m: any) => m.name !== 'Senior Project Manager',
                        )
                        .map((n: any) => (
                          <Option value={n.id} key={n.id}>
                            {n.name}
                          </Option>
                        ))
                    : groups
                        ?.filter(
                          (m: any) =>
                            m.name !== 'Business Head' &&
                            m.name !== 'Senior Project Manager',
                        )
                        .map((n: any) => (
                          <Option value={n.id} key={n.id}>
                            {n.name}
                          </Option>
                        ))} */}
                  {groups &&
                    groups.map((n: any) => (
                      <Option value={n.id} key={n.id}>
                        {n.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="department"
                rules={[
                  { required: true, message: 'Current department' as string },
                ]}
                label="Current department"
              >
                <Select>
                  {assignedData &&
                    assignedData.map((n: any) => (
                      <Option value={n.id} key={n.id}>
                        {n.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="working_mode"
                rules={[{ required: true, message: 'Working mode' as string }]}
                label="Working mode"
              >
                <Select>
                  <Option value="office">Office</Option>
                  <Option value="online">Online</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[{ required: true, message: 'Phone number' }]}
                label="Phone number"
              >
                <Input size="large" placeholder="Phone number" maxLength={10} />
              </Form.Item>
              <Form.Item
                name="birth_date"
                rules={[{ required: true, message: 'Date of birth' }]}
                label="Date of birth"
              >
                <DatePicker
                  suffixIcon={<i className="icon-calendar"></i>}
                  onChange={(el) => {
                    setdatePicker(el)
                  }}
                  value={datePicker}
                  format={'D MMM YYYY'}
                />
              </Form.Item>
              <Form.Item name="hobbies" label="Hobbies">
                <Input size="large" placeholder="Computer games, football" />
              </Form.Item>
              <Form.Item name="bio" label="About me">
                <Input.TextArea
                  placeholder="Tell us some information about yourself"
                  style={{
                    minHeight: '122px',
                    height: '100%',
                  }}
                />
              </Form.Item>
              <Form.Item style={{ marginTop: 20 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: '100%', marginBottom: '11px' }}
                  loading={isLoading}
                  className="login__btn"
                >
                  Continue
                </Button>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    width: '100%',
                    background: '#EFF4FF',
                    border: '1px solid #EFF4FF',
                    color: '#568EFF',
                  }}
                  loading={isLoading}
                  className="login__btn"
                  onClick={() => {
                    // form.resetFields()
                    history.push('/dashboard')
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="login__info-img">
          <img src={loginbg} alt="" />
        </div>
      </Layout>
    </>
  )
}

export default Register
