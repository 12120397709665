import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'src/app/hooks'
import DatePicker from 'src/components/common/DatePicker'
import services from 'src/services'
import ComposedAnalitics from './components/ComposedAnalitics'
import ComposedCharts from './components/ComposedCharts'
import HorizontalCard from './components/HorizontalCard'
import LineHours from './components/LineHours'
import PieCharts from './components/PieCharts'
import VerticalCard from './components/VerticalCard'

const Analytics = () => {
  const [users, setusers] = useState<any>()
  const [projects, setprojects] = useState<any>()
  const [sectors, setsectors] = useState<any>()
  const [analytics, setAnalytics] = useState<any>()
  const [works, setworks] = useState<any>()
  const [clients, setclients] = useState<any>()
  const windowWidth = useAppSelector((state) => state.windowWidth.data)
  const [datePicker, setdatePicker] = useState<any>(null)

  const fetchUsers = useCallback(async (date?: any) => {
    try {
      const res = await services.analytics.getUsers(date)
      const { data } = res

      setusers(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (datePicker !== null) {
      fetchUsers({ date: dayjs(datePicker).format('YYYY-MM') })
    } else {
      fetchUsers()
    }
  }, [fetchUsers, datePicker])

  const fetchProjects = useCallback(async (date?: any) => {
    try {
      const res = await services.analytics.getProjects(date)
      const { data } = res

      setprojects(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (datePicker !== null) {
      fetchProjects({ date: dayjs(datePicker).format('YYYY-MM') })
    } else {
      fetchProjects()
    }
  }, [fetchProjects, datePicker])

  const fetchAnalytics = useCallback(async (date?: any) => {
    try {
      const res = await services.analytics.getDevision(date)
      const { data } = res

      setAnalytics(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (datePicker !== null) {
      fetchAnalytics({ date: dayjs(datePicker).format('YYYY-MM') })
    } else {
      fetchAnalytics()
    }
  }, [fetchAnalytics, datePicker])

  const fetchSectors = useCallback(async (date?: any) => {
    try {
      const res = await services.analytics.getSectors(date)
      const { data } = res

      setsectors(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (datePicker !== null) {
      fetchSectors({ date: dayjs(datePicker).format('YYYY-MM') })
    } else {
      fetchSectors()
    }
  }, [fetchSectors, datePicker])

  const fetchWorks = useCallback(async (date?: any) => {
    try {
      const res = await services.analytics.getWorks(date)
      const { data } = res

      setworks(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (datePicker !== null) {
      fetchWorks({ date: dayjs(datePicker).format('YYYY-MM') })
    } else {
      fetchWorks()
    }
  }, [fetchWorks, datePicker])

  const fetchClients = useCallback(async (date?: any) => {
    try {
      const res = await services.analytics.getClients(date)
      const { data } = res

      setclients(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (datePicker !== null) {
      fetchClients({ date: dayjs(datePicker).format('YYYY-MM') })
    } else {
      fetchClients()
    }
  }, [fetchClients, datePicker])

  return (
    <div className="analytics">
      <div className="analytics__title page-title">Analytics</div>
      <div
        style={{
          maxWidth: '200px',
          marginLeft: 'auto',
          marginBottom: '24px',
        }}
      >
        <DatePicker
          onChange={(val) => {
            setdatePicker(val)
          }}
          style={{
            borderColor: '#fff',
          }}
          format={'MMMM YYYY'}
          allowClear
          picker="month"
        />
      </div>
      <div className="analytics__body">
        <div className="analytics__body-top">
          <div className="analytics__body-top-vertical">
            <VerticalCard
              customColor="#6DB6FF"
              custombackground="#EEF7FF"
              title={users && users.new_employees}
              subtitle="New employess"
              customIson="icon-user-plus"
            />
            <VerticalCard
              customColor="#FFAC60"
              custombackground="#FFF3E8"
              title={users && users.total_employees}
              subtitle="Total employees"
              customIson="icon-users"
            />
          </div>
          <div className="analytics__body-top-horizontal">
            <HorizontalCard
              customColor="#344054"
              custombackground="rgba(115, 103, 240, 0.12)"
              title={users && users.male}
              subtitle="Male"
              customIson="icon-icon-park-outline_male"
            />
            <HorizontalCard
              customColor="#344054"
              custombackground="rgba(115, 103, 240, 0.12)"
              title={users && users.female}
              subtitle="Female"
              customIson="icon-bx_female-sign"
            />
          </div>
          <div
            style={
              windowWidth && windowWidth > 992
                ? { width: '30%' }
                : { width: '100%' }
            }
          >
            <LineHours
              data={projects && projects.billable_hours}
              total={projects && projects.billable_hours}
            />
          </div>
        </div>
        <div className="analytics__body-mid">
          <div className="analytics__body-mid-item">
            <ComposedCharts data={projects && projects.number_of_projects} />
          </div>
          <div className="analytics__body-mid-item">
            <PieCharts data={analytics && analytics.data} />
            <div className="analytics__body-vertical">
              <VerticalCard
                customColor="#6DB6FF"
                custombackground="#EEF7FF"
                title={clients && clients.clients_in_pipeline}
                subtitle="Clients in Pipeline"
                customIson="icon-link"
              />
              <VerticalCard
                customColor="#56C686"
                custombackground="#E3F8EC"
                title={clients && clients.new_clients}
                subtitle="New clients won"
                customIson="icon-award"
              />
            </div>
          </div>
        </div>
        <div className="analytics__body-bot">
          <div className="analytics__body-bot-item">
            <ComposedAnalitics
              customColor="#FF9F43"
              title="Top five sectors of the month"
              data={sectors && sectors.sectors_count}
            />
          </div>
          <div className="analytics__body-bot-item">
            <ComposedAnalitics
              customColor="#568EFF"
              title="Type of work"
              data={works && works.works_count}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analytics
