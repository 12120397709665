import { ConfigProvider } from 'antd'
import App from './App'
// import ruRU from 'antd/lib/locale/ru_RU'

import './css/index.less'
import { Provider } from 'react-redux'
import { store } from './app/store'
// import 'dayjs/locale/ru'

import { createRoot } from 'react-dom/client'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from 'src/Config'

const msalInstance = new PublicClientApplication(msalConfig)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <MsalProvider instance={msalInstance}>
    <ConfigProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </MsalProvider>,
)
