import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LS_ACCESS } from 'src/app/constants'
import { IAccessModel } from 'src/app/models/access'
import { IUserModel } from 'src/app/models/user'

export interface AuthState {
  access: IAccessModel | null
  user: IUserModel | null
}

const initialState: AuthState = {
  access: null,
  user: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessAndUser: (
      state,
      action: PayloadAction<{ access: IAccessModel; user: IUserModel }>,
    ) => {
      const { access } = action.payload
      state.access = access
    },
    setAccess: (state, action: PayloadAction<IAccessModel>) => {
      state.access = action.payload
    },
    setUser: (state, action: PayloadAction<IUserModel>) => {
      state.user = action.payload
    },
    clearAccessAndUser: (state) => {
      state = initialState
    },
    signOut: (state) => {
      state = initialState
      localStorage.removeItem(LS_ACCESS)
      document.location.replace('/signin')
    },
  },
})

export const {
  setAccessAndUser,
  setAccess,
  setUser,
  clearAccessAndUser,
  signOut,
} = authSlice.actions

export default authSlice.reducer
