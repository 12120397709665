import React from 'react'
import { RadialBarChart, RadialBar, ResponsiveContainer } from 'recharts'

interface IProps {
  data: any
}

const RadialTasks: React.FC<IProps> = ({ data }) => {
  return (
    <div className="dashboard__radial">
      <div>
        <div className="dashboard__radial-top">
          <div className="dashboard__radial-top-title">Request at Glance</div>
          {/* <div className="dashboard__radial-top-time">Last 7 Days</div> */}
        </div>
        <div style={{ height: '320px', widows: '316px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <RadialBarChart
              width={315}
              height={320}
              cx="50%"
              cy="55%"
              innerRadius="40%"
              outerRadius="90%"
              barSize={15}
              data={data}
            >
              <RadialBar background dataKey="value" />
            </RadialBarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="dashboard__radial-info">
        {data &&
          data.map((n: any) => (
            <div className="dashboard__radial-info-item" key={n.name}>
              <div className="dashboard__radial-info-left">
                <i className="icon-circle" style={{ color: n.fill }}></i>
                <div className="dashboard__radial-info-name">{n.name}</div>
              </div>
              <div className="dashboard__radial-info-value">{n.value}</div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default RadialTasks
