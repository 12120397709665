import { paramsToQuery } from 'src/utils/paramsToQuery'
import BaseApi from '../base.service'

class Requests extends BaseApi {
  async getList(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/projects/requests/get_parents/${query}`)
  }
  async getParent(params?: any) {
    const query = params ? paramsToQuery(params) : ''
    return await this.api.get(`api/v1/projects/requests/${query}`)
  }
  async getDetail(id: string) {
    return await this.api.get(`api/v1/projects/requests/${id}/`)
  }
  async postRequests(data?: any) {
    return await this.api.post(`api/v1/projects/requests/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
  async updateRequests(id: string, data?: any) {
    return await this.api.put(
      `api/v1/projects/requests/${id}/`,
      data,
      // {
      //   headers: { 'Content-Type': 'multipart/form-data' },
      // }
    )
  }
  async deleteRequests(id: string) {
    return await this.api.delete(`api/v1/projects/requests/${id}/`)
  }

  async leaveRequest(data?: any) {
    return await this.api.post(`api/v1/leave-request/`, data)
  }

  // async getById(id: string, params?: any) {
  //   const query = params ? paramsToQuery(params) : ''
  //   return await this.api.get(`/providers/${id}${query}`)
  // }
}

export default Requests
