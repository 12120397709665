import { Select, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'src/app/hooks'
import { IPageRequest } from 'src/app/models/page'
import AntTable from 'src/components/common/AntTable'
import services from 'src/services'
const { Option } = Select

const Team = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [team, setTeam] = useState('Assigned')
  const [dataCount, setDataCount] = useState({
    count: 0,
  })
  const [data, setData] = useState<any>()
  const [tableParams, setTableParams] = useState<IPageRequest>({
    page: 1,
    page_size: 10,
  })
  const user = useAppSelector((state) => state.user.data)

  const fetchDataTeam = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.team.all(params)

      const { count, results } = res.data

      setData(results)
      setDataCount({
        count,
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  const fetchDataLeaved = useCallback(async (params?: IPageRequest) => {
    setIsLoading(true)
    try {
      const res = await services.team.allLeaved(params)

      const { count, results } = res.data

      setData(results)
      setDataCount({
        count,
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (team === 'Leaved users') {
      fetchDataLeaved({
        ...tableParams,
      })
    }
  }, [fetchDataLeaved, tableParams, team])

  useEffect(() => {
    if (team !== 'Leaved users') {
      fetchDataTeam({
        ...tableParams,
        assigned: team === 'Assigned' ? 'True' : 'False',
      })
    }
  }, [fetchDataTeam, tableParams, team])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'profile',
      render: (profile: any) => (
        <div className="table__user">
          <div className="table__user-img">
            <img src={profile?.avatar} alt="" />
          </div>
          <div className="table__user-info">
            <div className="table__user-name">
              {profile?.first_name ? profile?.first_name : ''}{' '}
              {profile?.last_name ? profile?.last_name : ''}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'groups',
      render: (groups: any) => (
        <div
          style={{
            padding: '2px 8px',
            background: '#F9F5FF',
            borderRadius: '16px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#6941C6',
            width: 'fit-content',
          }}
        >
          {groups && groups.map((n: any) => n.name)}
        </div>
      ),
    },
    {
      title: 'Started time',
      dataIndex: 'request_start_time',
      render: (request_start_time: any) => (
        <div
          style={{
            padding: '2px 8px',
            background: '#EAF1FF',
            borderRadius: '16px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#568EFF',
            width: 'fit-content',
          }}
        >
          {dayjs(request_start_time).subtract(5, 'hour').format('h A')}
        </div>
      ),
    },
  ]

  const columnsLeaved = [
    {
      title: 'Name',
      dataIndex: 'profile',
      render: (profile: any) => (
        <div className="table__user">
          <div className="table__user-img">
            <img src={profile?.avatar} alt="" />
          </div>
          <div className="table__user-info">
            <div className="table__user-name">
              {profile?.first_name ? profile?.first_name : ''}{' '}
              {profile?.last_name ? profile?.last_name : ''}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'groups',
      render: (groups: any) => (
        <div
          style={{
            padding: '2px 8px',
            background: '#F9F5FF',
            borderRadius: '16px',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            color: '#6941C6',
            width: 'fit-content',
          }}
        >
          {groups && groups.map((n: any) => n.name)}
        </div>
      ),
    },
    {
      title: 'Start date',
      dataIndex: 'vacation_start',
      redner: (vacation_start: any) => (
        <div>
          {vacation_start ? dayjs(vacation_start).format('MMMM D, YYYY') : ''}
        </div>
      ),
    },
    {
      title: 'End date',
      dataIndex: 'vacation_end',
      redner: (vacation_end: any) => (
        <div>
          {vacation_end ? dayjs(vacation_end).format('MMMM D, YYYY') : ''}
        </div>
      ),
    },
  ]

  const handleTableChange = (params: IPageRequest) => {
    setTableParams((prev) => ({
      ...prev,
      ...params,
    }))
  }

  return (
    <div className="team">
      <div className="team__top">
        <div className="team__title page-title">Team</div>
        <Select
          labelInValue
          placeholder={team}
          defaultValue={team as any}
          style={{ marginBottom: '0' }}
          className="team__dropdown"
          onChange={(value) => {
            setTeam(value.label)
          }}
          bordered={false}
          suffixIcon={
            <i
              className="icon-chevron-down"
              style={{ fontSize: '20px', color: '#344054' }}
            ></i>
          }
        >
          {user && user.groups[0].name === 'Team Member' ? (
            ''
          ) : (
            <Option value="Assigned">Assigned</Option>
          )}
          {(user && user.groups[0].name === 'Business Head') ||
          (user && user.groups[0].name === 'Senior Project Manager') ? (
            <Option value="Unassigned">Unassigned</Option>
          ) : (
            ''
          )}
          {(user && user.groups[0].name === 'Business Head') ||
          (user && user.groups[0].name === 'Senior Project Manager') ? (
            <Option value="Leaved users">Leaved users</Option>
          ) : (
            ''
          )}
        </Select>
      </div>
      {team === 'Assigned' && (
        <div className="team__chart-wrapper">
          <div
            className="team__chart"
            style={{
              height: '100%',
              overflow: 'hidden',
              width: 'calc(100vw - 310px)',
              overflowX: 'auto',
            }}
          >
            <table className="team__chart-table">
              <tbody>
                {data &&
                  data.map((n: any) => (
                    <tr>
                      <td
                        style={{
                          padding: '5px 10px 5px 15px',
                          borderRight: '1px solid #e7e8f3',
                          fontWeight: '600',
                          fontSize: '14px',
                          lineHeight: '16px',
                          textAlign: 'center',
                          color: '#344054',
                        }}
                      >
                        {n.name}
                      </td>
                      <td>
                        <div
                          className="team__chart-custom-wrapper"
                          style={{
                            display: 'flex',
                            margin: '5px 0 5px 10px',
                          }}
                        >
                          {n.projects?.map((m: any) => (
                            <Tooltip
                              title={`${m.id_code}, ${m.client}, ${m.type_of_work}`}
                            >
                              <div
                                className="team__chart-custom"
                                style={{
                                  background: m.color,
                                }}
                              ></div>
                            </Tooltip>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {team === 'Unassigned' && (
        <AntTable
          data={data}
          columns={columns}
          loading={isLoading}
          rowsCount={dataCount.count}
          tableParams={tableParams}
          onTableChange={handleTableChange}
          filterColumns={['lotId']}
        />
      )}
      {team === 'Leaved users' && (
        <AntTable
          data={data}
          columns={columnsLeaved}
          loading={isLoading}
          rowsCount={dataCount.count}
          tableParams={tableParams}
          onTableChange={handleTableChange}
          filterColumns={['lotId']}
        />
      )}
    </div>
  )
}

export default Team
