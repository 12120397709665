import { useCallback, useEffect } from 'react'
import { LS_ACCESS } from 'src/app/constants'
import { useAppDispatch } from 'src/app/hooks'
import { clearAccessAndUser, setAccess } from 'src/features/authSlice'

const WithAuth = ({
  component: Component,
  title,
  layout: Layout,
  ...props
}: {
  component: React.FC
  title: string
  // layout: JSXElementConstructor<MainLayoutProps>
  layout: any
}) => {
  const dispatch = useAppDispatch()
  const clear = useCallback(() => {
    dispatch(clearAccessAndUser())
  }, [dispatch])

  // access management
  useEffect(() => {
    // if no store access then try to get it from localStorage
    const lsAccess = localStorage.getItem(LS_ACCESS)

    // if no access data in LS then redirect to /signin
    if (!lsAccess) {
      return clear()
    }

    // set found access data to store
    dispatch(setAccess(JSON.parse(lsAccess)))
  }, [dispatch, clear])

  return (
    <Layout title={title} {...props}>
      <Component />
    </Layout>
  )
}

export default WithAuth
