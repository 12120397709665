import BaseApi from '../base.service'

class Group extends BaseApi {
  async all() {
    return await this.api.get(`api/v1/account/groups/`)
  }
  async allRM() {
    return await this.api.get(`api/v1/account/users/get_report_manager/`)
  }
}

export default Group
