// export const config = {
//   appId: '4c1a6c3a-4d46-4b48-95db-503e2086fd10',
//   redirectUri: 'http://localhost:3000',
//   scopes: ['user.read'],
//   authority: 'https://login.microsoftonline.com',
// }

export const msalConfig = {
  auth: {
    clientId: '4c1a6c3a-4d46-4b48-95db-503e2086fd10',
    authority: 'https://login.microsoftonline.com/achrafchad.onmicrosoft.com', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: 'http://localhost:3000',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
}
